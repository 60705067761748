import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Spin } from 'antd'
import axios from '../config/axios'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  redirectLoginIfLoggedOut,
  removeObjectPrototype
} from '../utils/helper';
import '../style/Product.css'

function Products({
  viewMode,
  setViewMode,
  productName,
  setProductName,
  selectedId,
  setSelectedId,
  fetchAllStocks,
  fetchInOutStocks
}) {
  const navigate = useNavigate()
  
  const [productLoading, setProductLoading] = useState(true)
  const [addProductLoading, setAddProductLoading] = useState(false)
  // const [productName, setProductName] = useState('')
  // const [selectedId, setSelectedId] = useState('')
  const [products, setProducts] = useState([])

  const _user = JSON.parse(localStorage.getItem('user'))
  
  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    fetchAllProduct()
  }, [])

  const fetchAllProduct = async () => {
    try {
      const { data } = await axios.get(
        '/product', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('products: ', data)
      
      setProducts(data.map((_item) => removeObjectPrototype(_item)))
      
      setProductLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('_error.response.data.message: ', _error.response.data.message)
      setProductLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const handleAddEditProduct = async () => {
    if (productName === '') {
      message.info('Please enter product name')
      return
    }
    const _find = products.find((item) => item.name === productName)
    if (_find) {
      message.info('Product Already Exists')
      return
    }
    setAddProductLoading(true)
    // console.log('user: ', _user._id)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/')
      return
    }

    if (viewMode === 'add-product') {
      try {
        const { data } = await axios.post(
          '/product',
          {
            name: productName,
            userId: _user._id
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        setProducts([
          ...products,
          removeObjectPrototype(data.product)
        ])
        // setProducts
        message.success(data.message)
        setAddProductLoading(false)
        setProductName('')
        fetchAllStocks()
        fetchInOutStocks()
        setViewMode('stock')
      } catch (_error) {
        console.log('error: ', _error)
        // console.log('_error.response.data.message: ', _error.response.data.message)
        message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
        setAddProductLoading(false)
      }
    } else {
      try {
        const { data } = await axios.put(
          `/product/${selectedId}`,
          {
            name: productName,
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        fetchAllProduct()
        // setProducts
        message.success(data.message)
        setAddProductLoading(false)
        setProductName('')
        setSelectedId('')
        fetchAllStocks()
        fetchInOutStocks()
        setViewMode('stock')
      } catch (_error) {
        console.log('error: ', _error)
        // console.log('_error.response.data.message: ', _error.response.data.message)
        message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
        setAddProductLoading(false)
      }
    }
    
  }

  return (
    <div>
      <div>
        <div className='add-product-container'>
          <div className='add-product-header'>
            <h2>{viewMode === 'add-product' ? 'Add' : 'Edit'} Product</h2>
            <button
              className='custom-button custom-button-sm'
              onClick={() => {
                setProductName('')
                setViewMode('stock')
              }}
            >
              All Stocks
            </button>
          </div>
          
          <div className="add-product-body">
            <div className="input-group">
              <label htmlFor="rice_name">Rice Name</label>
              <input
                type="text"
                className=""
                id='rice_name'
                placeholder='Enter Rice Name'
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
        
            <div className="input-submit">
              {addProductLoading
                ? (<Spin size="large" />)
                : (<button onClick={handleAddEditProduct}>{viewMode === 'add-product' ? 'Add' : 'Update'} Product</button>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
