import { DatePicker, message, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from '../config/axios';
import '../style/buy.css';
import '../style/common.css';
import {
  getCurrentUser, getDateFromDateTime, getFloatNumber, getValidPositiveNumber, redirectLoginIfLoggedOut, removeObjectPrototype
} from '../utils/helper';

const buyStatusOptions = [
  {
    label: 'Buy',
    value: 'Buy'
  }, 
  {
    label: 'On the way',
    value: 'On the way'
  }, 
  {
    label: 'Arrived',
    value: 'Arrived'
  }
]

const BuySell = ({
  transaction,
  setMode,
  fetchTransactions,
  statusEdit
}) => {
  const navigate = useNavigate()
  const [action, setAction] = useState('buy')
  const [productLoading, setProductLoading] = useState(true)
  const [entityLoading, setEntityLoading] = useState(true)
  const [buySellSubmitLoading, setBuySellSubmitLoading] = useState(false)
  const [prevPaid, setPrevPaid] = useState(0)
  const [paid, setPaid] = useState(0)
  const [due, setDue] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(buyStatusOptions[0])
  const [productOptions, setProductOptions] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [quantity, setQuantity] = useState('')
  const [unitPrice, setUnitPrice] = useState('')
  const [allEntity, setAllEntity] = useState([])
  const [entityOptions, setEntityOptions] = useState([])
  const [selectedEntity, setSelectedEntity] = useState({})
  const [selectedVendor, setSelectedVendor] = useState({})
  const [defaultPaid, setDefaultPaid] = useState(0)
  const [note, setNote] = useState('')
  
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

  const _user = getCurrentUser()

  useEffect(() => {
    // console.log('moment: ', moment()._d)
    // console.log('moment: ', moment(new Date().toUTCString())._d)
    redirectLoginIfLoggedOut(navigate)
    fetchAllProduct()
    fetchAllEntity()
    if (transaction) {
      console.log('sm target transaction: ', transaction)
      setAction(transaction.type)
      setQuantity(transaction.quantity)
      setUnitPrice(transaction.unitPrice)
      setNote(transaction.note)
      setPrevPaid(transaction.paid)
      setSelectedStatus(buyStatusOptions.find((_item) => _item.value === transaction.status) || buyStatusOptions[0])
      setDue(transaction.due)
      setDate(moment(transaction.date).format('YYYY-MM-DD'))
    }
  }, [])

  useEffect(() => {
    if (!transaction) {
      setQuantity('')
      setUnitPrice('')
    }
  }, [action, transaction])

  useEffect(() => {
    if (action === 'buy') {
      setEntityOptions(
        allEntity.filter((_item) => _item.type === 'vendor')
          .map((_item) => ({
            label: _item.name + ' - ' + (_item.address || ''),
            value: _item._id
          }))
      )
    } else if (action === 'sell') {
      setEntityOptions(
        allEntity.filter((_item) => _item.type === 'customer')
          .map((_item) => ({
            label: _item.name + ' - ' + (_item.address || ''),
            value: _item._id
          }))
      )
    }
  }, [allEntity, action])

  useEffect(() => {
    if (transaction) {
      setSelectedProduct(productOptions.find((_item) => _item.value === transaction.productId))
    }
  }, [productOptions, action])

  
  useEffect(() => {
    if (transaction) {
      setSelectedEntity(entityOptions.find((_item) => _item.value === transaction.entityId))
    }
  }, [entityOptions, action])

  useEffect(() => {
    
    setSelectedVendor(allEntity.find((_it) => _it._id === selectedEntity.value))
    const _currentDue = allEntity.find((_it) => _it._id === selectedEntity.value)?.due
    console.log('vendor.due: ', _currentDue)
    if (_currentDue && _currentDue < 0) {
      if (transaction) {
        // setPaid(Math.min(transaction.due, _currentDue * -1))
        // setDefaultPaid(Math.min(transaction.due, _currentDue * -1))
      } else {
        setPaid(Math.min(unitPrice * quantity * 1.0, _currentDue * -1))
        setDefaultPaid(Math.min(unitPrice * quantity * 1.0, _currentDue * -1))
      }
    } else {
      setPaid(0)
      setDefaultPaid(0)
    }

  }, [selectedEntity, transaction, unitPrice, quantity])


  useEffect(() => {
    if (!unitPrice || !quantity) {
      setPaid(0)
    }
    const _due = parseFloat(unitPrice * quantity * 1.0 - prevPaid).toFixed(2)
    console.log('sm unitPrice * quantity * 1.0: ', unitPrice * quantity * 1.0)
    console.log('sm paid: ', paid)
    console.log('sm prevPaid: ', prevPaid)
    console.log('sm _due: ', _due)
    const _newDue = isNaN(paid) || !paid ? parseFloat(_due) : parseFloat(_due) - parseFloat(paid)
    setDue(_newDue)
  }, [unitPrice, quantity, paid, prevPaid, transaction])


  // useEffect(() => {
  //   console.log('paid: ', Number(paid))
  //   console.log('paid-: ', parseFloat(paid))
  // }, [paid])
  

  const fetchAllProduct = async () => {
    try {
      const { data } = await axios.get(
        '/product', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      setProductOptions(
        data
          .map((_item) => removeObjectPrototype(_item))
          // .filter((_item) => !_item.deleted)
          .map((_item) => ({ value: _item._id, label: _item.name + ' - ' + _item.stock + ' KG' }))
      )
      
      setProductLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('_error.response.data.message: ', _error.response.data.message)
      setProductLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }

  const fetchAllEntity = async () => {
    try {
      const { data } = await axios.get(
        '/entity', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('entity data: ', data)
      setAllEntity(data.map((_item) => removeObjectPrototype(_item)))
      
      setEntityLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('_error.response.data.message: ', _error.response.data.message)
      setEntityLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }

  const handleBuySellSubmit = async () => {
    const body = {
      entityId: selectedEntity.value,
      productId: selectedProduct.value,
      quantity,
      unitPrice,
      paid,
      due,
      date: new Date(date),
      dateForCashHistory: getDateFromDateTime(new Date()),
      timeOffset: (new Date()).getTimezoneOffset(),
      note,
      type: action,
      userId: _user?._id
    }
    
    console.log('body: ', body)
    console.log('action: ', action)
    // if (selectedEntityId)
    // return

    if (!selectedEntity || !selectedEntity.value) {
      message.info(`${action === 'buy' ? 'Vendor' : 'Customer' } must be selected!`)
      return
    }

    if (!body.productId) {
      message.info('Rice Name must be selected')
      return
    }
    if (!body.quantity) {
      message.info('Quantity(KG) is required')
      return
    }
    if (!body.unitPrice) {
      message.info('Unit Price is required')
      return
    }

    // if (parseFloat(body.unitPrice * body.quantity).toFixed(2) !== parseFloat(paid + due).toFixed(2)) {
    //   message.info('Paid + Due amount must be equal to total amuont!')
    //   return
    // }
    // return
    //if (action === 'buy') {
    body.status = selectedStatus.value
    body.defaultPaid = defaultPaid
    
    if (parseFloat(paid).toFixed(2) < parseFloat(defaultPaid).toFixed(2)) {
      message.info('Paid Amount must be greater than or equal ' + defaultPaid)
      return
    }
    //}

    setBuySellSubmitLoading(true)
    try {
      if (!transaction) {
        const { data } = await axios.post(
          '/transaction',
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setBuySellSubmitLoading(false)
        setDue(0) 
        setPaid(0)
        setQuantity('')
        setUnitPrice('')
        setNote('')
        setSelectedEntity({})
        fetchAllProduct()
      } else {
        body.paid = prevPaid + parseFloat(paid)
        body.userId = _user._id
        console.log('sm update body: ', body) 
        // setBuySellSubmitLoading(false)
        // return
        const { data } = await axios.put(
          `/transaction/${transaction._id}`,
          {
            ...body,
            editRequest: {
              ...transaction.editRequest,
              status: 'edited',
            }
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setBuySellSubmitLoading(false)
        setQuantity('')
        setUnitPrice('')
        setNote('')
        setSelectedEntity({})
        fetchTransactions()
        setMode('dashboard')
      }

      fetchAllProduct()
      fetchAllEntity()
      
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setBuySellSubmitLoading(false)
    }
  }

  
  return (
    <div className="buy-container">
      <div className="buy-container-header back-button-container">
        <h2>{transaction ? 'Update ' : ''}{action === 'buy' ? 'Buy' : 'Sale'} Transaction</h2>
        {!transaction && (
          <button
            className="custom-button custom-button-sm"
            onClick={() => {
              if (action === 'buy') {
                setSelectedEntity({})
                setAction('sell')
              } else {
                setSelectedEntity({})
                setAction('buy')
              }
            }}
          >
            {action === 'buy' ? 'Sale' : 'Buy'}
          </button>
        )}
        
      </div>
      <div className="buy-container-body">
        <div className="input-item">
          <label>{ action === 'buy' ? 'Vendor' : 'Customer' }</label>
          <Select
            isLoading={entityLoading}
            options={entityOptions}
            value={selectedEntity}
            placeholder={`Select ${action === 'buy' ? 'Vendor' : 'Customer'}`}
            label={`Select ${action === 'buy' ? 'Vendor' : 'Customer'}`}
            onChange={(selected) => {
              console.log(selected)
              setSelectedEntity(selected)
            }}
            isDisabled={statusEdit}
          />
        </div>
        <div className="input-item">
          <label>Rice Name</label>
          <Select
            isLoading={productLoading}
            options={productOptions}
            value={selectedProduct}
            placeholder='Select Rice'
            onChange={(selected) => {
              console.log(selected)
              setSelectedProduct(selected)
            }}
            isDisabled={statusEdit}
          />
        </div>
        <div className="input-item">
          <label>{ action === 'buy' ? 'Buy' : 'Sell' }ing Date</label>
          <DatePicker
            defaultValue={() => moment(transaction ? moment(transaction.date).format('YYYY-MM-DD') : date, 'YYYY-MM-DD')}
            // value={() => moment(date, 'YYYY-MM-DD')}
            onChange={(value, dateString) => {
              console.log(dateString)
              setDate(dateString)
            }}
            disabled={statusEdit}
          />
        </div>
        <div className="input-item input-quantity">
          <label htmlFor="quantity">Quantity (KG)</label>
          <div>
            <input
              type="text"
              id="quantity"
              placeholder="Enter Quantity here"
              value={quantity}
              onChange={(e) => setQuantity(getValidPositiveNumber(e.target.value))}
              disabled={statusEdit}
            />
            <span>KG</span>
          </div>
        </div>
        <div className="input-item">
          <label htmlFor="unitPrice">Unit Price (Per KG)</label>
          <input
            type="text"
            id="unitPrice"
            placeholder="Enter Unit Price here"
            value={unitPrice}
            onChange={(e) => {
              let _temp = getFloatNumber(e.target.value, unitPrice)
              if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
              setUnitPrice(parseFloat(_temp) >= 0 || _temp === '' ? _temp : unitPrice)
            }}
            disabled={statusEdit}
          />
        </div>
        <div className="input-item disabled-item">
          <label htmlFor="total_Price">Total Price</label>
          <input
            type="text"
            id="total_Price"
            placeholder="Enter Unit Price here"
            value={parseFloat(unitPrice * quantity * 1.0).toFixed(2)}
            disabled
          />
        </div>
        <div className="input-item">
          <label htmlFor="paid">{transaction ? 'New' : '' } Paid Amount</label>
          <input
            type="text"
            id="paid"
            placeholder="Enter Paid Amount"
            value={paid}
            onChange={(e) => {
              let _temp = getFloatNumber(e.target.value, paid) <= parseFloat(unitPrice * quantity * 1.0).toFixed(2) - prevPaid
                ? getFloatNumber(e.target.value, paid)
                : paid
              if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
              const parsedTemp = parseFloat(_temp)
              console.log('parsedTemp: ', parsedTemp)
              setPaid(_temp)
              // setPaid(parsedTemp >= defaultPaid ? _temp : defaultPaid)
            }}
            disabled={statusEdit}
          />
        </div>
        <div className="input-item disabled-item">
          <label htmlFor="due">Due Amount</label>
          <input
            type="text"
            id="due"
            placeholder="Enter Due Amount"
            disabled
            value={parseFloat(due).toFixed(2)}
            // onChange={(e) => setDue(getValidPositiveNumber(e.target.value))}
          />
        </div>
        {action === 'buy' && (
          <div className="input-item">
            <label>Buying Status</label>
            <Select
              options={buyStatusOptions}
              value={selectedStatus}
              placeholder={`Select Buying Status`}
              label={`Select Buying Status`}
              onChange={(selected) => {
                console.log(selected)
                setSelectedStatus(selected)
              }}
            />
          </div>
        )}
        <div className="input-item note-item">
          <label htmlFor="note">Note</label>
          <textarea
            name="note"
            id="note"
            rows="3"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            disabled={statusEdit}
          />
        </div>
      </div> 
      <div className="buy-container-footer">
        {buySellSubmitLoading ? <Spin size="large" /> : (
          <button
            className="buy-submit"
            onClick={handleBuySellSubmit}
          >
            { transaction ? 'Update' : action === 'buy' ? 'BUY' : 'SALE' }
          </button>
        )}
      </div>
    </div>
  );
}

export default BuySell;
