import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, Modal, message } from 'antd';
import axios from '../config/axios'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CustomDateFilter from '../components/CustomDateFilter';
import Products from './Products';
import '../style/common.css'
import '../style/Stock.css'
import {
  redirectLoginIfLoggedOut,
  removeObjectPrototype,
  getCurrentUser
} from '../utils/helper';

function Stock() {
  const navigate = useNavigate()
  const [viewMode, setViewMode] = useState('stock')
  const [stockLoading, setStockLoading] = useState(true)
  const [inOutStockLoading, setInOutStockLoading] = useState(true)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [stocksRaw, setStocksRaw] = useState([])
  const [stocks, setStocks] = useState([])
  const [inOutStocksRaw, setInOutStocksRaw] = useState([])
  const [inOutStocks, setInOutStocks] = useState([])
  const [dateFilter, setDateFilter] = useState('today')

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [selectedId, setSelectedId] = useState('')
  const [productName, setProductName] = useState('')

  const [totalAvailableStock, setTotalAvailableStock] = useState(0)
  const [totalStockIn, setTotalStockIn] = useState(0)
  const [totalStockOut, setTotalStockOut] = useState(0)

  const [searchAllStocks, setSearchAllStocks] = useState('')
  const [searchInOutStocks, setSearchInOutStocks] = useState('')

  const _user = getCurrentUser()
  
  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    fetchAllStocks()
  }, [])

  useEffect(() => {
    if (dateFilter !== 'custom') {
      fetchInOutStocks()
    }
  }, [dateFilter])

  useEffect(() => {
    setTotalAvailableStock(stocks.reduce((partialSum, _item) => partialSum + _item.stock, 0))
  }, [stocks])

  useEffect(() => {
    setTotalStockIn(inOutStocks.reduce((partialSum, _item) => partialSum + _item.stockIn, 0))
    setTotalStockOut(inOutStocks.reduce((partialSum, _item) => partialSum + _item.stockOut, 0))
  }, [inOutStocks])

  const fetchAllStocks = async () => {
    try {
      const { data } = await axios.get(
        '/product', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('stocks data: ', data)
      
      const _tempData = data.map((_item) => removeObjectPrototype(_item)).filter((_item) => !_item.deleted)
      setStocks(_tempData)
      setStocksRaw(_tempData)
      
      setStockLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      setStockLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }

  const fetchInOutStocks = async () => {
    setInOutStockLoading(true)
    const _path = dateFilter !== 'custom'
      ? `/product/stock/${dateFilter}`
      : `/product/stock/${startDate}/${endDate}`
    try {
      const { data } = await axios.post(
        _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('data: ', data)
      
      setInOutStocksRaw(data.map((_item) => removeObjectPrototype(_item)))
      setInOutStocks(data.map((_item) => removeObjectPrototype(_item)))
      
      setInOutStockLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      setInOutStockLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }

  const handleProductDelete = async () => {
    try {
      const { data } = await axios.put(
        `/product/${selectedId}`,
        {
          deleted: true
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success('Product Deleted Successfully!')
      fetchAllStocks()
      fetchInOutStocks()
      setDeleteConfirmModal(false)
    } catch (_error) {
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const handleAllSearch = (_key) => {
    setSearchAllStocks(_key)
    setStocks(stocksRaw.filter((_item) => {
      const re = new RegExp(_key, "i");
      const _matched = _item.name.match(re)
      return _matched
    }))
  }

  const handleInOutSearch = (_key) => {
    setSearchInOutStocks(_key)
    setInOutStocks(inOutStocksRaw.filter((_item) => {
      const re = new RegExp(_key, "i");
      const _matched = _item.name.match(re)
      return _matched
    }))
  }

  return (
    <div className="stock-container">
      <Modal
        visible={deleteConfirmModal}
        title="Product Delete Confirmation"
        onCancel={() => {
          setSelectedId('')
          setDeleteConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setDeleteConfirmModal(false)
              setSelectedId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleProductDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Product?</p>
      </Modal>
      {viewMode === 'stock' ? (
        <>
          <div className='all-stock-container'>
            <div className='all-stock-header back-button-container'>
              <h2>Available Stock</h2>
              <button
                className="custom-button custom-button-sm"
                onClick={() => {
                  setViewMode('add-product')
                }}
              >
                Add Product
              </button>
            </div>
            <div className="total-header">
              <h3>Total: <strong>{totalAvailableStock} <small>KG</small></strong></h3>
              <div className="custom-filter input-item">
                <input
                  type="text"
                  id="search-name"
                  placeholder={`Search Product Name`}
                  value={searchAllStocks}
                  onChange={(e) => handleAllSearch(e.target.value)}
                />
              </div>
            </div>
            {stockLoading
              ? <Spin size="large" />
              : stocks.length === 0 ? (<p>Stockslist is Empty.</p>)
              : (
              <div className="all-stock-body">
                {stocks.map((stock, _index) => (
                  <div className='stock-item' key={_index}>
                    <p className='stock-name'>{stock.name}</p>
                    <p className='stock-amount'>{stock.stock} <small>KG</small></p>
                    {_user.role === 'super_admin' && (
                      <div className='actions'>
                        <span
                          onClick={() => {
                            setSelectedId(stock._id)
                            setProductName(stock.name)
                            setViewMode('update-product')
                          }}
                        >
                          <EditOutlinedIcon />
                        </span>
                        <span
                          onClick={() => {
                            setSelectedId(stock._id)
                            setDeleteConfirmModal(true)
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="in-out-stock-container">
        <div className="in-out-stock-filter">
          <CustomDateFilter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleSubmit={fetchInOutStocks}
          />
        </div>
        
        <div className="custom-filter input-item">
          <input
            type="text"
            id="search-name"
            placeholder={`Search Product Name`}
            value={searchInOutStocks}
            onChange={(e) => handleInOutSearch(e.target.value)}
          />
        </div>
        <div className='in-out-stock-header'>
          <h2>Stock In</h2>
        </div>
        <div className="total-header">
          <h3>Total: <strong>{totalStockIn} <small>KG</small></strong></h3>
        </div>
        {inOutStockLoading
          ? (<Spin size="large" />)
          : inOutStocks.length === 0 ? (<p>Stock In list is Empty.</p>)
          : (
            <div className="in-out-stock-body">
              {inOutStocks.map((stock, _index) => (
                <div className='stock-item' key={_index}>
                  <p className='stock-name'>{stock.name}</p>
                  <p className='stock-amount'>{stock.stockIn} <small>KG</small></p>
                </div>
              ))}
            </div>
          )
        }
        <div className='in-out-stock-header'>
          <h2>Stock Out</h2>
        </div>
        <div className="total-header">
          <h3>Total: <strong>{totalStockOut} <small>KG</small></strong></h3>
        </div>
        {inOutStockLoading
          ? (<Spin size="large" />)
          : inOutStocks.length === 0 ? (<p>Stock Out list is Empty.</p>)
          : (
            <div className="in-out-stock-body">
              {inOutStocks.map((stock, _index) => (
                <div className='stock-item' key={_index}>
                  <p className='stock-name'>{stock.name}</p>
                  <p className='stock-amount'>{stock.stockOut} <small>KG</small></p>
                </div>
              ))}
            </div>
          )
        }
          </div>
        </>
      ) : (
          <Products
            viewMode={viewMode}
            setViewMode={setViewMode}
            productName={productName}
            setProductName={setProductName}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            fetchAllStocks={fetchAllStocks}
            fetchInOutStocks={fetchInOutStocks}
          />
      )}
    </div>
  );
}

export default Stock;
/* style={{display:"flex", flexDirection:"column", marginTop:"10rem"}} */