import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'
import { message, Spin, DatePicker, Modal } from 'antd'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import axios from '../config/axios'

import {
  getCurrentUser,
  removeObjectPrototype,
  redirectLoginIfLoggedOut,
  generatePDF,
  getFloatNumber,
  getFormattedReadableNumber
} from '../utils/helper'
import {
  fetchAllusers
} from '../utils/apis'
import '../style/common.css'
import '../style/daily-account.css'

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

const accountTypeOptions = [
  {
    label: 'Debit',
    value: 'Debit'
  }, {
    label: 'Credit',
    value: 'Credit'
  }
]

const transactionTypeOptions = [
  {
    label: 'Cash In',
    value: 'Cash In'
  }, {
    label: 'Cash Out',
    value: 'Cash Out'
  }
]

const DailyAccount = () => {

  const navigate = useNavigate()
  const _user = getCurrentUser()

  const [users, setUsers] = useState([])

  const [viewMode, setViewMode] = useState('add-holder')
  const [accountHolderLoading, setAccountHolderLoading] = useState(true)
  const [holderTransactionLoading, setHolderTransactionoading] = useState(true)
  const [holderSubmitLoading, setHolderSubmitLoading] = useState(false)
  const [transactionSubmitLoading, setTransactionSubmitLoading] = useState(false)

  const [selectedAccountType, setselectedAccountType] = useState(accountTypeOptions[0])
  const [selectedTransactionType, setSelectedTransactionType] = useState(transactionTypeOptions[0])

  const [holders, setHolders] = useState([])
  const [rawHolders, setRawHolders] = useState([])
  const [transactions, setTransactions] = useState([])
  const [selectedHolder, setSelectedHolder] = useState({})
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

  const [holderName, setHolderName] = useState('')
  const [holderPhone, setHolderPhone] = useState('')
  const [note, setNote] = useState('')
  const [amount, setAmount] = useState('')
  const [transactionNote, setTransactionNote] = useState('')
  // const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [transactionAmount, setTransactionAmount] = useState('')

  const [downloadClicked, setDownloadClicked] = useState(false)
  const [yearOptions, setYearOptions] = useState([])
  const [selectedYear, setSelectedYear] = useState({})
  const [searchName, setSearchName] = useState('')

  const [targetHolderIdForDelete, setTargetHolderIdForDelete] = useState('')

  const _accListRef = useRef()
  
  let forceUpdate = useForceUpdate();

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    console.log('selectedHolder: ', selectedHolder)
    if (selectedHolder && selectedHolder._id) {
      fetchAllTransactionsByHolderId(selectedHolder._id)
      setHolderName(selectedHolder.name)
      setHolderPhone(selectedHolder.phone)
      setselectedAccountType(accountTypeOptions.find((_item) => _item.value === selectedHolder.type))
      setAmount(selectedHolder.amount)
      setDate(selectedHolder.date)
      setNote(selectedHolder.note)
    }
    
  }, [selectedHolder])

  useEffect(() => {
    if (selectedHolder && selectedHolder._id) {
      setSelectedHolder(rawHolders.find((_item) => _item._id === selectedHolder._id))
    }
  }, [rawHolders])
  
  const init = async () => {
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/')
    }
    fetchAllHolders()
    const _users = await fetchAllusers()
    setUsers(_users)
  }
  
  const fetchAllHolders = async () => {
    try {
      const { data } = await axios.get(
        '/dailyaccountholder', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('dailyaccountholders data: ', data)
      const _temp = data
        .map((_item) => removeObjectPrototype(_item))
        .sort((_a, _b) => _a.readableId > _b.readableId ? -1 : _a.readableId < _b.readableId ? 1 : 0)
        || []
      setRawHolders(_temp)

      let _allYearOptions = [...new Set(_temp.map((_item) => {
        const _date = new Date(_item.date)
        return _date.getFullYear()
      }))]
        .sort((_a, _b) => _a > _b ? -1 : _a < _b ? 1 : 0)
        .map((_item) => ({
          label: _item,
          value: _item
        }))
      setYearOptions(_allYearOptions)
      if (_allYearOptions.length > 0) {
        console.log('msg from _allYearOptions: ', _allYearOptions)
        setSelectedYear(_allYearOptions[0])
        renderHoldersBySelectedYear(_allYearOptions[0].value, _temp)
      }

    } catch (_error) {
      console.log('error: ', _error)
    }
  }
  
  const renderHoldersBySelectedYear = (_year, _allHolders) => {
    const _tempEntities = _allHolders ? _allHolders : rawHolders
    setHolders(_tempEntities.filter((_item) => {
      const _date = new Date(_item.date)
      const re = new RegExp(searchName, "i");
      const _matched = _item.name.match(re)
      return _date.getFullYear() === _year && _matched
    }))
  }

  const handleSearchName = (_key) => {
    setSearchName(_key)
    setHolders(rawHolders.filter((_item) => {
      const _date = new Date(_item.date)
      const re = new RegExp(_key, "i");
      const _matched = _item.name.match(re)
      return _date.getFullYear() === selectedYear.value && _matched
    }))
  }
  

  const fetchAllTransactionsByHolderId = async (_holderId) => {
    try {
      const { data } = await axios.get(
        `/dailyaccount/holder/${_holderId}`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('data: ', data)
      setTransactions(
        data.map((_item) => removeObjectPrototype(_item)).map((_item) => {
          const _userFound = users.find((_item2) => _item2._id === _item.userId)
          return {
            ..._item,
            transactorName: _userFound.name || '-'
          }
        }).sort((_a, _b) => _a.readableId > _b.readableId ? -1 : _a.readableId < _b.readableId ? 1 : 0)
      )
    } catch (_error) {
      console.log('error: ', _error)
    }
  }

  const handleAccountSubmit = async () => {

    const body = {
      name: holderName,
      phone: holderPhone,
      type: selectedAccountType.value,
      amount,
      date: new Date(date),
      timeOffset: (new Date()).getTimezoneOffset(),
      note,
      userId: _user?._id
    }
    
    
    console.log('body: ', body)
    if (!body.name) {
      message.info('Debitor/Creditor Name is required')
      return
    }
    if (!body.phone) {
      message.info('Phone number is required')
      return
    }
    
    if (!amount || amount < 1) {
      message.info('Amount is required')
      return
    }

    setHolderSubmitLoading(true)
    try {
      if (!selectedHolder || !selectedHolder.name) {
        const { data } = await axios.post(
          '/dailyaccountholder',
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setHolderSubmitLoading(false)
        setHolderName('')
        setHolderPhone('')
        setNote('')
        setAmount('')
        fetchAllHolders()
      } else {
        const { data } = await axios.put(
          `/dailyaccountholder/${selectedHolder._id}`,
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setHolderSubmitLoading(false)
        fetchAllHolders()
      }
      
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setHolderSubmitLoading(false)
    }
  }
  
  const handleTransactionSubmit = async () => {

    const body = {
      type: selectedTransactionType.value,
      amount: transactionAmount,
      holderId: selectedHolder._id,
      note: transactionNote,
      userId: _user?._id,
      timeOffset: (new Date()).getTimezoneOffset()
    }
    
    console.log('body: ', body)
    if (!body.amount || body.amount < 1) {
      message.info('Amount is required')
      return
    }
    
    
    setTransactionSubmitLoading(true)
    try {
      const { data } = await axios.post(
        '/dailyaccount',
        body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      const _tempAmount = data.dailyAccountHolder.amount
      setAmount(_tempAmount)
      setTransactionNote('')
      message.success(data.message)
      setTransactionSubmitLoading(false)
      setTransactionAmount('')
      fetchAllHolders()
      fetchAllTransactionsByHolderId(body.holderId)
    } catch (_error) { 
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setTransactionSubmitLoading(false)
    }
  }

  const handleHolderItemDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/dailyaccountholder/${targetHolderIdForDelete}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      fetchAllHolders()
      setTargetHolderIdForDelete('')
    } catch (_error) {
      setTargetHolderIdForDelete('')
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const getHolderEditOption = (_holderItem) => (
    <span
      className="view-edit-item"
      onClick={() => {
        console.log('selected holderItem: ', _holderItem)
        setSelectedHolder(_holderItem)
        setViewMode('update-holder')
      }}
    >
      <EditOutlinedIcon />
    </span>
  )

  const getHolderDeleteOption = (_holderId) => (
    <span
      className="delete-item"
      onClick={() => {
        setTargetHolderIdForDelete(_holderId)
      }}
    >
      <DeleteOutlinedIcon />
    </span>
  )

  
  return (
    <div className="account-container common-container">
      <Modal
        visible={targetHolderIdForDelete}
        title="Debitors/Creditors Delete Confirmation"
        onCancel={() => setTargetHolderIdForDelete('')}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => setTargetHolderIdForDelete('')}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleHolderItemDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Account?</p>
      </Modal>
      <div className="add-account-container">
        <div className="add-account-header back-button-container">
          <h2>{viewMode === 'update-holder' ? 'Update' : 'Add'} Account</h2>
          {viewMode === 'update-holder' && (
            <button
              className='custom-button custom-button-sm'
              onClick={() => {
                setSelectedHolder({})
                setHolderName('')
                setHolderPhone('')
                setNote('')
                setAmount('')
                setDate(moment().format('YYYY-MM-DD'))
                setViewMode('add-holder')
              }}
            >
              Back
            </button>
          )}
        </div>
        <div className="add-account-body common-container-body input-group">
          <div className="input-item">
            <label>Account Type</label>
            <Select
              options={accountTypeOptions}
              value={selectedAccountType}
              onChange={(selected) =>  setselectedAccountType(selected)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="account-name">{selectedAccountType.value === 'Debit' ? 'Debitor' : 'Creditor'} Name</label>
            <input
              type="text"
              id="account-name"
              placeholder="Enter Name"
              value={holderName}
              onChange={(e) => setHolderName(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              placeholder="Enter Phone"
              value={holderPhone}
              onChange={(e) => setHolderPhone(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="amount">{viewMode === 'add-holder' ? 'Initial ' : ''}{selectedAccountType.value}</label>
            <input
              type="text"
              id="amount"
              placeholder="Enter Amount"
              disabled={viewMode === 'update-holder' && transactions.length > 0}
              value={amount}
              onChange={(e) => {
                
                let _temp = getFloatNumber(e.target.value, amount)
                if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                setAmount(parseFloat(_temp) >= 0 || _temp === '' ? _temp : amount)
                
              }}
            />
          </div>
          <div className="input-item">
            <label>Date</label>
            {viewMode === 'add-holder' ? (
              <DatePicker
                key={'for-add-holder'}
                defaultValue={() => moment(selectedHolder ? moment(selectedHolder.date).format('YYYY-MM-DD') : date, 'YYYY-MM-DD')}
                onChange={(value, dateString) => {
                  console.log(dateString)
                  setDate(dateString)
                }}
              />
            ) : (
              <DatePicker
                key={'for-update-holder'}
                defaultValue={() =>  moment(selectedHolder ? moment(selectedHolder.date).format('YYYY-MM-DD') : date, 'YYYY-MM-DD')}
                onChange={(value, dateString) => {
                  console.log(dateString)
                  setDate(dateString)
                }}
              />
            )}
            
            
          </div>
          <div className="input-item note-item">
            <label htmlFor="note">Note</label>
            <textarea
              name="note"
              id="note"
              rows="3"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <div className="add-account-footer common-container-footer">
          {holderSubmitLoading ? <Spin size="large" /> : (
            <button
              className="custom-button custom-button-sm"
              onClick={handleAccountSubmit}
            >
              {viewMode === 'update-holder' ? 'Update' : 'Add'} Account
            </button>
          )}
        </div>
      </div>

      {viewMode === 'update-holder' && (
        <>
          <div className="add-account-header">
            <h2>Add Transaction</h2>
          </div>
          <div className="add-account-body common-container-body input-group">
            <div className="input-item">
              <label>Transaction Type</label>
              <Select
                options={transactionTypeOptions}
                value={selectedTransactionType}
                onChange={(selected) =>  setSelectedTransactionType(selected)}
              />
            </div>
            <div className="input-item">
              <label htmlFor="amount">Amount</label>
              <input
                type="text"
                id="amount"
                placeholder="Enter amount"
                value={transactionAmount}
                onChange={(e) => {
                  let _temp = getFloatNumber(e.target.value, transactionAmount)
                  if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                  setTransactionAmount(parseFloat(_temp) >= 0 || _temp === '' ? _temp : transactionAmount)
                }}
              />
            </div>
            <div className="input-item note-item">
              <label htmlFor="note">Note</label>
              <textarea
                name="note"
                id="note"
                rows="3"
                value={transactionNote}
                onChange={(e) => setTransactionNote(e.target.value)}
              />
            </div>
          </div>
          <div className="add-account-footer common-container-footer">
            {transactionSubmitLoading ? <Spin size="large" /> : (
              <button
                className="custom-button custom-button-sm"
                onClick={handleTransactionSubmit}
              >
                Add Transaction
              </button>
            )}
          </div>
        </>
      )}


      <div className="export-pdf">
        <button
          className="custom-button custom-button-sm"
          onClick={() => {
            setDownloadClicked(true)
            setTimeout(() => { 
              const fileName = `${viewMode === 'add-holder' ? 'All Debitors/Creditors' : 'Transactions of ' + selectedHolder.name} - ${moment().format('YYYY-MM-DD')}.pdf`
              
              generatePDF(fileName, _accListRef, setDownloadClicked)
              
            }, 0)
            
          }}
        >
          Export PDF
        </button>
      </div>

      <div
        className={`account-list-container common-container-body ${viewMode}-mode ${downloadClicked ? 'minus-one-row' : ''}`}
        ref={_accListRef}
        style={downloadClicked ? {
          width: '89rem',
          padding: '4rem'
        } : {}}
      >
        <div className="bank-loan-transaction-header">
          <h3>{viewMode === 'add-holder' ? 'All Debitors/Creditors' : <>Transactions of <strong>{selectedHolder.name}</strong></>}</h3>
          {viewMode === 'add-holder' && (
            <div className="custom-filter input-item debitors-creditors">
              <Select
                options={yearOptions}
                value={selectedYear}
                onChange={(selected) => {
                  console.log(selected)
                  renderHoldersBySelectedYear(selected.value)
                  setSelectedYear(selected)
                }}
              />
              <input
                type="text"
                id="search-name"
                placeholder={`Search Name`}
                value={searchName}
                onChange={(e) => handleSearchName(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="account-list-body">
          <div className="account-list-table-header">
            <div className="account-row common-row header">
              {viewMode === 'add-holder' ? (
                <>
                  <span>Id</span>
                  <span>Date</span>
                  <span>Name</span>
                  <span>Phone</span>
                  <span>Type</span>
                  <span>Balance</span>
                  <span>Note</span>
                  {!downloadClicked && <span>Action</span>}
                  
                </>
              ) : (
                  <>
                    <span>Id</span>
                    <span>Type</span>
                    <span>Amount</span>
                    <span>Date</span>
                    <span>Transactor</span>
                    <span>Note</span>
                  </>
              )}
              
            </div>
          </div>
          {viewMode === 'add-holder' ? (
            <div className="account-list-table-body">
              {holders.length === 0 ? (<p style={{ marginTop: '20px' }}>No Loan is available</p>)
                : holders.map((_item, _index) => (
                  <div key={_index} className='account-row common-row'>
                    {/* <span>{moment(_item.date).format('YYYY-MM-DD')}</span> */}
                    <span>{_item.readableId}</span>
                    <span>{_item.date ? moment(_item.date).format('YYYY-MM-DD') : '-'}</span>
                    <span>{_item.name}</span>
                    <span>{_item.phone}</span>
                    <span>{_item.type}</span>
                    <span style={_item.type === 'Credit' ? {color: 'red'} : {color: 'green'}}>{getFormattedReadableNumber(_item.amount)}</span>
                    <span>{_item.note}</span>
                    {!downloadClicked && (
                      <span>
                        {getHolderEditOption(_item)}
                        {_user.role === 'super_admin' && getHolderDeleteOption(_item._id)}
                      </span>
                    )}
                  </div>
              ))}
            </div>
          ) : (
            <div className="account-list-table-body">
              {transactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction is available</p>)
                : transactions.map((_item, _index) => (
                  <div key={_index} className='account-row common-row'>
                    {/* <span>{moment(_item.date).format('YYYY-MM-DD')}</span> */}
                    <span>{_item.readableId}</span>
                    <span>{_item.type}</span>
                    <span style={_item.type === 'Cash In' ? {color: 'green'} : {color: 'red'}}>{getFormattedReadableNumber(_item.amount)}</span>
                    <span>{moment(_item.createdAt).format('YYYY-MM-DD')}</span>
                    <span>{_item.transactorName}</span>
                    <span>{_item.note}</span>
                  </div>
              ))}
            </div>
          )}
          
        </div>
      </div>
    </div>
  )
}

export default DailyAccount;