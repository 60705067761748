import axios from '../config/axios'
import moment from 'moment'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'


export const isLoggedIn = async () => {
  try {
    const { data } = await axios.get(
      '/auth', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('isLoggedIn data: ', data)
    if (data && data.message && data.message === 'authorized') {
      return true
    }
    return false
  } catch (_error) {
    console.log('error: ', _error)
    return false
  }
}

export const redirectHomeIfLoggedIn = async (navigate) => {
  const _isLoggedIn = await isLoggedIn()
  if (_isLoggedIn) {
    navigate('/home')
  }
}

export const redirectLoginIfLoggedOut = async (navigate) => {
  const _isLoggedIn = await isLoggedIn()
  if (!_isLoggedIn) {
    navigate('/')
  }
}

export const removeObjectPrototype = (oldObj) => {
  const _newObj = Object.create(null)
  for (const field in oldObj) {
    _newObj[field] = oldObj[field]
  }
  return _newObj
}

export const getCurrentUser = () => JSON.parse(localStorage.getItem('user'))

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const isValidEmail = (_email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(_email).toLowerCase())
}

export const getToday = () => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const getYesterday = () => {
  const today = new Date()
  today.setDate(today.getDate() - 1);
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const getPriorNthDate = (days) => {
  const today = new Date()
  const targetDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000)
  const dd = String(targetDate.getDate()).padStart(2, '0')
  const mm = String(targetDate.getMonth() + 1).padStart(2, '0')
  const yyyy = targetDate.getFullYear()
  return yyyy + '-' + mm + '-' + dd
} 

export const handleTransactionDownloadPdf = async (from, transactions) => {
  const mywindow = window.open('', 'PRINT', 'height=700,width=900')
  mywindow.document.write(`
  <html>
    <head>
      <title>${from === 'buy' ? 'Purchase Transactions' : 'Sale Transactions'} - ${moment().format('YYYYMMDDHHmmss')}</title>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
      <style>
        * {
          font-family: "Poppins", sans-serif;
        }
        h3 {
          font-size: 22px;
          color: #000;
          text-align: center;
        }
        .row {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          border-bottom: solid 1px rgba(0, 0, 0, 0.25);
          padding: 5px 0;
          text-align: center;
        }
        .row.header {
          font-size: 15px;
        }
      </style>`)
  mywindow.document.write('</head><body>')
  mywindow.document.write(`
    <div class='transaction-body'>
      <h3>${from === 'buy' ? 'Purchase Transactions' : 'Sale Transactions'}</h3>
      <div class="transaction-table-header">
        <div class="row header">
          <span>Date</span>
          <span>Purchase Id</span>
          <span>Rice Name</span>
          <span>Vendor</span>
          <span>Quantity (kg)</span>
          <span>Price (৳)</span>
          <span>Status</span>
          <span>Entry Man</span>
        </div>
      </div>
      <div class="transaction-table-body">
        ${transactions.map((_item) => `
          <div class='row'>
            <span>${ moment(_item.date).format('YYYY-MM-DD') }</span>
            <span>${ _item.buyId }</span>
            <span>${ _item.productName }</span>
            <span>${ _item.vendorName }</span>
            <span>${ _item.quantity } </span>
            <span>${ _item.totalPrice } ৳</span>
            <span>${ _item.status || '' }</span>
            <span>${ _item.userName } </span>
          </div>
        `).join('')}
      </div>
    </div>
  `)

  
  mywindow.document.write('</body></html>')
  mywindow.document.close() // necessary for IE >= 10
  mywindow.focus() // necessary for IE >= 10*/
  mywindow.onafterprint = window.close
  mywindow.print()
  
  /*
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL('image/png');

  const pdf = new jsPDF('l');
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth() - 30;
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  
  pdf.addImage(data, 'PNG', 15, 15, pdfWidth, pdfHeight);
  pdf.save('print.pdf');
  // window.html2canvas = html2canvas;
  // var doc = new jsPDF({
  //   unit: "px"
  //   // format: [4, 2]
  // });
  
  // doc.html(element, {
  //   margin: 20,
  //   autoPaging: true,
  //   filename: 'transactions.pdf',
  //   callback: function(doc) {
  //     console.log("in callback");
  //     doc.save();
  //   }
  // });
  */
}; 

export const handleExpenseDownloadPdf = async (transactions) => {
  const mywindow = window.open('', 'PRINT', 'height=700,width=900')
  mywindow.document.write(`
  <html>
    <head>
      <title>Expense Transactions - ${moment().format('YYYYMMDDHHmmss')}</title>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
      <style>
        * {
          font-family: "Poppins", sans-serif;
        }
        h3 {
          font-size: 22px;
          color: #000;
          text-align: center;
        }
        .row {
          display: grid;
          grid-template-columns: repeat(5, 1fr) 2fr;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          border-bottom: solid 1px rgba(0, 0, 0, 0.25);
          padding: 5px 0;
          text-align: center;
        }
        .row.header {
          font-size: 15px;
        }
      </style>`)
  mywindow.document.write('</head><body>')
  mywindow.document.write(`
    <div class='transaction-body'>
      <h3>Expense Transactions</h3>
      <div class="transaction-table-header">
        <div class="row header">
          <span>Date</span>
          <span>Expense ID</span>
          <span>Entry Man</span>
          <span>Type</span>
          <span>Amount (৳)</span>
          <span>Note</span>
        </div>
      </div>
      <div class="transaction-table-body">
        ${transactions.map((_item) => `
          <div key={_index} class='row'>
            <span>${ moment(_item.date).format('YYYY-MM-DD') }</span>
            <span>${ _item.expenseId || _item.expenseProductId || '-' }</span>
            <span>${ _item.userName }</span>
            <span>${ _item.type }</span>
            <span>${ parseFloat(_item.amount).toFixed(2) }</span>
            <span>${ _item.note }</span>
          </div>
        `).join('')}
      </div>
    </div>
  `)

  
  mywindow.document.write('</body></html>')
  mywindow.document.close() // necessary for IE >= 10
  mywindow.focus() // necessary for IE >= 10*/
  mywindow.onafterprint = window.close
  mywindow.print()
}

export const getValidNumber = (value) => isNaN(parseInt(value)) ? '' : parseInt(value)
export const getValidPositiveNumber = (value) => isNaN(parseInt(value)) ? '' : parseInt(value) > 0 ? parseInt(value) : ''
export const getFloatNumber = (value, prev) => !isNaN(value) ? value: prev

export const generatePDF = (fileName, elementRef, setDownloadClicked) => {
  // const fileName = `product-expenses-${moment().format('YYYY-MM-DD')}.pdf`
  html2canvas(elementRef.current).then((canvas) => {
    document.body.appendChild(canvas);  // if you want see your screenshot in body.
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('l');

    var imgWidth = 295; //210
    var pageHeight = 210;  //295
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    var position = 0;

    console.log('heightLeft: ', heightLeft)
    
    pdf.addImage(imgData, 'PNG', 0, 0);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position);
      // pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save(fileName); 

    setDownloadClicked(false)
    const _canvases = document.querySelectorAll('canvas')
    console.log('_canvases: ', _canvases)
    _canvases.forEach((_canvas) => _canvas.style.display = 'none')
  })
}





export const getDateFromDateTime = (d) => {
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()
  
  const year = d.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-')
}

export const getFormattedReadableNumber = (number) => {
  const _tempNumber = parseFloat(number).toFixed(2)
  // const _nc = 4979348
  // console.log('frn input: ', _nc)
  const _tempStrings = _tempNumber.toString().split('.')
  // console.log('frn _tempStrings: ', _tempStrings)
  let result = ''
  const _len1 = _tempStrings[0].length
  for (let _in = 0; _in < _len1; ++_in) {
    const _char = _tempStrings[0][_in]
    // console.log('frn char: ', _in, _char)
    result += _char

    const _lenDiff = _len1 - _in
    
    if ((_lenDiff === 4 || _lenDiff === 6 || _lenDiff === 8) && _char !== '-') {
      // console.log('frn comma: ', ',')
      result += ','
    }
    
    
  }

  if (_tempStrings[1] && _tempStrings[1].length > 0) {
    result += '.'
    result += _tempStrings[1]
  }

  // console.log('frn output: ', result)


  return result
}