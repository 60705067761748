import axios from "../config/axios"
import { removeObjectPrototype } from "./helper"
export const fetchAllusers = async () => {
  try {
    const { data } = await axios.get(
      '/user', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    return data.map((_item) => removeObjectPrototype(_item))
  } catch (_error) {
    console.log('error: ', _error)
    return []
  }
}

export const fetchAllEntities = async () => {
  try {
    const { data } = await axios.get(
      '/entity', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('entities: ', data)
    return data.map((_item) => removeObjectPrototype(_item))
    
  } catch (_error) {
    console.log('error: ', _error)
    return []
  }
}

export const fetchAllProducts = async () => {
  try {
    const { data } = await axios.get("/product", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data.map((_item) => removeObjectPrototype(_item))
    
  } catch (_error) {
    console.log("error: ", _error);
    // message.error(_error?.response?.data?.message | "Something wrong. Please Refresh the page.");
  }
};

export const fetchEntityInfoByEntityId = async (entityId) => {
  try {
    const { data } = await axios.get(
      `/transaction/entityinfo/${entityId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log(`/transaction/entityinfo/${entityId}: `, data)
    return removeObjectPrototype(data)
  } catch (_error) {
    console.log('error: ', _error)
  }
}

export const fetchTransactionById = async (___id) => {
  try {
    const { data } = await axios.get(
      `/transaction/${___id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('transaction: ', data)
    return removeObjectPrototype(data)
    // setSelectedTransaction(_data)
    // if (_data && _data.entityId) {
    //   await fetchEntityById(_data.entityId)
    //   await fetchTransactionsByEntityId(_data.entityId)
    // }
    
  } catch (_error) {
    console.log('fetchTransactionById error: ', _error)
  }
}

export const fetchEntityById = async (___id) => {
  try {
    const { data } = await axios.get(
      `/entity/${___id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('fetchEntityById  data: ', data)
    return removeObjectPrototype(data)
    // setEntity(_data)
  } catch (_error) {
    console.log('fetchEntityById error: ', _error)
  }
}

export const fetchTransactionsByEntityId = async (___id) => {
  try {
    const { data } = await axios.get(
      `/transaction/entity/${___id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('fetchTransactionsByEntityId data: ', data)
    return data.map((_item) => removeObjectPrototype(_item))
  } catch (_error) {
    console.log('fetchTransactionsByEntityId error: ', _error)
  }
}

export const fetchTransactionHistoryByTransactionId = async (___id) => {
  try {
    const { data } = await axios.get(
      `/transactionhistory/transaction/${___id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('fetchTransactionHistoryByTransactionId data: ', data)
    return data.map((_item) => removeObjectPrototype(_item))
  } catch (_error) {
    console.log('fetchTransactionHistoryByTransactionId error: ', _error)
  }
}

export const fetchAllExpenseTypes = async () => {
  try {
    const { data } = await axios.get(
      '/expense-type', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    // console.log('fetchAllExpenseTypes data: ', data)
    return data.map((_item) => removeObjectPrototype(_item))
    
  } catch (_error) {
    console.log('error: ', _error)
  }
}

export const fetchExpenseTypeById = async (_expenseTypeId) => {
  try {
    const { data } = await axios.get(
      '/expense-type/' + _expenseTypeId, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    // console.log('fetchAllExpenseTypes data: ', data)
    return removeObjectPrototype(data)
    
  } catch (_error) {
    console.log('error: ', _error)
  }
}

export const fetchBuySellTransactions = async (dateFilter, startDate, endDate) => {

  const products = await fetchAllProducts()
  const users = await fetchAllusers()
  const entities = await fetchAllEntities()

  const _path =
    dateFilter !== "custom"
      ? `/transaction/${dateFilter}`
      : `/transaction/${startDate}/${endDate}`;
  
  try {
    const { data } = await axios.post(_path, { timeOffset: (new Date()).getTimezoneOffset() }, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("transaction data: ", data);
    return data.map((_item) => removeObjectPrototype(_item)).map((_item) => {
      const _product = products.find((_item2) => _item2._id === _item.productId) 
      const __user = users.find((_item2) => _item2._id === _item.userId)
      const __entity = entities.find((_item2) => _item2._id === _item.entityId)
      return {
        ..._item,
        userName: __user?.name || '-',
        productName: _product?.label || '-',
        entityName: __entity?.name || '-',
        totalPrice: _item.unitPrice * _item.quantity
      }
    })
  } catch (_error) {
    console.log("error: ", _error);
    // message.error(_error?.response?.data?.message | "Something wrong. Please Refresh the page.");
  }
};

export const fetchExpenseProductTransactions = async (dateFilter, startDate, endDate) => {
  const products = await fetchAllProducts()
  const users = await fetchAllusers()
  const expenseTypes = await fetchAllExpenseTypes()


  const _path = dateFilter !== 'custom'
    ? `/expenseproduct/${dateFilter}`
    : `/expenseproduct/${startDate}/${endDate}`
  
  try {
    const { data } = await axios.post(
      _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    
    return data.map((_item) => {
      const targetUser = users.find((_item2) => _item2._id === _item.userId)
      const targetProduct = products.find((_item2) => _item2._id === _item.productId)
      return {
        ...removeObjectPrototype(_item),
        userName: targetUser?.name || '-',
        productName: targetProduct?.label || '-',
        type: expenseTypes.find((_item2) => _item2.value === _item.typeId)?.label || '-',
      }
    }).sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0)
  
  } catch (_error) {
    console.log('error: ', _error)
    // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
  }
}


export const fetchExpenseAllTransactions = async (dateFilter, startDate, endDate) => {
  
  const users = await fetchAllusers()
  const expenseTypes = await fetchAllExpenseTypes()

  const _path = dateFilter !== 'custom'
    ? `/expense/${dateFilter}`
    : `/expense/${startDate}/${endDate}`
  
  console.log('fetchExpenseTransactions: ', _path)
  try {
    const { data } = await axios.post(
      _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    
    return data.map((_item) => {
      const targetUser = users.find((_item2) => _item2._id === _item.userId)
      // console.log(targetUser.name)
      return {
        ...removeObjectPrototype(_item),
        userName: targetUser?.name || '-',
        type: expenseTypes.find((_item2) => _item2.value === _item.typeId)?.label || '-',
      }
    }).sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0)
  } catch (_error) {
    console.log('error: ', _error)
    // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
  }
}

export const fetchDeibtorsCreditorsTransactions = async (dateFilter, startDate, endDate) => {
  
  const users = await fetchAllusers()
  const _path = dateFilter !== 'custom'
    ? `/dailyaccount/${dateFilter}`
    : `/dailyaccount/${startDate}/${endDate}`
  
  console.log('fetchExpenseTransactions: ', _path)
  try {
    const { data } = await axios.post(
      _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log('dailyaccount transactions: ', data)
    return data.map((_item) => {
      const targetUser = users.find((_item2) => _item2._id === _item.userId)
      const _removedProto = removeObjectPrototype(_item)
      return {
        ..._removedProto._doc,
        accountHolder: _removedProto.accountHolder,
        userName: targetUser?.name || '-',
      }
    })
      .sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0)
      .filter((_it) => _it.accountHolder)
  } catch (_error) {
    console.log('error: ', _error)
    // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
  }
}


export const fetchBankTransactions = async (dateFilter, startDate, endDate) => {
  
  const users = await fetchAllusers()
  const _path = dateFilter !== 'custom'
    ? `/bankloantransaction/${dateFilter}`
    : `/bankloantransaction/${startDate}/${endDate}`
  
  console.log('fetchExpenseTransactions: ', _path)
  try {
    const { data } = await axios.post(
      _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    
    return data.map((_item) => {
      const targetUser = users.find((_item2) => _item2._id === _item.userId)
      const _removedProto = removeObjectPrototype(_item)
      return {
        ..._removedProto._doc,
        bankLoan: _removedProto.bankLoan,
        userName: targetUser?.name || '-',
      }
    })
      .sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0)
      .filter((_it) => _it.bankLoan)
  } catch (_error) {
    console.log('error: ', _error)
    // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
  }
}


export const fetchTransactionHistories = async (dateFilter, startDate, endDate) => {
  
  const products = await fetchAllProducts()
  const users = await fetchAllusers()
  const entities = await fetchAllEntities()

  const _path = dateFilter !== 'custom'
    ? `/transactionhistory/${dateFilter}`
    : `/transactionhistory/${startDate}/${endDate}`
  
  console.log('transactionhistory path: ', _path)
  try {
    const { data } = await axios.post(
      _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    
    return data.map((_item) => {
      const _removedProto = removeObjectPrototype(_item)
      const targetUser = users.find((_item2) => _item2._id === _item.userId)
      const targetProduct = products.find((_item2) => _item2._id === _item?.transaction?.productId)
      const targetEntity = entities.find((_item2) => _item2._id === _item?.transaction?.entityId)
      return {
        ..._removedProto._doc,
        transaction: _removedProto.transaction,
        userName: targetUser?.name || '-',
        generatedType: targetProduct && targetEntity && targetProduct.name && targetEntity.name ? targetEntity.name + ' - ' + targetProduct.name : '-'
      }
    })
      .sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0)
      .filter((_it) => _it.transaction)
  } catch (_error) {
    console.log('error: ', _error)
    // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
  }
}

export const fetchAdvanceTransactionByEntityId = async (entityId) => {
  try {
    const { data } = await axios.get(
      `/entityadvance/transaction/${entityId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    console.log(`/entityadvance/transaction/${entityId}: `, data)
    return data.map((_item) => removeObjectPrototype(_item))
  } catch (_error) {
    console.log('error: ', _error)
  }
}

export const fetchAdvancePaidTransaction = async (dateFilter, startDate, endDate) => {
  
  const users = await fetchAllusers()
  const entities = await fetchAllEntities()

  const _path = dateFilter !== 'custom'
    ? `/entityadvance/${dateFilter}`
    : `/entityadvance/${startDate}/${endDate}`
  const body = { timeOffset: (new Date()).getTimezoneOffset() }
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  
  console.log('entity advance path: ', _path)
  try {
    const { data } = await axios.post(_path, body, config)

    console.log('removed proto: ', data)
    
    return data.map((_item) => {
      const _removedProto = removeObjectPrototype(_item)
      const targetUser = users.find((_item2) => _item2._id === _item.userId)
      
      return {
        ..._removedProto._doc,
        entity: _removedProto.entity,
        userName: targetUser?.name || '-',
      }
    })
      .sort((_a, _b) => _a.createdAt > _b.createdAt ? -1 : _a.createdAt < _b.createdAt ? 1 : 0)
  } catch (_error) {
    console.log('error: ', _error)
    // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
  }
}