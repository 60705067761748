import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import moment from 'moment'
import { message, Spin, Modal } from 'antd'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import axios from '../config/axios'
import '../style/buy.css'
import '../style/expense.css'
import '../style/user.css'
import {
  getCurrentUser,
  removeObjectPrototype,
  redirectLoginIfLoggedOut,
  isValidEmail
} from '../utils/helper'

const userTypeOptions = [
  {
    label: 'Operator',
    value: 'operator'
  },
  {
    label: 'Super Admin',
    value: 'super_admin'
  }
]
const User = ({
  setTitle: setPageTitle
}) => {

  const navigate = useNavigate()
  const _user = getCurrentUser()

  const [mode, setMode] = useState('view')
  const [userLoading, setUserLoading] = useState(true)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [deleteUserLoading, setDeleteUserLoading] = useState(true)
  const [userSubmitLoading, setUserSubmitLoading] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [users, setUsers] = useState([])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [selectedRole, setSelectedRole] = useState(userTypeOptions[0])
  const [phone, setPhone] = useState('')

  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/home')
    }
    if (_user && _user.role !== 'super_admin') {
      setPageTitle('Home')
      navigate('/home')
    }
    fetchAllusers()
  }, [])
  
  const fetchAllusers = async () => {
    try {
      const { data } = await axios.get(
        '/user', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('users: ', data)
      
      setUsers(data.map((_item) => removeObjectPrototype(_item)))
      
    } catch (_error) {
      console.log('error: ', _error)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }

  
  const handleUserSubmit = async () => {

    const body = {
      name,
      email,
      password,
      phone,
      role: selectedRole.value,
    }

    if (!body.name) {
      message.info('User Name is required')
      return
    }
    if (!body.email) {
      message.info('User Email is required')
      return
    }
    if (!isValidEmail(body.email)) {
      message.info('User Email is Invalid')
      return
    }
    if (!body.password) {
      message.info('Password is required')
      return
    }
    if (body.password.length < 6) {
      message.info('Password length must be greater than 5 character')
      return
    }
    if (!body.phone) {
      message.info('User Phone Number is required')
      return
    }

    console.log('body: ', body)
    
    setUserSubmitLoading(true)
    try {
      const { data } = mode === 'view'
        ? await axios.post(
          '/user',
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        ) : 
          await axios.put(
            `/user/${selectedId}`,
            body, {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              }
            }
          )
      
      message.success(data.message)
      setUserSubmitLoading(false)
      setName('')
      setEmail('')
      setPassword('')
      setPhone('')
      setSelectedRole(userTypeOptions[0])
      fetchAllusers()
      setMode('view')
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setUserSubmitLoading(false)
    }
  }

  const handleUserDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/user/${selectedId}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      fetchAllusers()
      setDeleteUserLoading(false)
      setDeleteConfirmModal(false)
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setUserSubmitLoading(false)
    }
  }

  return (
    <div className="user-container">
      <Modal
        visible={deleteConfirmModal}
        title="User Delete Confirmation"
        onCancel={() => {
          setSelectedId('')
          setDeleteConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setDeleteConfirmModal(false)
              setSelectedId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            loading={deleteUserLoading}
            onClick={handleUserDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Account?</p>
      </Modal>
      {mode === 'view' && (
        <div className="user-table-container-outer">
          <div className="header-title">
            <h2>All Users</h2>
          </div>
          <div className="user-table-container">
            <div className="user-table-header">
              <div className={`user-row header ${_user.role === 'super_admin' ? 'user-row-edit-delete' : ''}`}>
                <span>Name</span>
                <span>Role</span>
                <span>Phone</span>
                <span>Email</span>
                <span>Total Entry</span>
                {_user.role === 'super_admin' && (
                  <span>Actions</span>
                )}
              </div>
            </div>
            <div className="user-table-body">
              {users.length === 0 ? (<p style={{ marginTop: '20px' }}>No User is available</p>)
                : users.map((userItem, _index) => (
                <div
                  key={_index}
                  className={`user-row ${_user.role === 'super_admin' ? 'user-row-edit-delete' : ''}`}
                >
                  <span>{ userItem.name }</span>
                  <span>{ userItem.role === 'super_admin' ? 'Super Admin' : 'Operator' }</span>
                  <span>{ userItem.phone }</span>
                  <span>{ userItem.email }</span>
                  <span>{ userItem.entryCount }</span>
                {_user.role === 'super_admin' && (
                  <span className="edit-delete">
                    <span
                      onClick={() => {
                        setSelectedId(userItem._id)
                        setName(userItem.name)
                        setEmail(userItem.email)
                        setPhone(userItem.phone)
                        setSelectedRole(userTypeOptions.find((_item) => _item.value === userItem.role))
                        setMode('edit')
                      }}
                    >
                      <EditOutlinedIcon />
                    </span>
                    <span
                      onClick={() => {
                        if (userItem._id === _user._id) {
                          message.info('This user cannot be deleted.')
                          return
                        }
                        setSelectedId(userItem._id)
                        setDeleteConfirmModal(true)
                      }}
                    >
                      <DeleteOutlinedIcon />
                    </span>
                  </span>
                )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="add-user-container">
        <div className="add-user-header">
          <h2>{mode === 'view' ? 'Add' : 'Edit'} User</h2>
          {mode === 'edit' && (
            <button
              className='custom-button custom-button-sm'
              onClick={() => {
                setName('')
                setEmail('')
                setPassword('')
                setPhone('')
                setSelectedRole(userTypeOptions[0])
                setMode('view')
              }}
            >
              Back
            </button>
          )}
        </div>
        <div className="add-user-body">
          <div className="input-item">
            <label htmlFor="user-name">User Name</label>
            <input
              type="text"
              id="user-name"
              placeholder="Enter User Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="user-email">User Email</label>
            <input
              type="email"
              id="user-email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="user-password">Password</label>
            <input
              type="text"
              id="user-password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="user-phone">Phone Number</label>
            <input
              type="text"
              id="user-phone"
              placeholder="Enter Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label htmlFor="vendorName">User Type</label>
            <Select
              options={userTypeOptions}
              value={selectedRole}
              onChange={(selected) => {
                console.log(selected)
                setSelectedRole(selected)
              }}
            />
          </div>
        </div> 
        <div className="add-user-footer">
          {userSubmitLoading ? <Spin size="large" /> : (
            <button
              className="buy-submit"
              onClick={handleUserSubmit}
            >
              {mode === 'view' ? 'Add' : 'Update'}
            </button>
          )}
        </div>
      </div>
      
      
    </div>
  )
}

export default User