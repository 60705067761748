import React from 'react'
import '../style/not_found.css'

const NotExists = () => {
  return (
    <div className='not-exists-container'>
      <div className="not-exists-inner">
        <h1>404</h1>
        <h2>Page not found</h2>
      </div>
    </div>
  )
}

export default NotExists