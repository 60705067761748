
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { Spin, message, Modal } from "antd"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  AiOutlineDownload,
  AiOutlineUpload
} from "react-icons/ai";
import CustomDateFilter from "../components/CustomDateFilter";
import { MultiSelect } from "../components/MultiSelect";
import BuySell from './BuySell'
import axios from "../config/axios";
import {
  redirectLoginIfLoggedOut,
  removeObjectPrototype,
  getToday,
  getYesterday,
  getPriorNthDate,
  getCurrentUser,
  generatePDF,
  getFormattedReadableNumber
} from '../utils/helper';
import {
  fetchAllEntities,
  fetchAllProducts,
  fetchAllusers,
  fetchExpenseAllTransactions,
  fetchExpenseProductTransactions,
  fetchBuySellTransactions, 
  fetchAllExpenseTypes,
  fetchDeibtorsCreditorsTransactions,
  fetchBankTransactions,
  fetchTransactionHistories,
  fetchAdvancePaidTransaction
} from '../utils/apis'
import '../style/common.css'
import '../style/buy.css'
import '../style/expense.css'
import "../style/dashboard.css";

function Home() {
  
  const navigate = useNavigate();
  const [mode, setMode] = useState('dashboard');
  const [recentLoading, setRecentLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(true);
  const [cashHistoryLoading, setCashHistoryLoading] = useState(true);
  const [transactionStatLoading, setTransactionStatLoading] = useState(true)
  const [transactionDebitorsLoading, setTransactionDebitorsLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [entities, setEnities] = useState([])
  const [holders, setHolders] = useState([])
  const [expenseTypes, setExpenseTypes] = useState([])
  
  const [dateStatFilter, setDateStatFilter] = useState("today");
  const [dateTransactionFilter, setDateTransactionFilter] = useState("today");
  const [dateRequestFilter, setDateRequestFilter] = useState("today");
  const [dateDebitorsFilter, setDateDebitorsFilter] = useState("today");
  const [dateRecentFilter, setDateRecentFilter] = useState("today");
  const [dateCashHistoryFilter, setDateCashHistoryFilter] = useState("today");
  const [startDateStatFilter, setStartDateStatFilter] = useState('')
  const [startDateRequestFilter, setStartDateRequestFilter] = useState('')
  const [startDateTransactionFilter, setStartDateTransactionFilter] = useState('')
  const [startDateDebitorsFilter, setStartDateDebitorsFilter] = useState('')
  const [startDateRecentFilter, setStartDateRecentFilter] = useState('')
  const [startDateCashHistoryFilter, setStartDateCashHistoryFilter] = useState('')
  const [endDateStatFilter, setEndDateStatFilter] = useState('')
  const [endDateRequestFilter, setEndDateRequestFilter] = useState('')
  const [endDateTransactionFilter, setEndDateTransactionFilter] = useState('')
  const [endDateDebitorsFilter, setEndDateDebitorsFilter] = useState('')
  const [endDateRecentFilter, setEndDateRecentFilter] = useState('')
  const [endDateCashHistoryFilter, setEndDateCashHistoryFilter] = useState('')

  const [stats, setStats] = useState({});
  const [allTransactions, setAllTransactions] = useState([]);
  const [buyTransactions, setBuyTransactions] = useState([]);
  const [saleTransactions, setSaleTransactions] = useState([]);
  const [buyRequests, setBuyRequests] = useState([])
  const [saleRequests, setSaleRequests] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [multiSelectedProducts, setMultiSelectedProducts] = useState([])
  const [filteredDate, setFilteredDate] = useState(getToday())
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [recentTransactions, setRecentTransactions] = useState([])
  const [cashHistories, setCashHistories] = useState([])

  const [buyPdfDownloadClicked, setBuyPdfDownloadClicked] = useState(false)
  const [sellPdfDownloadClicked, setSellPdfDownloadClicked] = useState(false)
  const [recentDownloadClicked, setRecentDownloadClicked] = useState(false)

  const [statusEdit, setStatusEdit] = useState(false)

  const [buyTotalPaid, setBuyTotalPaid] = useState(0)
  const [buyTotalDue, setBuyTotalDue] = useState(0)
  const [saleTotalPaid, setSaleTotalPaid] = useState(0)
  const [saleTotalDue, setSaleTotalDue] = useState(0)

  const [recentDebitorCashInTotal, setRecentDebitorCashInTotal] = useState(0)
  const [recentDebitorCashOutTotal, setRecentDebitorCashOutTotal] = useState(0)
  const [recentCreditorCashInTotal, setRecentCreditorCashInTotal] = useState(0)
  const [recentCreditorCashOutTotal, setRecentCreditorCashOutTotal] = useState(0)
  
  const [recentBankCreditCashInTotal, setRecentBankCreditCashInTotal] = useState(0)
  const [recentBankCreditCashOutTotal, setRecentBankCreditCashOutTotal] = useState(0)
  const [recentBankDebitCashInTotal, setRecentBankDebitCashInTotal] = useState(0)
  const [recentBankDebitCashOutTotal, setRecentBankDebitCashOutTotal] = useState(0)

  const [recentSaleSubTotal, setRecentSaleSubTotal] = useState(0)
  const [recentBuySubTotal, setRecentBuySubTotal] = useState(0)

  const [vendorAdvanceTotal, setVendorAdvanceTotal] = useState(0)
  const [customerAdvanceTotal, setCustomerAdvanceTotal] = useState(0)

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  const buyTransactionRef = useRef()
  const saleTransactionRef = useRef()
  const recentTransactionRef = useRef()

  const _user = getCurrentUser()

  useEffect(() => {
    // if (_user && _user.)
    init()
  }, []);

  useEffect(() => {
    if (dateStatFilter === 'today') {
      setFilteredDate(getToday())
    } else if (dateStatFilter === 'yesterday') {
      setFilteredDate(getYesterday())
    } else if (dateStatFilter === 'lastweek') {
      setFilteredDate(`${getPriorNthDate(7)}  -  ${getToday()}`)
    } else if (dateStatFilter === 'lastmonth') {
      setFilteredDate(`${getPriorNthDate(30)}  -  ${getToday()}`)
    } else {
      // setFilteredDate('')
    }

    if (dateStatFilter !== "custom") {
      fetchTransactionStat();
    }
  }, [dateStatFilter]);

  useEffect(() => {
    if (dateTransactionFilter !== "custom") {
      console.log('sm fetchTransactions called')
      fetchTransactions();
    }
  }, [dateTransactionFilter]);

  useEffect(() => {
    if (dateRequestFilter !== "custom") {
      fetchRequests();
    }
  }, [dateRequestFilter, users, productOptions, entities]);

  useEffect(() => {
    if (dateCashHistoryFilter !== "custom") {
      fetchCashHistories();
    }
  }, [dateCashHistoryFilter]);

  
  useEffect(() => {
    if (dateDebitorsFilter !== "custom") {
      fetchDebitors();
    }
  }, [dateDebitorsFilter]);

  useEffect(() => {
    if (dateRecentFilter !== "custom") {
      fetchRecents()
    }
  }, [dateRecentFilter])

  useEffect(() => {
    console.log('sm fetchTransactions called')
    fetchTransactions()
  }, [users, productOptions])

  useEffect(() => {
    // console.log('buyTransactions: ', buyTransactions)
    // console.log('saleTransactions: ', saleTransactions)
  }, [buyTransactions])

  // useEffect(() => {
  //   fetchTransactionStat()
  // }, [selectedProduct])

  useEffect(() => {
    console.log('multiSelectedProducts: ', multiSelectedProducts)
    fetchTransactionStat()
  }, [multiSelectedProducts])

  useEffect(() => {
    generateTransactionData()
  }, [allTransactions, productOptions, users, entities])

  

  useEffect(() => { 
    let _paid = 0
    let _due = 0
    buyTransactions.forEach((_it) => {
      _paid += _it.paid 
      _due += _it.due 
    })
    setBuyTotalPaid(_paid)
    setBuyTotalDue(_due)
  }, [buyTransactions])
  
  useEffect(() => { 
    let _paid = 0
    let _due = 0
    saleTransactions.forEach((_it) => {
      _paid += _it.paid 
      _due += _it.due 
    })
    setSaleTotalPaid(_paid)
    setSaleTotalDue(_due)
  }, [saleTransactions])

  const init = async () => {
    redirectLoginIfLoggedOut(navigate)
    const _products = await fetchAllProducts()
    setProductOptions(_products.map((_item) => ({
      value: _item._id,
      label: _item.name
    })))
    if (_products.length > 0) {
      setSelectedProduct({
        value: _products[0]._id,
        label: _products[0].name
      })
    }
    const _expenseTypes = await fetchAllExpenseTypes()
    setExpenseTypes(_expenseTypes)
    const _users = await fetchAllusers()
    setUsers(_users)
    const _entites = await fetchAllEntities()
    setEnities(_entites)
  }
  
  const generateTransactionData = () => {
    const _nameIncludedData = allTransactions.map((_item) => {
      const _product = productOptions.find((_item2) => _item2.value === _item.productId)
      const __user = users.find((_item2) => _item2._id === _item.userId)
      const __entity = entities.find((_item2) => _item2._id === _item.entityId)
      return {
        ..._item,
        userName: __user?.name || '-',
        productName: _product?.label || '-',
        entityName: __entity?.name || '-',
        totalPrice: _item.unitPrice * _item.quantity
      }
    })

    let _temp = _user.role === 'operator'
      ? _nameIncludedData.filter((_item) => _item.type === 'buy' && _item.userId === _user._id)
      : _nameIncludedData.filter((_item) => _item.type === 'buy')
    setBuyTransactions(_temp.sort((_a, _b) => _a.buyId > _b.buyId ? -1 : _a.buyId < _b.buyId ? 1 : 0))
    _temp = _user.role === 'operator'
      ? _nameIncludedData.filter((_item) => _item.type === 'sell' && _item.userId === _user._id)
      : _nameIncludedData.filter((_item) => _item.type === 'sell')
    setSaleTransactions(_temp.sort((_a, _b) => _a.sellId > _b.sellId ? -1 : _a.sellId < _b.sellId ? 1 : 0))
  }

  const fetchTransactions = async () => {
    const formatedStartDate = startDateTransactionFilter
    const formatedEndDate = endDateTransactionFilter
    console.log('sm dateTransactionFilter: ', dateTransactionFilter)
    if (formatedStartDate) {
      setFilteredDate(`${formatedStartDate}  -  ${formatedEndDate}`)
    }
    setTransactionLoading(true);
    const _path =
      dateTransactionFilter !== "custom"
        ? `/transaction/${dateTransactionFilter}`
        : `/transaction/${formatedStartDate}/${formatedEndDate}`;
    
    try {
      const { data } = await axios.post(_path, {timeOffset: (new Date()).getTimezoneOffset()}, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("transaction data: ", data);
      setAllTransactions(data.map((_item) => removeObjectPrototype(_item)))
      setTransactionLoading(false);
    } catch (_error) {
      console.log("error: ", _error);
      setTransactionLoading(false);
      // message.error(_error?.response?.data?.message | "Something wrong. Please Refresh the page.");
    }
  };

  const fetchRequests = async () => {
    const formatedStartDate = startDateRequestFilter
    const formatedEndDate = endDateRequestFilter
    
    setRequestLoading(true);
    const _path =
      dateRequestFilter !== "custom"
        ? `/transaction/${dateRequestFilter}`
        : `/transaction/${formatedStartDate}/${formatedEndDate}`;
    
    try {
      const { data } = await axios.post(_path, {timeOffset: (new Date()).getTimezoneOffset()}, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("request data: ", data);
      if (data && data.length > 0) {
        const _filteredData = data.map((_item) => removeObjectPrototype(_item))
        const _nameIncludedData = _filteredData.map((_item) => {
          const _product = productOptions.find((_item2) => _item2.value === _item.productId)
          const __user = users.find((_item2) => _item2._id === _item.userId)
          const __entity = entities.find((_item2) => _item2._id === _item.entityId)
          return {
            ..._item,
            userName: __user?.name || '-',
            productName: _product?.label || '-',
            entityName: __entity?.name || '-',
            totalPrice: _item.unitPrice * _item.quantity
          }
        })

        let _temp = _user.role === 'operator'
          ? _nameIncludedData.filter((_item) =>
            _item.type === 'buy'
            && _item.editRequest
            && _item.editRequest.status === 'accepted'
            && _item.editRequest.requestedBy === _user._id
            && _item.userId === _user._id
          )
          : _nameIncludedData.filter((_item) =>
            _item.type === 'buy'
            && _item.editRequest
            && _item.editRequest.status === 'pending'
          )
        setBuyRequests(_temp.sort((_a, _b) => _a.buyId > _b.buyId ? -1 : _a.buyId < _b.buyId ? 1 : 0))

        _temp = _user.role === 'operator'
          ? _nameIncludedData.filter((_item) =>
            _item.type === 'sell'
            && _item.editRequest
            && _item.editRequest.status === 'accepted'
            && _item.editRequest.requestedBy === _user._id
            && _item.userId === _user._id
          )
          : _nameIncludedData.filter((_item) =>
            _item.type === 'sell'
            && _item.editRequest
            && _item.editRequest.status === 'pending'
          )
        setSaleRequests(_temp.sort((_a, _b) => _a.sellId > _b.sellId ? -1 : _a.sellId < _b.sellId ? 1 : 0))
      } else {
        setBuyRequests([])
        setSaleRequests([])
      }
      setRequestLoading(false);
    } catch (_error) {
      console.log("error: ", _error);
      setRequestLoading(false);
      // message.error(_error?.response?.data?.message | "Something wrong. Please Refresh the page.");
    }
  };

  const fetchCashHistories = async () => {
    const formatedStartDate = startDateCashHistoryFilter
    const formatedEndDate = endDateCashHistoryFilter
    
    setCashHistoryLoading(true);
    const _path =
      dateCashHistoryFilter !== "custom"
        ? `/cashhistory/${dateCashHistoryFilter}`
        : `/cashhistory/${formatedStartDate}/${formatedEndDate}`;

    const _body = {
      timeOffset: (new Date()).getTimezoneOffset()
    }
    
    const _config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }
    
    try {
      const { data } = await axios.post(_path, _body, _config);
      console.log("cashhistory data: ", data);
      setCashHistories(
        data
          .map((_item) => removeObjectPrototype(_item))
          .sort((_a, _b) => _a.date > _b.date ? -1 : _a.date < _b.date ? 1 : 0)
      )
      setCashHistoryLoading(false);
    } catch (_error) {
      console.log("error: ", _error);
      setCashHistoryLoading(false);
    }
  };
  

  const fetchTransactionStat = async () => {

    const formatedStartDate = startDateStatFilter
    const formatedEndDate = endDateStatFilter
    if (formatedStartDate) {
      setFilteredDate(`${formatedStartDate}  -  ${formatedEndDate}`)
    }

    const _path =
      dateStatFilter !== "custom"
        ? `/transaction/stat/${dateStatFilter}`
        : `/transaction/stat/${formatedStartDate}/${formatedEndDate}`;
    setTransactionStatLoading(true)
    try {
      const { data } = await axios.post(
        _path,
        { productIds: multiSelectedProducts.map((_item) => _item.value) , timeOffset: (new Date()).getTimezoneOffset()},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("stats data: ", data);
      setStats(data);
      setTransactionStatLoading(false);
    } catch (_error) {
      console.log("error: ", _error);
      setTransactionStatLoading(false);
      // message.error(_error?.response?.data?.message | "Something wrong. Please Refresh the page.");
    }
  };
  
  const fetchDebitors = async () => {

    const formatedStartDate = startDateDebitorsFilter
    const formatedEndDate = endDateDebitorsFilter
    if (formatedStartDate) {
      setFilteredDate(`${formatedStartDate}  -  ${formatedEndDate}`)
    }

    const _path =
      dateDebitorsFilter !== "custom"
        ? `/dailyaccountholder/${dateDebitorsFilter}`
        : `/dailyaccountholder/${formatedStartDate}/${formatedEndDate}`;
    setTransactionDebitorsLoading(true)
    try {
      const { data } = await axios.post(
        _path, {timeOffset: (new Date()).getTimezoneOffset()},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("debitors data: ", data);
      setHolders(
        data
          .map((_item) => removeObjectPrototype(_item))
          .sort((_a, _b) => _a.date > _b.date ? -1 : _a.date < _b.date ? 1 : 0)
      )
      setTransactionDebitorsLoading(false);
    } catch (_error) {
      console.log("error: ", _error);
      setTransactionDebitorsLoading(false);
      // message.error(_error?.response?.data?.message | "Something wrong. Please Refresh the page.");
    }
  };
  
  const updateTargetTransaction = async (__id, updateBody) => {
    try {
      const { data } = await axios.put(
        `/transaction/${__id}`,
        updateBody, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      )
      message.success(data.message)
      fetchTransactions()
      fetchRequests()
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('_error.response.data.message: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      // setAddProductLoading(false)
    }
  }

  const getEditRequestOptionForOperator = (transactionItem) => {
    let editRequestStatus = ''
    // transactionItem.editRequest
    let updateBody = {}
    if (!transactionItem.editRequest || transactionItem.editRequest.status === 'none') {
      editRequestStatus = 'Send Edit Request'
      updateBody = {
        editRequest: {
          status: 'pending',
          requestedBy: _user._id,
        },
        _userId: _user._id
      }
    } else if (transactionItem.editRequest.status === 'pending') {
      editRequestStatus = transactionItem.editRequest.requestedBy === _user._id ? 'Your Request is Pending' : 'Requested by Someone'
    } else {
      return null
    }
    
    return (
      <button
        className="custom-button custom-button-sm"
        disabled={transactionItem.editRequest.status === 'pending'}
        onClick={() => updateTargetTransaction(transactionItem._id, updateBody)}
      >
        {editRequestStatus}
      </button>
    )
  }

  const getEditOption = (transactionItem) => (
    <button
      className="custom-button custom-button-sm"
      onClick={() => {
        setSelectedTransaction(transactionItem)
        setMode('update')
      }}
    >
      Edit
    </button>
  )
  
  const getAproveOptionForAdmin = (transactionItem) => (
    <>
      <button
        className="custom-button custom-button-sm"
        onClick={() => {
          let updateBody = {
            editRequest: {
              status: 'accepted',
              acceptedBy: _user._id,
              requestedBy: transactionItem.editRequest.requestedBy
            }
          }
          updateTargetTransaction(transactionItem._id, updateBody)
        }}
      >
        Accept
      </button>
      <button
        className="custom-button custom-button-sm"
        style={{ marginLeft: '10px' }}
        onClick={() => {
          let updateBody = {
            editRequest: {
              status: 'deleted',
              acceptedBy: _user._id,
              requestedBy: transactionItem.editRequest.requestedBy
            }
          }
          updateTargetTransaction(transactionItem._id, updateBody)
        }}
      >
        Ignore
      </button>
    </>
  )

  const fetchRecents = async () => {
    setRecentLoading(true)
    
    // const allTransactions = await fetchBuySellTransactions(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)
    const expenseProductTransactions = await fetchExpenseProductTransactions(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)
    const expenseAllTransactions = await fetchExpenseAllTransactions(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)
    const bankLoanTransactions = await fetchBankTransactions(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)
    const debiCreditorsAllTransactions = await fetchDeibtorsCreditorsTransactions(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)

    const transactionHistories = await fetchTransactionHistories(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)
    const advancePaidTransactions = await fetchAdvancePaidTransaction(dateRecentFilter, startDateRecentFilter, endDateRecentFilter)
    
    console.log('advancePaidTransactions: ', advancePaidTransactions)
    // const gAllTransaction = allTransactions.map((_item) => ({
    //   ..._item,
    //   transactionName: 'Buy/Sell',
    //   generatedAmount: parseFloat(_item.quantity * _item.unitPrice).toFixed(2),
    //   generatedType: _item.type === 'buy' ? 'Buy' : 'Sale'
    // }))
    const gExpenseProductTransactions = expenseProductTransactions.map((_item) => ({
      ..._item,
      transactionName: 'Expense Product',
      generatedAmount: _item.amount,
      generatedType: expenseTypes.find((_item1) => _item1._id === _item.typeId)?.name || '-'
    }))
    const gExpenseAllTransactions = expenseAllTransactions.map((_item) => ({
      ..._item,
      transactionName: 'Expense',
      generatedAmount: _item.amount,
      generatedType: expenseTypes.find((_item1) => _item1._id === _item.typeId)?.name || '-'
    }))


    
    let _bcci = 0
    let _bcco = 0
    let _bdci = 0
    let _bdco = 0
    const gBankLoanTransactions = bankLoanTransactions?.map((_item) => {
      if (_item.bankLoan.type === 'Deposit(Debit)') {
        if (_item.type === 'Cash In') {
          _bdci += _item.amount
        } else {
          _bdco += _item.amount
        }
      } else {
        if (_item.type === 'Cash In') {
          _bcci += _item.amount
        } else {
          _bcco += _item.amount
        }
      }
      return {
        ..._item,
        transactionName: `Bank - ` + _item.bankLoan.type,
        generatedType: _item.type,
        generatedAmount: _item.amount,
      }
    }) || []
    setRecentBankCreditCashInTotal(_bcci)
    setRecentBankCreditCashOutTotal(_bcco)
    setRecentBankDebitCashInTotal(_bdci)
    setRecentBankDebitCashOutTotal(_bdco)


    let _dcit = 0
    let _dcot = 0
    let _ccit = 0
    let _ccot = 0
    const gDebiCreditorsAllTransactions = debiCreditorsAllTransactions.map((_item) => {
      console.log('_item.accountHolder.name: ', _item.accountHolder.name)
      if (_item.accountHolder.type === 'Debit') {
        if (_item.type === 'Cash In') {
          _dcit += _item.amount
        } else {
          _dcot += _item.amount
        }
      } else {
        if (_item.type === 'Cash In') {
          _ccit += _item.amount
        } else {
          _ccot += _item.amount
        }
      }

      return {
        ..._item,
        transactionName: `${_item.accountHolder.type === 'Debit' ? 'Debitor' : 'Creditor'}-${_item.accountHolder.name}`,
        generatedType: _item.type,
        generatedAmount: _item.amount,
      }
    })
    setRecentDebitorCashInTotal(_dcit)
    setRecentDebitorCashOutTotal(_dcot)
    setRecentCreditorCashInTotal(_ccit)
    setRecentCreditorCashOutTotal(_ccot)

    let _saleSubTotal = 0
    let _buySubTotal = 0
    const gTransactionHistories = transactionHistories.map((_item) => {
      if (_item.transaction.type === 'buy') {
        _buySubTotal += _item.paidAmount
      } else {
        _saleSubTotal += _item.paidAmount
      }

      return {
        ..._item,
        transactionName: 'Sub-Transaction',
        date: _item.createdAt,
        generatedAmount: _item.paidAmount,
        note: _item.note || '-'
      }
    })
    setRecentSaleSubTotal(_saleSubTotal)
    setRecentBuySubTotal(_buySubTotal)


    let _subTotalVendor = 0
    let _subTotalCustomer = 0
    const gAdvancePaidForTransactions = advancePaidTransactions.map((_it) => {
      if (_it?.entity?.type === 'vendor') {
        _subTotalVendor += _it.amount
      } else {
        _subTotalCustomer += _it.amount
      }
      
      return {
        ..._it,
        transactionName: `${_it?.entity?.type === 'vendor' ? 'Vendor' : 'Customer'}-Advance`,
        date: _it.createdAt,
        generatedAmount: _it.amount,
        note: _it.note || '-',
        generatedType: _it?.entity?.name || '-'
      }
    })
    setVendorAdvanceTotal(_subTotalVendor)
    setCustomerAdvanceTotal(_subTotalCustomer)

    // console.log('recent gAllTransaction : ', gAllTransaction)
    // console.log('recent gExpenseProductTransactions : ', gExpenseProductTransactions)
    // console.log('recent gExpenseAllTransactions : ', gExpenseAllTransactions)
    // console.log('recent bankLoanTransactions: ', bankLoanTransactions)
    // console.log('recent debiCreditorsAllTransactions: ', debiCreditorsAllTransactions)
    console.log('recent sub gAdvancePaidForTransactions: ', gAdvancePaidForTransactions)

    setRecentTransactions([
      ...gAdvancePaidForTransactions,
      ...gExpenseProductTransactions,
      ...gExpenseAllTransactions,
      ...gBankLoanTransactions,
      ...gDebiCreditorsAllTransactions,
      ...gTransactionHistories
    ].sort((_a, _b) => _a.createdAt > _b.createdAt ? -1 : _a.createdAt < _b.createdAt ? 1 : 0))

    setRecentLoading(false)
  }



  const handleTransactionDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/transaction/${selectedId}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      fetchTransactions()
      setDeleteConfirmModal(false)
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const getCashItem = (_cash) => {
    const _cashAmount = getFormattedReadableNumber(_cash)
    const _class = _cash > 0 ? 'green' : 'red'
    return (
      <span className={_class}>{_cashAmount}</span>
    )
  }
  
  
  return (
    <>
      {mode === 'dashboard' ? (
        <div className="dashboard-container">
          <Modal
            visible={deleteConfirmModal}
            title="Transaction Delete Confirmation"
            onCancel={() => {
              setSelectedId('')
              setDeleteConfirmModal(false)
            }}
            footer={[
              <button
                key="back"
                className="custom-button custom-button-sm"
                onClick={() => {
                  setDeleteConfirmModal(false)
                  setSelectedId('')
                }}
                style={{marginRight: '10px'}}
              >
                Cancel
              </button>,
              <button
                key="submit"
                type="primary"
                className="custom-button custom-button-sm"
                onClick={handleTransactionDelete}
              >
                Delete
              </button>
            ]}
          >
            <p>Are you sure to delete this transaction?</p>
          </Modal>
          {_user && _user.role && _user.role === 'super_admin' && (
            <div className="stat-container">
              <div className="stat-header">
                <h1>Statistics</h1>
              </div>
              <div className="stat-filter">
                <CustomDateFilter
                  dateFilter={dateStatFilter}
                  setDateFilter={setDateStatFilter}
                  startDate={startDateStatFilter}
                  setStartDate={setStartDateStatFilter}
                  endDate={endDateStatFilter}
                  setEndDate={setEndDateStatFilter}
                  handleSubmit={fetchTransactionStat}
                />
              </div>
              <div className="product-filter-container">
                <div className="product-filter">
                  <label>Rice Name</label>
                  <MultiSelect
                    value={multiSelectedProducts}
                    parentOnChange={setMultiSelectedProducts}
                    options={productOptions}
                  />
                </div>
                <div className="filtered-date">
                  <label>Filtered Date</label>
                  <h4>{filteredDate}</h4>
                </div>
              </div>
              {transactionStatLoading ? (<Spin style={{ marginTop: '50px'}} size="large" />) : (
                <div className="stat-data-container">
                  <div className="stat-data-item purchase-data-item">
                    <div className="stat-data-header">
                      <h5>Total Purchase</h5>
                    </div>
                    <div className="stat-data-body">
                      <span className="icon"><AiOutlineDownload /></span>
                      <span>{getFormattedReadableNumber(stats.totalPurchase)} ৳</span>
                    </div>
                  </div>
                  <div className="stat-data-item sale-data-item">
                    <div className="stat-data-header">
                      <h5>Total Sale</h5>
                    </div>
                    <div className="stat-data-body">
                      <span className="icon"><AiOutlineUpload /></span>
                      <span>{getFormattedReadableNumber(stats.totalSale)} ৳</span>
                    </div>
                  </div>
                  <div className="stat-data-item expense-data-item">
                    <div className="stat-data-header">
                      <h5>Total Product Expense</h5>
                    </div>
                    <div className="stat-data-body">
                      <span className="icon"><AiOutlineUpload /></span>
                      <span>{getFormattedReadableNumber(stats.totalExpenseProduct)} ৳</span>
                    </div>
                  </div>
                  <div className="stat-data-item expense-data-item">
                    <div className="stat-data-header">
                      <h5>Total Expense</h5>
                    </div>
                    <div className="stat-data-body">
                      <span className="icon"><AiOutlineUpload /></span>
                      <span>{getFormattedReadableNumber(stats.totalExpense)} ৳</span>
                    </div>
                  </div>
                  <div className="stat-data-item expense-data-item">
                    <div className="stat-data-header">
                      <h5>Total Cash In</h5>
                    </div>
                    <div className="stat-data-body">
                      <span className="icon"><AiOutlineDownload /></span>
                      <span>{getFormattedReadableNumber(stats.totalCashIn)} ৳</span>
                    </div>
                  </div>
                  <div className="stat-data-item expense-data-item">
                    <div className="stat-data-header">
                      <h5>Total Cash Out</h5>
                    </div>
                    <div className="stat-data-body">
                      <span className="icon"><AiOutlineUpload /></span>
                      <span>{getFormattedReadableNumber(stats.totalCashOut)} ৳</span>
                    </div>
                  </div>
                  <div className="stat-data-item profit-data-item">
                    <div className="stat-data-header">
                      <h5>Total Profit</h5>
                    </div>
                    <div className={`stat-data-body ${stats.totalProfit < 0 ? 'negative' : ''}`}>
                      <span className="icon"><AiOutlineDownload /></span>
                      <span>{getFormattedReadableNumber(stats.totalProfit)} ৳</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="transaction-container">
            <div className="transaction-header">
              <h2>Transactions</h2>
            </div>
            <div className="transaction-filter">
              <CustomDateFilter
                dateFilter={dateTransactionFilter}
                setDateFilter={setDateTransactionFilter}
                startDate={startDateTransactionFilter}
                setStartDate={setStartDateTransactionFilter}
                endDate={endDateTransactionFilter}
                setEndDate={setEndDateTransactionFilter}
                handleSubmit={fetchTransactions}
              />
            </div>
            <div
              className={`purchase-transaction-container ${buyPdfDownloadClicked ? 'minus-one-row' : ''}`}
              ref={buyTransactionRef}
              style={buyPdfDownloadClicked ? {
                width: '112rem',
                padding: '4rem'
              } : {}}
            >
              <div className="purchase-transaction-header">
                <h3>Purchase Transactions</h3>
                <button
                  className="custom-button custom-button-sm"
                  onClick={(event) => {
                    event.target.style.visibility = 'hidden'
                    setBuyPdfDownloadClicked(true)
                    setTimeout(() => { 
                      const fileName = `purchase-transactions-${moment().format('YYYY-MM-DD')}.pdf`
                      
                      generatePDF(fileName, buyTransactionRef, setBuyPdfDownloadClicked)
                    }, 0)
                    
                  }}
                >
                  Export PDF
                </button>
              </div>
              <div className="total-info">
                <div className="total-info-item">
                  <h4>Total Paid: <strong>{getFormattedReadableNumber(buyTotalPaid)} ৳</strong></h4>
                  <h4>Total Due: <strong>{getFormattedReadableNumber(buyTotalDue)} ৳</strong></h4>
                </div>
              </div>
              {transactionLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
                <div className={`purchase-transaction-body ${_user.role === 'operator' ? 'operator' : 'admin'}`}>
                  <div className="purchase-transaction-table-header">
                    <div className="purchase-row header">
                      <span>Date</span>
                      <span>Purchase Id</span>
                      <span>Rice Name</span>
                      <span>Vendor</span>
                      <span>Quantity (kg)</span>
                      <span>Price (৳)</span>
                      <span>Status</span>
                      <span>Paid (৳)</span>
                      <span>Due (৳)</span>
                      <span>Note</span>
                      <span>Transactor</span>
                      {!buyPdfDownloadClicked && <span>Action</span>}
                    </div>
                  </div>
                  <div className="purchase-transaction-table-body">
                    {buyTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction is available</p>)
                      : buyTransactions.map((purchaseItem, _index) => (
                      <div key={_index} className='purchase-row'>
                        <span>{ moment(purchaseItem.date).format('YYYY-MM-DD') }</span>
                        <span>{ purchaseItem.buyId }</span>
                        <span>{ purchaseItem.productName }</span>
                        <span
                            className="entity-cell"
                            onClick={() => {
                              navigate(`/entityinfo?tid=${purchaseItem._id}`)
                            }}
                        >
                          {purchaseItem.entityName}
                        </span>
                        <span>{ purchaseItem.quantity } </span>
                        <span>{ getFormattedReadableNumber(purchaseItem.totalPrice) } ৳</span>
                          <span
                            className="entity-cell"
                            onClick={() => {
                              if (_user.role === 'operator') setStatusEdit(true)
                              setSelectedTransaction(purchaseItem)
                              setMode('update')
                            }}
                          >
                            {purchaseItem.status}
                          </span>
                        <span className="positive">{getFormattedReadableNumber(purchaseItem.paid)}</span>
                        <span className="negative">{getFormattedReadableNumber(purchaseItem.due)}</span>
                        <span>{ purchaseItem.note }</span>
                        <span>{ purchaseItem.userName } </span>
                          {!buyPdfDownloadClicked && (
                            <span>
                              {_user.role === 'operator' ? getEditRequestOptionForOperator(purchaseItem) : (

                                <div className="transaction-actions">
                                  <span
                                    onClick={() => {
                                      setSelectedTransaction(purchaseItem)
                                      setMode('update')
                                    }}
                                  >
                                    <EditOutlinedIcon />
                                  </span>
                                  <span
                                    className='delete-icon'
                                    style={{marginLeft: '1rem'}}
                                    onClick={() => {
                                      setDeleteConfirmModal(true)
                                      setSelectedId(purchaseItem._id)
                                    }}
                                  >
                                    <DeleteOutlinedIcon />
                                  </span>
                                </div>
                              )}
                            </span>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`sale-transaction-container ${sellPdfDownloadClicked ? 'minus-one-row' : ''}`}
              ref={saleTransactionRef}
              style={sellPdfDownloadClicked ? {
                width: '112rem',
                padding: '4rem'
              } : {}}
            >
              <div className="sale-transaction-header">
                <h3>Sale Transactions</h3>
                <button
                  className="custom-button custom-button-sm"
                  onClick={(event) => {
                    event.target.style.visibility = 'hidden'
                    setSellPdfDownloadClicked(true)
                    setTimeout(() => { 
                      const fileName = `sale-transactions-${moment().format('YYYY-MM-DD')}.pdf`
                      
                      generatePDF(fileName, saleTransactionRef, setSellPdfDownloadClicked)
                      
                    }, 0)
                    
                  }}
                >
                  Export PDF
                </button>
              </div>
              <div className="total-info">
                <div className="total-info-item">
                  <h4>Total Paid: <strong>{getFormattedReadableNumber(saleTotalPaid)}</strong></h4>
                  <h4>Total Due: <strong>{getFormattedReadableNumber(saleTotalDue)}</strong></h4>
                </div>
              </div>
              {transactionLoading ? (<Spin size="large" />) : (
                <div className={`sale-transaction-body ${_user.role === 'operator' ? 'operator' : 'admin'}`}>
                  <div className="sale-transaction-table-header">
                    <div className="sale-row header">
                      <span>Date</span>
                      <span>Sale Id</span>
                      <span>Rice Name</span>
                      <span>Customer</span>
                      <span>Quantity (kg)</span>
                      <span>Price (৳)</span>
                      <span>Paid (৳)</span>
                      <span>Due (৳)</span>
                      <span>Note</span>
                      <span>Transactor</span>
                      {!sellPdfDownloadClicked && <span>Action</span>}
                    </div>
                  </div>
                  <div className="sale-transaction-table-body">
                    {saleTransactions.length === 0 ? (<p style={{ marginTop: '40px' }}>No Transaction is available</p>)
                      : saleTransactions.map((saleItem, _index) => (
                        <div key={_index} className='sale-row'>
                          <span>{moment(saleItem.date).format('YYYY-MM-DD')}</span>
                          <span>{saleItem.sellId}</span>
                          <span>{saleItem.productName}</span>
                          <span
                            className="entity-cell"
                            onClick={() => {
                              navigate(`/entityinfo?tid=${saleItem._id}`)
                            }}
                          >
                            {saleItem.entityName}
                          </span>
                          <span>{saleItem.quantity}</span>
                          <span>{getFormattedReadableNumber(saleItem.totalPrice)} ৳</span>
                          <span className="positive">{getFormattedReadableNumber(saleItem.paid)}</span>
                          <span className="negative">{getFormattedReadableNumber(saleItem.due)}</span>
                          <span>{saleItem.note}</span>
                          <span>{saleItem.userName}</span>
                          {!sellPdfDownloadClicked && <span>
                            {_user.role === 'operator' ? getEditRequestOptionForOperator(saleItem) : (
                              <div className="transaction-actions">
                                <span
                                  onClick={() => {
                                    setSelectedTransaction(saleItem)
                                    setMode('update')
                                  }}
                                >
                                  <EditOutlinedIcon />
                                </span>
                                <span
                                  className='delete-icon'
                                  style={{marginLeft: '1rem'}}
                                  onClick={() => {
                                    setDeleteConfirmModal(true)
                                    setSelectedId(saleItem._id)
                                  }}
                                >
                                  <DeleteOutlinedIcon />
                                </span>
                              </div>
                            )}
                          </span>}
                        </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
    {/* ----------------------------- */}

          

    

    
    {/* ----------------------------------- */}
          <div className="cash-history-container">
            <div className="cash-history-header">
              <h2>Cash Histories</h2>
            </div>
            <div className="cash-history-filter">
              <CustomDateFilter
                dateFilter={dateCashHistoryFilter}
                setDateFilter={setDateCashHistoryFilter}
                startDate={startDateCashHistoryFilter}
                setStartDate={setStartDateCashHistoryFilter}
                endDate={endDateCashHistoryFilter}
                setEndDate={setEndDateCashHistoryFilter}
                handleSubmit={fetchCashHistories}
              />
            </div>

            {cashHistoryLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
              <div className={`cash-history-body ${_user.role === 'operator' ? 'operator' : ''}`}>
                <div className="cash-history-table-header">
                  <div className="cash-history-row header">
                    <span>Date</span>
                    <span>Previous Day Cash</span>
                    <span>Total Cash In</span>
                    <span>Total Cash Out</span>
                    <span>Total Cash</span>
                  </div>
                </div>
                <div className="cash-history-table-body">
                  {cashHistories.length === 0 ? (<p style={{ marginTop: '20px' }}>No History is available</p>)
                    : cashHistories.map((historyItem, _index) => (
                    <div key={_index} className='cash-history-row'>
                      <span>{moment(historyItem.date).format('YYYY-MM-DD')}</span>
                      {getCashItem(historyItem.previousDayCash || 0)}
                      <span>{getFormattedReadableNumber(historyItem.cashIngenerated)}</span>
                      <span>{getFormattedReadableNumber(historyItem.cashOut)}</span>
                      {getCashItem(historyItem.totalCash)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
    {/* ----------------------------------- */}





    {/* ----------------------------------- */}
          <div className="request-container">
            <div className="request-header">
              <h2>Transaction Edit Requests</h2>
            </div>
            <div className="request-filter">
              <CustomDateFilter
                dateFilter={dateRequestFilter}
                setDateFilter={setDateRequestFilter}
                startDate={startDateRequestFilter}
                setStartDate={setStartDateRequestFilter}
                endDate={endDateRequestFilter}
                setEndDate={setEndDateRequestFilter}
                handleSubmit={fetchRequests}
              />
            </div>

            <div className="purchase-request-container">
              <div className="purchase-request-header">
                <h2>Purchase Requests</h2>
              </div>
              {requestLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
                <div className={`purchase-request-body ${_user.role === 'operator' ? 'operator' : ''}`}>
                  <div className="purchase-request-table-header">
                    <div className="purchase-row header">
                      <span>Date</span>
                      <span>Purchase Id</span>
                      <span>Rice Name</span>
                      <span>Vendor</span>
                      <span>Quantity (kg)</span>
                      <span>Price (৳)</span>
                      <span>Paid (৳)</span>
                      <span>Due (৳)</span>
                      <span>Transactor</span>
                      <span>Action</span>
                    </div>
                  </div>
                  <div className="purchase-request-table-body">
                    {buyRequests.length === 0 ? (<p style={{ marginTop: '20px' }}>No Request is available</p>)
                      : buyRequests.map((purchaseItem, _index) => (
                      <div key={_index} className='purchase-row'>
                        <span>{ moment(purchaseItem.date).format('YYYY-MM-DD') }</span>
                        <span>{ purchaseItem.buyId }</span>
                        <span>{ purchaseItem.productName }</span>
                        <span>{ purchaseItem.entityName }</span>
                        <span>{ purchaseItem.quantity } </span>
                        <span>{getFormattedReadableNumber(purchaseItem.totalPrice)} ৳</span>
                        <span className="positive">{getFormattedReadableNumber(purchaseItem.paid)} ৳</span>
                        <span className="negative">{getFormattedReadableNumber(purchaseItem.due)} ৳</span>
                        <span>{ purchaseItem.userName } </span>
                        <span>
                          {_user.role === 'operator' ? getEditOption(purchaseItem) : getAproveOptionForAdmin(purchaseItem)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="sale-request-container">
              <div className="sale-request-header">
                <h2>Sale Requests</h2>
              </div>
              {requestLoading ? (<Spin size="large" />) : (
                <div className={`sale-request-body ${_user.role === 'operator' ? 'operator' : ''}`}>
                  <div className="sale-reqeust-table-header">
                    <div className="sale-row header">
                      <span>Date</span>
                      <span>Sale Id</span>
                      <span>Rice Name</span>
                      <span>Customer</span>
                      <span>Quantity (kg)</span>
                      <span>Price (৳)</span>
                      <span>Paid (৳)</span>
                      <span>Due (৳)</span>
                      <span>Transactor</span>
                      <span>Action</span>
                    </div>
                  </div>
                  <div className="sale-reqeust-table-body">
                    {saleRequests.length === 0 ? (<p style={{ marginTop: '40px' }}>No Request is available</p>)
                      : saleRequests.map((saleItem, _index) => (
                      <div key={_index} className='sale-row'>
                        <span>{moment(saleItem.date).format('YYYY-MM-DD')}</span>
                        <span>{saleItem.sellId}</span>
                        <span>{saleItem.productName}</span>
                        <span>{saleItem.entityName}</span>
                        <span>{saleItem.quantity}</span>
                        <span>{getFormattedReadableNumber(saleItem.totalPrice)} ৳</span>
                        <span className="positive">{getFormattedReadableNumber(saleItem.paid)} ৳</span>
                        <span className="negative">{getFormattedReadableNumber(saleItem.due)} ৳</span>
                        <span>{ saleItem.userName }</span>
                        <span>
                          {_user.role === 'operator' ? getEditOption(saleItem) : getAproveOptionForAdmin(saleItem)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>


          <div className="debitors-container common-container-body">
            <div className="debitors-header">
              <h2>Debitors/Creditors</h2>
            </div>
            <div className="debitors-filter">
              <CustomDateFilter
                dateFilter={dateDebitorsFilter}
                setDateFilter={setDateDebitorsFilter}
                startDate={startDateDebitorsFilter}
                setStartDate={setStartDateDebitorsFilter}
                endDate={endDateDebitorsFilter}
                setEndDate={setEndDateDebitorsFilter}
                handleSubmit={fetchDebitors}
              />
            </div>
            {transactionDebitorsLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
              <div className="debitors-container-body">
                <div className="debitors-table-header">
                  <div className="debitors-row common-row header">
                    <span>Id</span>
                    <span>Date</span>
                    <span>Name</span>
                    <span>Phone</span>
                    <span>Type</span>
                    <span>Balance</span>
                    <span>Note</span>
                  </div>
                </div>
                <div className="debitors-table-container">
                  {holders.length === 0
                    ? (<p style={{ marginTop: '20px' }}>No Debitors/Creditors is available</p>)
                    : holders.map((_item, _index) => (
                      <div key={_index} className='debitors-row common-row'>
                        <span>{_item.readableId}</span>
                        <span>{_item.date ? moment(_item.date).format('YYYY-MM-DD') : '-'}</span>
                        <span>{_item.name}</span>
                        <span>{_item.phone}</span>
                        <span>{_item.type}</span>
                        <span style={_item.type === 'Credit' ? {color: 'red'} : {}}>{getFormattedReadableNumber(_item.amount)}</span>
                        <span>{_item.note}</span>
                      </div>
                  ))}
                </div>
              </div>
            )}
          </div>



          <div className="recent-container common-container-body">
            <div className="recent-header">
              <h2>Recent Transactions</h2>
            </div>
            <div className="recent-filter">
              <CustomDateFilter
                dateFilter={dateRecentFilter}
                setDateFilter={setDateRecentFilter}
                startDate={startDateRecentFilter}
                setStartDate={setStartDateRecentFilter}
                endDate={endDateRecentFilter}
                setEndDate={setEndDateRecentFilter}
                handleSubmit={fetchRecents}
              />
            </div>
            {recentLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
              <div
                className="recent-container-body"
                ref={recentTransactionRef}
                style={recentDownloadClicked ? {
                  width: '112rem',
                  padding: '4rem'
                } : {}}
              >
                <div className="recent-container-header">
                  <h3>Recent Transactions</h3>
                  <button
                    className="custom-button custom-button-sm"
                    onClick={(event) => {
                      event.target.style.visibility = 'hidden'
                      setRecentDownloadClicked(true)
                      setTimeout(() => { 
                        const fileName = `recent-transactions-${moment().format('YYYY-MM-DD')}.pdf`
                        
                        generatePDF(fileName, recentTransactionRef, setRecentDownloadClicked)
                      }, 0)
                      
                    }}
                  >
                    Export PDF
                  </button>
                </div>
                
                
                <div className="total-info recent-total-info">
                  <div className="total-info-item">
                    <h4>Debitors Total (Cash In): <strong>{getFormattedReadableNumber(recentDebitorCashInTotal)}</strong></h4>
                    <h4>Debitors Total (Cash Out): <strong>{getFormattedReadableNumber(recentDebitorCashOutTotal)}</strong></h4>
                    <h4>Creditors Total (Cash In): <strong>{getFormattedReadableNumber(recentCreditorCashInTotal)}</strong></h4>
                    <h4>Creditors Total (Cash Out): <strong>{getFormattedReadableNumber(recentCreditorCashOutTotal)}</strong></h4>

                    <h4>Bank Debit Total (Cash In): <strong>{getFormattedReadableNumber(recentBankDebitCashInTotal)}</strong></h4>
                    <h4>Bank Debit Total (Cash Out): <strong>{getFormattedReadableNumber(recentBankDebitCashOutTotal)}</strong></h4>
                    <h4>Bank Credit Total (Cash In): <strong>{getFormattedReadableNumber(recentBankCreditCashInTotal)}</strong></h4>
                    <h4>Bank Credit Total (Cash Out): <strong>{getFormattedReadableNumber(recentBankCreditCashOutTotal)}</strong></h4>
                    
                    <h4>Sale Subtotal: <strong>{getFormattedReadableNumber(recentSaleSubTotal)}</strong></h4>
                    <h4>Buy Subtotal: <strong>{getFormattedReadableNumber(recentBuySubTotal)}</strong></h4>

                    <h4>Vendor Advance Total: <strong>{getFormattedReadableNumber(vendorAdvanceTotal)}</strong></h4>
                    <h4>Customer Advance Total: <strong>{getFormattedReadableNumber(customerAdvanceTotal)}</strong></h4>
                  </div>
                </div>
                <div className="recent-table-container">
                  <div className="recent-table-header">
                    <div className="recent-row common-row header">
                      <span>Date</span>
                      <span>Type</span>
                      <span>Subtype</span>
                      <span>Amount</span>
                      <span>Note</span>
                    </div>
                  </div>
                  <div className="recent-table-body">
                    {recentTransactions.length === 0
                      ? (<p style={{ marginTop: '20px' }}>No Recent Transaction is available</p>)
                      : recentTransactions.map((_item, _index) => (
                        <div key={_index} className='recent-row common-row'>
                          <span>{_item.date ? moment(_item.date).format('YYYY-MM-DD') : '-'}</span>
                          <span>{_item.transactionName}</span>
                          <span>{_item.generatedType}</span>
                          <span>{getFormattedReadableNumber(_item.generatedAmount)}</span>
                          <span>{_item.note}</span>
                        </div>
                    ))}
                  </div>
                </div>
                
              </div>
            )}
          </div>
        </div>
      ) :
        (
            <>
              <div className="back-container">
                <button
                  className='custom-button custom-button-sm'
                  onClick={() => {
                    setStatusEdit(false)
                    fetchTransactions()
                    setMode('dashboard')
                  }}
                >
                  Back
                </button>
              </div>
            <BuySell
              transaction={selectedTransaction}
              setMode={setMode}
              fetchTransactions={fetchTransactions}
              statusEdit={statusEdit}
            />
          </>
      )}
    </>
  );
}

export default Home;
