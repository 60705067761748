// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../image/home-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".not-exists-container {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: #eca643;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-attachment: scroll;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.not-exists-inner {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(236, 166, 67, 0.732);\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  text-align: center;\n  color: #fff;\n}\n.not-exists-inner h1 {\n  font-size: 6rem;\n  color: #fff;\n  font-weight: 700;\n}\n\n.not-exists-inner h2 {\n  font-size: 4rem;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/style/not_found.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,yDAA6C;EAC7C,6BAA6B;EAC7B,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,2CAA2C;EAC3C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".not-exists-container {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: #eca643;\n  background-image: url('../image/home-bg.png');\n  background-attachment: scroll;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.not-exists-inner {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(236, 166, 67, 0.732);\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  text-align: center;\n  color: #fff;\n}\n.not-exists-inner h1 {\n  font-size: 6rem;\n  color: #fff;\n  font-weight: 700;\n}\n\n.not-exists-inner h2 {\n  font-size: 4rem;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
