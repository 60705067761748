import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'
import { message, Spin, Modal, DatePicker } from 'antd'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import axios from '../config/axios'
import '../style/common.css'
import '../style/buy.css'
import '../style/expense.css'
import '../style/user.css'
import '../style/entity.css'
import {
  getCurrentUser,
  redirectLoginIfLoggedOut,
  getFloatNumber,
  getFormattedReadableNumber
} from '../utils/helper'
import { fetchAllEntities } from "../utils/apis";

const entityTypeOptions = [
  {
    label: 'Customer',
    value: 'customer'
  },
  {
    label: 'Vendor',
    value: 'vendor'
  }
]

const Entity = () => {

  const navigate = useNavigate()
  const _user = getCurrentUser()

  const [mode, setMode] = useState('view')
  const [entityLoading, setEntityLoading] = useState(true)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [deleteEntityLoading, setDeleteEntityLoading] = useState(true)
  const [entitySubmitLoading, setEntitySubmitLoading] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [allRawEntites, setAllRawEntities] = useState([])
  const [customers, setCustomers] = useState([])
  const [vendors, setVendors] = useState([])

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [entityYear, setEntityYear] = useState('')
  const [address, setAddress] = useState('')
  const [selectedType, setSelectedType] = useState(entityTypeOptions[0])
  const [yearOptions, setYearOptions] = useState([])
  const [selectedYear, setSelectedYear] = useState({})
  const [due, setDue] = useState('')

  const [searchCustomerName, setSearchCustomerName] = useState('')
  const [searchVendorName, setSearchVendorName] = useState('')

  const [advance, setAdvance] = useState('')

  const [advancePayModal, setAdvancePayModal] = useState(false)
  const [advanceNote, setAdvanceNote] = useState('')
  const [dateForAdvancePay, setDateForAdvancePay] = useState(moment().format('YYYY-MM-DD'))

  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/home')
    }
    // if (_user && _user.role !== 'super_admin') {
    //   setPageTitle('Home')
    //   navigate('/home')
    // }
    init()
  }, [])
  
  const init = () => {
    loadAllEntities()
  }

  useEffect(() => {
    // console.log('customers: ', customers)
  }, [customers])
  useEffect(() => {
    // console.log('vendors: ', vendors)
  }, [vendors])
  
  const loadAllEntities = async () => {
    setEntityLoading(true)
    const _allEntites = await fetchAllEntities()
    setAllRawEntities(_allEntites)
    let _allYearOptions = [...new Set(_allEntites.filter((_item) => _item.year).map((_item) => _item.year))]
      .sort((_a, _b) => _a > _b ? -1 : _a < _b ? 1 : 0)
      .map((_item) => ({
        label: _item,
        value: _item
      }))
    setYearOptions(_allYearOptions)
    if (_allYearOptions.length > 0) {
      // console.log('msg from _allYearOptions: ', _allEntites.map((_item) => _item.year))
      setSelectedYear(_allYearOptions[0])
      renderEntitiesBySelectedYear(_allYearOptions[0].value, _allEntites)
    }
    
    setEntityLoading(false)
  }

  const renderEntitiesBySelectedYear = (_year, _allEntites) => {
    const _tempEntities = _allEntites ? _allEntites : allRawEntites
    setCustomers(_tempEntities.filter((_item) => {
      const re = new RegExp(searchCustomerName, "i");
      const _matched = _item.name.match(re)
      return _item.type === 'customer' && _item.year === _year && _matched
    }))
    setVendors(_tempEntities.filter((_item) => {
      const re = new RegExp(searchVendorName, "i");
      const _matched = _item.name.match(re)
      return _item.type === 'vendor' && _item.year === _year && _matched
    }))
  }
  
  const handleEntitySubmit = async () => {

    const body = {
      name,
      phone,
      year: entityYear,
      address,
      type: selectedType.value,
      userId: _user._id
    }

    if (!body.name) {
      message.info(`${selectedType.label} Name is required`)
      return
    }
    if (!body.phone) {
      message.info(`${selectedType.label} Phone Number is required`)
      return
    }
    const yearLen = parseInt(body.year).toString().length
    const yearNotNumber = isNaN(parseInt(body.year))

    if (yearNotNumber || yearLen !== 4) {
      message.info('Please Enter valid year!')
      return
    }
    console.log('due: ', due)
    if (advance) { //&& selectedType.value === 'vendor'
      if (mode === 'view') {
        body.due = -advance
      } else {
        body.due = due - advance
      }
    }

    console.log('body: ', body)
    
    setEntitySubmitLoading(true)
    try {
      const { data } = mode === 'view'
        ? await axios.post(
          '/entity',
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        ) : 
          await axios.put(
            `/entity/${selectedId}`,
            body, {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              }
            }
          )
      
      message.success(data.message)
      setEntitySubmitLoading(false)
      setName('')
      setPhone('')
      setEntityYear('')
      setAddress('')
      setAdvance('')
      setDue('')
      setSelectedType(entityTypeOptions[0])
      loadAllEntities()
      setMode('view')
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setEntitySubmitLoading(false)
    }
  }

  const handleAdvancePaySubmit = async () => {
    const _selectedVendor = allRawEntites.find((_it) => _it._id === selectedId)
    
    const body = {
      due: _selectedVendor.due - advance
    }

    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      }
    }
    
    try {
      const { data } = await axios.put(`/entity/${selectedId}`, body, config)

      const url = `/entityadvance`
      const body2 = {
        amount: advance,
        note: advanceNote,
        userId: _user._id,
        entityId: selectedId,
        date: dateForAdvancePay
      }

      await axios.post(url, body2, config)
      
      message.success(data.message)
      
      loadAllEntities()
      setAdvance('')
      setSelectedId('')
      setAdvancePayModal(false)
    } catch (_error) {
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setEntitySubmitLoading(false)
    }
  }

  const handleEntityDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/entity/${selectedId}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      loadAllEntities()
      setDeleteEntityLoading(false)
      setDeleteConfirmModal(false)
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setDeleteEntityLoading(false)
    }
  }

  const handleCustomerSearch = (_key) => {
    setSearchCustomerName(_key)
    setCustomers(allRawEntites.filter((_item) => {
      const re = new RegExp(_key, "i");
      const _matched = _item.name.match(re)
      return _item.type === 'customer' && _item.year === selectedYear.value && _matched
    }))
    
  }

  const handleVendorSearch = (_key) => {
    setSearchVendorName(_key)
    setVendors(allRawEntites.filter((_item) => {
      const re = new RegExp(_key, "i");
      const _matched = _item.name.match(re)
      return _item.type === 'vendor' && _item.year === selectedYear.value && _matched
    }))
  }

  return (
    <div className="entity-container">
      <Modal
        visible={deleteConfirmModal}
        title="Entity Delete Confirmation"
        onCancel={() => {
          setSelectedId('')
          setDeleteConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setDeleteConfirmModal(false)
              setSelectedId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            loading={deleteEntityLoading}
            onClick={handleEntityDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Entity?</p>
      </Modal>
      <Modal
        visible={advancePayModal}
        title="Advance Pay"
        className="advance-pay-modal"
        onCancel={() => {
          setSelectedId('')
          setAdvancePayModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setAdvancePayModal(false)
              setSelectedId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleAdvancePaySubmit}
          >
            Submit
          </button>
        ]}
      >
        <div className="input-item">
          <label>Advance Pay Amount</label>
          <input
            type="text"
            placeholder="Enter amount"
            value={advance}
            onChange={(e) => setAdvance(getFloatNumber(e.target.value))}
          />
        </div>
        <div className="input-item">
          <label>Date</label>
          <DatePicker
            defaultValue={() => moment(new Date(), 'YYYY-MM-DD')}
            onChange={(value, dateString) => {
              console.log(dateString)
              setDateForAdvancePay(dateString)
            }}
          />
        </div>
        <div className="input-item note-item">
          <label htmlFor="note">Note</label>
          <textarea
            name="note"
            id="note"
            rows="3"
            value={advanceNote}
            onChange={(e) => setAdvanceNote(e.target.value)}
          />
        </div>
      </Modal>
      {mode === 'view' && (
        <div className="entity-table-container-outer">
          <div className="entity-table-container customer-table-container">
            <div className="entity-outer-header">
              <h2>Entities</h2>
              <div className="custom-filter">
                <Select
                  options={yearOptions}
                  value={selectedYear}
                  onChange={(selected) => {
                    console.log(selected)
                    renderEntitiesBySelectedYear(selected.value)
                    setSelectedYear(selected)
                  }}
                />
              </div>
            </div>
            <div className="entity-header">
              <h2>Customers</h2>
              <div className="custom-filter input-item">
                <input
                  type="text"
                  id="search-name"
                  placeholder={`Search Name`}
                  value={searchCustomerName}
                  onChange={(e) => handleCustomerSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="entity-table-container-inner">
              <div className="entity-table-header">
                <div className={`entity-row vendor-row header ${_user.role === 'super_admin' ? 'entity-row-edit-delete' : ''}`}>
                  <span>Name</span>
                  <span>Phone</span>
                  <span>Amount</span>
                  <span>Address</span>
                  <span></span>
                  <span>Actions</span>
                </div>
              </div>
              <div className="entity-table-body">
                {customers.length === 0 ? (<p style={{ marginTop: '20px' }}>No Entity is available</p>)
                  : customers.map((entityItem, _index) => (
                    <div
                      key={_index}
                      className={`entity-row vendor-row ${_user.role === 'super_admin' ? 'entity-row-edit-delete' : ''}`}
                    >
                      <span
                        className="entity-cell"
                        onClick={() => {
                          navigate(`/entityinfo?eid=${entityItem._id}`)
                        }}
                      >
                        {entityItem.name}
                      </span>
                      <span>{ entityItem.phone }</span>
                      {parseFloat(entityItem.due).toFixed(2) <= 0 ? (
                        <span style={{color: 'green'}}>{getFormattedReadableNumber(parseFloat(entityItem.due).toFixed(2) * -1)}</span>
                      ) : (
                        <span style={{color: 'red'}}>{getFormattedReadableNumber(parseFloat(entityItem.due).toFixed(2))}</span>
                      )}
                      <span>{entityItem.address}</span>
                      <span>
                        <button
                          className="custom-button custom-button-sm"
                          onClick={() => { 
                            setSelectedId(entityItem._id)
                            setAdvancePayModal(true)
                          }}
                          disabled={parseFloat(entityItem.due).toFixed(2) > 0.00}
                        >
                          Pay
                        </button>
                      </span>
                      <span className="edit-delete">
                        <span
                          onClick={() => {
                            setSelectedId(entityItem._id)
                            setName(entityItem.name)
                            setPhone(entityItem.phone)
                            setAddress(entityItem.address)
                            setSelectedType(entityTypeOptions.find((_item) => _item.value === entityItem.type))
                            setMode('edit')
                          }}
                        >
                          <EditOutlinedIcon />
                        </span>
                        {_user.role === 'super_admin' && (
                          <span
                            onClick={() => {
                              setSelectedId(entityItem._id)
                              setDeleteConfirmModal(true)
                            }}
                          >
                            <DeleteOutlinedIcon />
                          </span>
                        )}
                      </span>
                    </div>
                ))}
              </div>
            </div>
          </div>

          <div className="entity-table-container vendor-table-container">
            <div className="entity-header">
              <h2>Vendors</h2>
              <div className="custom-filter input-item">
                <input
                  type="text"
                  id="search-name"
                  placeholder={`Search Name`}
                  value={searchVendorName}
                  onChange={(e) => handleVendorSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="entity-table-container-inner">
              <div className="entity-table-header">
                <div className={`entity-row vendor-row header ${_user.role === 'super_admin' ? 'entity-row-edit-delete' : ''}`}>
                  <span>Name</span>
                  <span>Phone</span>
                  <span>Amount</span>
                  <span>Address</span>
                  <span></span>
                  <span>Actions</span>
                </div>
              </div>
              <div className="entity-table-body">
                {vendors.length === 0 ? (<p style={{ marginTop: '20px' }}>No Entity is available</p>)
                  : vendors.map((entityItem, _index) => (
                  <div
                    key={_index}
                    className={`entity-row vendor-row ${_user.role === 'super_admin' ? 'entity-row-edit-delete' : ''}`}
                  >
                    <span
                      className="entity-cell"
                      onClick={() => {
                        navigate(`/entityinfo?eid=${entityItem._id}`)
                      }}
                    >
                      {entityItem.name}
                    </span>
                    <span>{ entityItem.phone }</span>
                    {parseFloat(entityItem.due).toFixed(2) <= 0 ? (
                      <span style={{color: 'green'}}>{getFormattedReadableNumber(parseFloat(entityItem.due).toFixed(2) * -1)}</span>
                    ) : (
                      <span style={{color: 'red'}}>{getFormattedReadableNumber(parseFloat(entityItem.due).toFixed(2))}</span>
                    )}
                    <span>{ entityItem.address }</span>
                    <span>
                      <button
                        className="custom-button custom-button-sm"
                        onClick={() => { 
                          setSelectedId(entityItem._id)
                          setAdvancePayModal(true)
                        }}
                        disabled={parseFloat(entityItem.due).toFixed(2) > 0.00}
                      >
                        Pay
                      </button>
                    </span>
                    <span className="edit-delete">
                      <span
                        onClick={() => {
                          setSelectedId(entityItem._id)
                          setName(entityItem.name)
                          setPhone(entityItem.phone)
                          setDue(entityItem.due)
                          setAddress(entityItem.address)
                          setAdvance('')
                          setSelectedType(entityTypeOptions.find((_item) => _item.value === entityItem.type))
                          setMode('edit')
                        }}
                      >
                        <EditOutlinedIcon />
                      </span>
                      {_user.role === 'super_admin' && (
                        <span
                          onClick={() => {
                            setSelectedId(entityItem._id)
                            setDeleteConfirmModal(true)
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </span>
                        )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="add-entity-container">
        <div className="add-entity-header">
          <h2>{mode === 'view' ? 'Add' : 'Edit'} Entity</h2>
          {mode === 'edit' && (
            <button
              className='custom-button custom-button-sm'
              onClick={() => {
                setName('')
                setPhone('')
                setAdvance('')
                setDue('')
                setSelectedType(entityTypeOptions[0])
                setMode('view')
              }}
            >
              Back
            </button>
          )}
        </div>
        <div className="add-entity-body">
          <div className="input-item">
            <label>Entity Type</label>
            <Select
              options={entityTypeOptions}
              value={selectedType}
              onChange={(selected) => {
                console.log(selected)
                setSelectedType(selected)
              }}
            />
          </div>
          <div className="input-item">
            <label htmlFor="entity-name">{ selectedType.label } Name</label>
            <input
              type="text"
              id="entity-name"
              placeholder={`Enter ${ selectedType.label } Name`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Enter Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="input-item">
            <label>Year</label>
            <input
              type="text"
              placeholder="Enter year"
              value={entityYear}
              onChange={(e) => setEntityYear(e.target.value)}
            />
          </div>
          <div className="input-item note-item">
            <label htmlFor="address">Address</label>
            <textarea
              name="address"
              id="address"
              rows="3"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div> 
        <div className="add-entity-footer">
          {entitySubmitLoading ? <Spin size="large" /> : (
            <button
              className="custom-button"
              onClick={handleEntitySubmit}
            >
              {mode === 'view' ? 'Add' : 'Update'}
            </button>
          )}
        </div>
      </div>
      
      
    </div>
  )
}

export default Entity;