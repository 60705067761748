import { useState, useEffect, useRef } from 'react'
import { Box, Grid } from "@material-ui/core";
import * as FaIcons from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import axios from '../config/axios'
import "./Topbar.css";
import logo from "../image/bholatreding.png";
import { getCurrentUser, capitalizeFirstLetter } from '../utils/helper';
const Topbar = ({
  isNavOpen,
  setIsNavOpen,
  title,
}) => {

  const navigate = useNavigate()

  const [profileDropdown, setProfileDropdown] = useState(false)
  const [userName, setUserName] = useState('')
  
  const innerWidthRef = useRef()
  innerWidthRef.current = window.innerWidth

  const _user = getCurrentUser()

  useEffect(() => {
    const isOpen = document.body.classList.contains('nav-open')
    // if (window.innerWidth < 800 && isOpen) {
    //   setIsNavOpen(false) 
    //   document.body.classList.remove('nav-open')
    // }
    if (isOpen) {
      setIsNavOpen(true)
    } else {
      setIsNavOpen(false)
    }
  })
  
  useEffect(() => {
    window.addEventListener('click', handleProfileDropdownClick)
    window.addEventListener('resize', handleNavigationStuff)
    // console.log('user: ', _user)
    setUserName(_user?.name || 'User')
    return () => {
      window.removeEventListener('click', handleProfileDropdownClick)
      window.removeEventListener('resize', handleNavigationStuff)
    }
  }, [])

  useEffect(() => {
    console.log('capitalized: ', capitalizeFirstLetter(title))
    document.title = 'Bhola Trading | ' + capitalizeFirstLetter(title)
  }, [title])

  const handleLogout = async () => {
    try {
      const { data } = await axios.get(
        'auth/logout', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      // console.log('login response: ', res)
      if (data && data.message && data.message === 'Logout Successfull!') {
        localStorage.removeItem('user')
        navigate('/')
      }
    } catch (_error) {
      console.log('error: ', _error)
    }
  }

  const handleProfileDropdownClick = (event) => {
    if (!event.target.classList.contains('profile-dropdown-content') && profileDropdown) {
      setProfileDropdown(false)
    } 
  }

  const handleNavigationStuff = (event) => {
    const isOpen = document.body.classList.contains('nav-open')
    if (window.innerWidth > 799 && innerWidthRef.current < 800 && !isOpen) {
      document.body.classList.add('nav-open')
      setIsNavOpen(true)
    } else if (window.innerWidth < 800 && innerWidthRef.current > 799 && isOpen) {
      document.body.classList.remove('nav-open')
      setIsNavOpen(false)
    }
    innerWidthRef.current = window.innerWidth
  }

  const toggleNavbar = () => {
    const isOpen = document.body.classList.contains('nav-open')
    if (isOpen) {
      setIsNavOpen(false)
      document.body.classList.remove('nav-open')
    } else {
      setIsNavOpen(true)
      document.body.classList.add('nav-open')
    }
  }

  return (
    <div className="topbar" id='topbar'>
      <div
        className="nav-toggler"
        onClick={toggleNavbar}
        title={isNavOpen ? 'Close Navigation' : 'Open Navigation'}
      >
        <MenuOpenOutlinedIcon />
      </div>
      <div className="topbar-inner">
        <div className="current-page-title">
          <span>{ title }</span>
        </div>
        <div className='topbar-logo'>
          <img src={logo} alt="Bhola Trading" />
        </div>
        <div className="topbar-profile" >
          <span onClick={() => setProfileDropdown(!profileDropdown)}>
            <FiUser className="fiUser" />
            <span className='user-title'>{userName}</span>
            {profileDropdown
              ? (<HiChevronUp className="downarrow" />)
              : (<HiChevronDown className="downarrow" />)
            }
            
            <ul className={`profile-dropdown profile-dropdown-content ${profileDropdown ? 'show' : ''}`}>
              <li
                className='profile-dropdown-content'
                onClick={handleLogout}
              >
                Log Out
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  )
};

export default Topbar;
