import React, { useState, useEffect } from "react"
import {
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom"
import Select from "react-select"
import moment from 'moment'
import { message, Spin, Modal, DatePicker } from 'antd'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import axios from '../config/axios'
import '../style/common.css'
import '../style/buy.css'
import '../style/customer-transaction.css'
import '../style/entityinfo.css'

import {
  getCurrentUser,
  removeObjectPrototype,
  redirectLoginIfLoggedOut,
  getFloatNumber,
  getDateFromDateTime,
  getFormattedReadableNumber
} from '../utils/helper'
import {
  fetchAllProducts,
  fetchAllusers,
  fetchEntityInfoByEntityId,
  fetchTransactionById,
  fetchEntityById,
  fetchTransactionsByEntityId,
  fetchTransactionHistoryByTransactionId,
  fetchAllEntities,
  fetchAdvanceTransactionByEntityId
} from "../utils/apis"

const { RangePicker } = DatePicker

const EntityInfo = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const _user = getCurrentUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const _entityId = searchParams.get('eid')
  const _transactionId = searchParams.get('tid')
  const [qString, setQString] = useState({
    eid: _entityId,
    tid: _transactionId
  })
  
  const [entityInfo, setEntityInfo] = useState({})

  const [transactionLoading, setTransactionLoading] = useState(false)
  const [transactionSubmitLoading, setTransactionSubmitLoading] = useState(false)
  const [transactionHistoryLoading, setTransactionHistoryLoading] = useState(true)
  const [selectedTransactionId, setSelectedTransactionId] = useState('')

  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [transactions, setTransactions] = useState([])
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [rawTransactions, setRawTransactions] = useState([])
  const [transactionHistory, setTransactionHistory] = useState([])
  const [users, setUsers] = useState([])
  const [products, setProducts] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({
    label: 'All Products',
    value: 'all'
  })
  const [entities, setEntities] = useState([])
  const [entity, setEntity] = useState({})
  const [paidAmount, setPaidAmount] = useState('')
  
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  
  const [startDateForAdvancePaid, setStartDateForAdvancePaid] = useState('')
  const [endDateForAdvancePaid, setEndDateForAdvancePaid] = useState('')

  const [note, setNote] = useState('')
  const [rawAdvanceTransactions, setRawAdvanceTransactions] = useState([])
  const [advanceTransactions, setAdvanceTransactions] = useState([])
  const [advanceTransactionLoading, setAdvanceTransactionLoading] = useState(true)

  const [selectedTransactionHistoryId, setSelectedTransactionHistoryId] = useState('')
  const [transactionHistoryDeleteConfirmModal, setTransactionHistoryDeleteConfirmModal] = useState(false)
  const [deleteTransactionHistoryLoading, setDeleteTransactionHistoryLoading] = useState(false)

  useEffect(() => {
    console.log('_entityId: ', _entityId)
    console.log('_transactionId: ', _transactionId)
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/home')
    }
    
    init()
  }, [])

  useEffect(() => {
    console.log('searchParams: ', searchParams)
    console.log('searchParams.get(eid): ', searchParams.get('eid'))
    if (searchParams.get('tid')) {
      getAndSetSelectedTransaction()
    }
    
  }, [searchParams])

  useEffect(() => {
    if (users.length > 0) {
      getAndSetTransactionHistory()
    }
    
  }, [selectedTransactionId])

  

  useEffect(() => {
    generateFilteredTransactions()
  }, [transactions, startDate, endDate])

  useEffect(() => {
    if (startDateForAdvancePaid && endDateForAdvancePaid) {
      const _temp5 = rawAdvanceTransactions.filter((_item) => _item.date >= startDateForAdvancePaid && _item.date <= endDateForAdvancePaid)
      setAdvanceTransactions(_temp5)
    } else {
      setAdvanceTransactions(rawAdvanceTransactions)
    }
  }, [startDateForAdvancePaid, endDateForAdvancePaid, rawAdvanceTransactions])

  

  const init = async () => {
    const _users = await fetchAllusers()
    setUsers(_users) 
    const _products = await fetchAllProducts()
    setProducts(_products)
    setProductOptions([
      {
        label: 'All Products',
        value: 'all'
      },
      ..._products.map((_item) => ({
        value: _item._id,
        label: _item.name
      }))
    ])

    const _entities = await fetchAllEntities()
    setEntities(_entities)


    if (_entityId) {
      const _transactions = await fetchTransactionsByEntityId(_entityId)
      setRawTransactions(_transactions)
      
      if (selectedProduct.value === 'all') {
        setTransactions(_transactions)
      } else {
        setTransactions(_transactions.filter((_item) => _item.productId === selectedProduct.value))
      }

      const _entityInfo = await fetchEntityInfoByEntityId(_entityId)
      setEntityInfo(_entityInfo)

      const _entity = await fetchEntityById(_entityId)
      setEntity(_entity)

      if (_entity && _entity.type) {
        const _advanceTransactions = await fetchAdvanceTransactionByEntityId(_entity._id)
        const _temp4 = _advanceTransactions.map((_it4) => {
          const { name: userName } = _users.find((_it5) => _it5._id === _it4.userId)
          return {
            ..._it4,
            userName: userName || '-'
          }
        })
        setRawAdvanceTransactions(_temp4)
        setAdvanceTransactions(_temp4)
        setAdvanceTransactionLoading(false)
      }
    }

    if (_transactionId) {
      const _transaction = await fetchTransactionById(_transactionId)
      setSelectedTransaction(_transaction)

      const _transactionHistory = await fetchTransactionHistoryByTransactionId(_transactionId)
      const _temps = _transactionHistory.map((_item) => {
        const _fUser = _users.find((_item2) => _item2._id === _item.userId)
        return {
          ..._item,
          userName: _fUser.name || '-'
        }
      }).sort((_a, _b) => _a.readableId > _b.readableId ? -1 : _a.readableId < _b.readableId ? 1 : 0)
      setTransactionHistory(_temps)
      setTransactionHistoryLoading(false)

      const _entity = await fetchEntityById(_transaction.entityId)
      setEntity(_entity)
    }
  }

  const getAndSetTransactionHistory = async () => {
    const _transaction = await fetchTransactionById(_transactionId)
    setSelectedTransaction(_transaction)
    setTransactionHistoryLoading(true)
    const _transactionHistory = await fetchTransactionHistoryByTransactionId(_transactionId)
    const _temps = _transactionHistory.map((_item) => {
      const _fUser = users.find((_item2) => _item2._id === _item.userId)
      return {
        ..._item,
        userName: _fUser.name || '-'
      }
    }).sort((_a, _b) => _a.readableId > _b.readableId ? -1 : _a.readableId < _b.readableId ? 1 : 0)
    setTransactionHistory(_temps)

    setTransactionHistoryLoading(false)
  }

  const generateFilteredTransactions = () => {
    if (!startDate || !endDate) {
      setFilteredTransactions(transactions)
    } else {
      setFilteredTransactions(transactions.filter((_item) => {
        const _startDate = new Date(startDate)
        const _endDate = new Date(endDate)
        const _itemDate = new Date(_item.date)
        return _itemDate >= _startDate && _itemDate <= _endDate
      }))
    }
  }


  const getAndSetSelectedTransaction = async () => {
    const _transaction = await fetchTransactionById(_transactionId)
    setSelectedTransaction(_transaction)
  }
  
  const handleTransactionSubmit = async () => {

    console.log('testing selectedTransaction.due: ', parseFloat(selectedTransaction.due))
    console.log('testing paidAmount: ', parseFloat(paidAmount))

    const _tempDue = parseFloat(parseFloat(selectedTransaction.due).toFixed(2))

    if (_tempDue < parseFloat(paidAmount)) {
      message.info('Paid amount must be equel or less than due amount.')
      return
    }

    const body = {
      due: selectedTransaction.due - parseFloat(paidAmount),
      paid: selectedTransaction.paid + parseFloat(paidAmount),
      dateForCashHistory: getDateFromDateTime(new Date()),
      userId: _user._id,
      newDate: date,
      note
    }
    
    console.log('body: ', body)
    
    setTransactionSubmitLoading(true)
    try {
      const { data } = await axios.put(
        `/transaction/${_transactionId}`,
        body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )

      fetchTransactionById(_transactionId)
      setPaidAmount('')
      
      message.success(data.message)
      setTransactionSubmitLoading(false)
      getAndSetTransactionHistory()
      
      const _transactions = await fetchTransactionsByEntityId(_entityId)
      setRawTransactions(_transactions)
      
      if (selectedProduct.value === 'all') {
        setTransactions(_transactions)
      } else {
        setTransactions(_transactions.filter((_item) => _item.productId === selectedProduct.value))
      }
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setTransactionSubmitLoading(false)
    }
  }

  const handleTransactionHistoryDelete = async () => {
    try {
      const url = `/transactionhistory/${selectedTransactionHistoryId}`
      const config = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      const { data } = await axios.delete(url, config)
      
      message.success(data.message)
      
      setDeleteTransactionHistoryLoading(false)
      setTransactionHistoryDeleteConfirmModal(false)
      await init()
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }
  

  return (
    <div className="entityinfo-container-outer  common-container">
      <Modal
        visible={transactionHistoryDeleteConfirmModal}
        title="Transaction History Delete Confirmation"
        onCancel={() => {
          setSelectedTransactionHistoryId('')
          setTransactionHistoryDeleteConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setTransactionHistoryDeleteConfirmModal(false)
              setSelectedTransactionHistoryId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleTransactionHistoryDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Transaction History?</p>
      </Modal>
      {_entityId && (
        <>
          <div className="total-entityinfo-container">
            <div className="total-entityinfo-header back-button-container">
              <h3>Summery of <strong>{ entity.name }</strong></h3>
              <button
                className='custom-button custom-button-sm'
                onClick={() => {
                  navigate(-1)
                }}
              >
                Back
              </button>
            </div>
            <div className="total-entityinfo-body">
              <div className="entityinfo-item">
                <p>Total Amount</p>
                <p><strong>{getFormattedReadableNumber(entityInfo.totalAmount)}</strong></p>
              </div>
              <div className="entityinfo-item">
                <p>Paid Amount</p>
                <p><strong>{getFormattedReadableNumber(entityInfo.paidAmount)}</strong></p>
              </div>
              <div className="entityinfo-item">
                {entity.due && entity.due > 0 ? (
                  <>
                    <p>Due Amount</p>
                    <p><strong>{getFormattedReadableNumber(entity.due)}</strong></p>
                  </>
                ) : (
                    <>
                      <p>Advance Paid</p>
                      <p><strong style={{color: 'green'}}>{getFormattedReadableNumber(entity.due * -1)}</strong></p>
                    </>
                )}
              </div>
              
              
            </div>
          </div>
          
          
          <div className="entityinfo-transaction-container">
            <div className="entityinfo-transaction-header">
              <h3>All Transactions of <strong>{ entity.name }</strong></h3>
            </div>
            <div className="entityinfo-transaction-filter">
              <Select
                options={productOptions}
                value={selectedProduct}
                onChange={(selected) => {
                  if (selected.value === 'all') {
                    setTransactions(rawTransactions)
                  } else {
                    setTransactions(rawTransactions.filter((_item) => _item.productId === selected.value))
                  }
                  setSelectedProduct(selected)
                }}
                // isDisabled={viewMode === 'update-bank-loan'}
              />
              <RangePicker
                onChange={(value, dateString) => {
                  console.log('dateString: ', dateString)
                  setStartDate(dateString[0])
                  setEndDate(dateString[1])
                }}
              />
            </div>
            {transactionLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
              <div className={`entityinfo-transaction-body common-container-body`}>
                <div className="entityinfo-transaction-table-header">
                  <div className="entityinfo-transaction-row common-row header">
                    <span>Date</span>
                    <span>{ entity.type === 'customer' ? 'Sale Id' : 'Purchase Id' }</span>
                    <span>Rice Name</span>
                    <span>Quantity (kg)</span>
                    <span>Price (৳)</span>
                    <span>Paid</span>
                    <span>Due</span>
                  </div>
                </div>
                <div className="entityinfo-transaction-table-body">
                  {filteredTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction is available</p>)
                    : filteredTransactions.map((transactionItem, _index) => (
                      <div
                        key={_index}
                        className='entityinfo-transaction-row common-row clickable'
                        onClick={() => {
                          setSelectedTransactionId(transactionItem._id)
                          fetchTransactionById(transactionItem._id)
                          const qST = {
                            'tid': transactionItem._id,
                            'eid': transactionItem.entityId
                          }
                          if (_entityId) {
                            qST['eid'] = transactionItem.entityId
                          }
                          setSearchParams(qST)
                        }}
                      >
                      <span>{ moment(transactionItem.date).format('YYYY-MM-DD') }</span>
                      <span>{ transactionItem.buyId || transactionItem.sellId }</span>
                      <span>{ products.find((_item) => _item._id === transactionItem.productId)?.name || '-' }</span>
                      <span>{ getFormattedReadableNumber(transactionItem.quantity) } </span>
                      <span>{ getFormattedReadableNumber(transactionItem.quantity * transactionItem.unitPrice) } ৳</span>
                      <span>{ getFormattedReadableNumber(transactionItem.paid) }</span>
                      <span>{ getFormattedReadableNumber(transactionItem.due) }</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {_transactionId && (
        <>
          <div className="edit-transaction-container">
            <div className="edit-transaction-header">
              <h3>Selected { entity.type === 'customer' ? 'Sale' : 'Buy' } Transaction Information</h3>
            </div>
            <div className="edit-transaction-body">
              <div className="input-item">
                <label>{ selectedTransaction.type === 'buy' ? 'Vendor' : 'Customer' } Name</label>
                <p><strong>{entities.find((_item) => _item._id === selectedTransaction.entityId)?.name || '-'}</strong></p>
              </div>
              <div className="input-item">
                <label>Date</label>
                <p><strong>{moment(selectedTransaction.date).format('YYYY-MM-DD')}</strong></p>
              </div>
              <div className="input-item">
                <label>Rice Name</label>
                <p><strong>{products.find((_item) => _item._id === selectedTransaction.productId)?.name || '-' }</strong></p>
              </div>
              <div className="input-item">
                <label>Quantity</label>
                <p><strong>{selectedTransaction.quantity}</strong></p>
              </div>
              <div className="input-item">
                <label>Unit Price</label>
                <p><strong>{selectedTransaction.unitPrice}</strong></p>
              </div>
              <div className="input-item">
                <label>Total Price</label>
                <p><strong>{getFormattedReadableNumber(selectedTransaction.quantity * selectedTransaction.unitPrice)}</strong></p>
              </div>
              
              <div className="input-item">
                <label>Paid Amount</label>
                <p><strong>{getFormattedReadableNumber(parseFloat(selectedTransaction.paid).toFixed(2))}</strong></p>
              </div>
              <div className="input-item">
                <label>Due Amount</label>
                <p><strong>{getFormattedReadableNumber(parseFloat(selectedTransaction.due).toFixed(2))}</strong></p>
              </div>
              
            </div>
            <div className="update-inputs">
              <div className="input-item">
                <label>Pay Amount</label>
                <input
                  type="text"
                  id="quantity"
                  placeholder="Enter Amount here"
                  disabled={selectedTransaction.due === 0}
                  value={paidAmount}
                  onChange={(e) => setPaidAmount(getFloatNumber(e.target.value, paidAmount))}
                />
              </div>
              <div className="input-item">
                <label>Date</label>
                <DatePicker
                  defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                  onChange={(value, dateString) => {
                    console.log(dateString)
                    setDate(dateString)
                  }}
                />
              </div>
              <div className="input-item note-item">
                <label>Note</label>
                <textarea
                  name="note"
                  id="note"
                  rows="3"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
            <div className="edit-transaction-footer">
              <button
                className="custom-button custom-button-sm"
                onClick={handleTransactionSubmit}
                disabled={!paidAmount || paidAmount === 0}
              >
                Update
              </button>
            </div>
          </div>
          
          <div className="transaction-history-container">
            <div className="transaction-history-header">
              <h3>Transaction History</h3>
            </div>
            {transactionHistoryLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
              <div className={`transaction-history-body common-container-body ${_user.role}`}>
                <div className="transaction-history-table-header">
                  <div className="transaction-history-row common-row header">
                    <span>Id</span>
                    <span>Transactor</span>
                    <span>Amount</span>
                    <span>Date</span>
                    <span>Note</span>
                    {_user.role === 'super_admin' && <span>Action</span>}
                  </div>
                </div>
                <div className="transaction-history-table-body">
                  {transactionHistory.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction History is available</p>)
                    : transactionHistory.map((historyItem, _index) => (
                      <div className='transaction-history-row common-row'>
                        <span>{ historyItem.readableId } </span>
                        <span>{ historyItem.userName }</span>
                        <span>{ getFormattedReadableNumber(historyItem.paidAmount) }</span>
                        <span>{ historyItem.date || moment(historyItem.createdAt).format('YYYY-MM-DD') }</span>
                        <span>{historyItem.note || '-'}</span>
                        {_user.role === 'super_admin' && (
                          <span
                            onClick={() => {
                              setSelectedTransactionHistoryId(historyItem._id)
                              setTransactionHistoryDeleteConfirmModal(true)
                            }}
                          >
                            <DeleteOutlinedIcon />
                          </span>
                        )}
                      </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {entity && entity.type && (
        <div className="advance-transaction-container">
          <div className="advance-transaction-header">
            <h3>Advance Paid Transactions Of <strong>{entity.name}</strong></h3>
          </div>
          <div className="advance-paid-transaction-filter">
            <label>Select Date</label>
            <RangePicker
              onChange={(value, dateString) => {
                console.log('dateString: ', dateString)
                setStartDateForAdvancePaid(dateString[0])
                setEndDateForAdvancePaid(dateString[1])
              }}
            />
          </div>
          {advanceTransactionLoading ? (<Spin style={{ marginTop: '40px' }} size="large" />) : (
            <div className={`advance-transaction common-container-body`}>
              <div className="advance-transaction-table-header">
                <div className="advance-transaction-row common-row header">
                  <span>Id</span>
                  <span>Transactor</span>
                  <span>Amount</span>
                  <span>Date</span>
                  <span>Note</span>
                </div>
              </div>
              <div className="advance-transaction-table-body">
                {advanceTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Advance Paid Transaction is available</p>)
                  : advanceTransactions.map((advancePaidItem, _index) => (
                    <div className='advance-transaction-row common-row' key={_index}>
                      <span>{ advancePaidItem.readableId } </span>
                      <span>{ advancePaidItem.userName }</span>
                      <span>{ getFormattedReadableNumber(advancePaidItem.amount) }</span>
                      <span>{ advancePaidItem.date ? moment(advancePaidItem.date).format('YYYY-MM-DD') : ''}</span>
                      <span>{ advancePaidItem.note  || '-'}</span>
                    </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      
    </div>
  )
}

export default EntityInfo