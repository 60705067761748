import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { message } from "antd";
// import { create}
import axios from "../config/axios";
import logo from "../image/bholatreding.png";
import "../style/Login.css";
import {
  redirectHomeIfLoggedIn,
  isValidEmail
} from "../utils/helper";

const Login = () => {

  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  

  useEffect(() => {
    redirectHomeIfLoggedIn(navigate)
  }, [])
  
  const loginSubmit = async () => {
    if (!password || password.length < 1 || !email || email.length < 1) {
      setErrorMessage('Please enter all fields')
      message.error('Please enter all fields', 4)
      return
    }
    if (!isValidEmail(email)) {
      setErrorMessage('Invalid Email Address')
      message.error('Invalid Email Address')
      return
    }
    try {
      const { data } = await axios.post(
        '/auth',
        {
          email,
          password
        }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      if (data && data.user) {
        message.success('Successfully logged in.')
        localStorage.setItem('user', JSON.stringify(data.user))
        navigate('/home')
      }
    } catch (_error) {
      console.log('error: ', _error)
      message.error('Invalid Email or Password')
    }
    

  }

  return (
    <div className="loginFull">
      <div className="loginForm">
        <div className="pic"></div>
        <div className="login-container">
          <div className="logo">
            <img src={logo} className="bholaLogo" alt="Bhola Trading Logo" />
          </div>
          <div className="email-input">
            <input
              type="email"
              className="userName"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                // if (validateEmail(email)) {
                //   setErrorMessage('')
                // }
              }}
            />
          </div>
          <div className="password-input">
            <input
              type="password"
              className="password"
              placeholder="Password"
              value = {password}
              onChange = {(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="submit-login">
            <button onClick={loginSubmit}> Login </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
