import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'
import { message, Spin, DatePicker } from 'antd'
import html2canvas from 'html2canvas'
import jsPDF from "jspdf"
import axios from '../config/axios'
import ExpenseTypes from "./ExpenseTypes";
import CustomDateFilter from '../components/CustomDateFilter';
import {
  getCurrentUser,
  redirectLoginIfLoggedOut,
  generatePDF,
  removeObjectPrototype
} from '../utils/helper'
import '../style/common.css'
import '../style/buy.css'
import '../style/expense.css'
import '../style/expense_by_type.css'
import {
  fetchExpenseTypeById,
  fetchAllusers,
  fetchAllProducts
} from "../utils/apis";

const ExpensesByType = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const _expenseTypeId = searchParams.get('typeid')
  const _user = getCurrentUser()

  const expensesAllRef = useRef()
  
  const [selectedExpenseType, setSelectedExpenseType] = useState({})
  const [transactionLoading, setExpenseLoading] = useState(true)
  const [expenseTransactions, setExpenseTransactions] = useState([])
  const [dateFilter, setDateFilter] = useState('today')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [users, setUsers] = useState([])
  const [products, setProducts] = useState([])

  const [totalExpense, setTotalExpense] = useState(0)

  const [expenseDownloadClicked, setExpenseDownloadClicked] = useState(false)

  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/')
    }
    init()
  }, [])

  useEffect(() => {
    if (dateFilter !== 'custom' && selectedExpenseType) {
      fetchTransactionsByType()
    }
  }, [dateFilter, selectedExpenseType])

  
  const init = async () => {
    const _users = await fetchAllusers()
    setUsers(_users)
    const _products = await fetchAllProducts()
    setProducts(_products)
    const _selectedExpensetype = await fetchExpenseTypeById(_expenseTypeId)
    setSelectedExpenseType(_selectedExpensetype)
  }
  
  const fetchTransactionsByType = async () => {
    setExpenseLoading(true)
    const _path = dateFilter !== 'custom'
      ? `/expense/type/${dateFilter}`
      : `/expense/type/${startDate}/${endDate}`
    
    console.log('fetchExpenseTransactions: ', _path)
    try {
      const { data } = await axios.post(
        _path, { typeId: selectedExpenseType._id, timeOffset: (new Date()).getTimezoneOffset() }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('type expenses: ', data)
      setExpenseTransactions(data.map((_item) => removeObjectPrototype(_item)).map((_item) => {
        const _foundU = users.find((_item1) => _item1._id === _item.userId)
        const _foundP = products.find((_item1) => _item1._id === _item.productId)
        return {
          userName: _foundU.name,
          productName: _foundP ? _foundP.name : '-',
          ..._item,
        }
      }))
      setTotalExpense(data.reduce((_sum, _item) => _sum + _item.amount, 0))
      setExpenseLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      setExpenseLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }


  

  return (
    <div className="expense-container">
      <div className="expense-transaction-container">
        <div className="expense-header back-button-container">
          <h3><strong>{selectedExpenseType.name} </strong> Expenses</h3>
          <button
            className="custom-button custom-button-sm"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        <div className="in-out-stock-filter">
          <CustomDateFilter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleSubmit={fetchTransactionsByType}
          />
        </div>
        <div className="expense-transaction-header">
          
          <span>
            <h3>Total: <strong>{totalExpense}</strong></h3>
          </span>
          <button
            className="custom-button custom-button-sm"
            onClick={() => {
              setExpenseDownloadClicked(true)
              setTimeout(() => { 
                const fileName = `product-expenses-${moment().format('YYYY-MM-DD')}.pdf`
                generatePDF(fileName, expensesAllRef, setExpenseDownloadClicked)
              }, 0)
              
            }}
          >
            Export PDF
          </button>
        </div>
        <div className="expense-transaction-body-outer">
          <div
            className="expense-transaction-body expense-type-transaction-body"
            ref={expensesAllRef}
            style={expenseDownloadClicked ? {
              width: '89rem',
              padding: '4rem'
            } : {}}
            >
            <div className="expense-transaction-table-header">
              <div className="expense-row header">
                <span>Date</span>
                <span>ID</span>
                <span>Entry Man</span>
                <span>Product(?)</span>
                <span>Amount (৳)</span>
                <span>Note</span>
              </div>
            </div>
            <div className="expense-transaction-table-body">
              {expenseTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction is available</p>)
                : expenseTransactions.map((expenseItem, _index) => (
                <div key={_index} className='expense-row'>
                  <span>{ moment(expenseItem.date).format('YYYY-MM-DD') }</span>
                  <span>{ expenseItem.expenseProductId || expenseItem.expenseId}</span>
                  <span>{ expenseItem.userName }</span>
                  <span>{ expenseItem.productName }</span>
                  <span>{ parseFloat(expenseItem.amount).toFixed(2) }</span>
                  <span>{ expenseItem.note }</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default ExpensesByType