import React, { useState, useEffect } from 'react'
import { DatePicker } from 'antd'
import '../style/custom_date_filter.css'

const { RangePicker } = DatePicker

const CustomDateFilter = ({
  dateFilter,
  setDateFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmit
}) => {
  
  
  return (
    <div className="custom-date-filter-outer">
      <div className='custom-date-filter'>
        <button
          className={dateFilter === 'today' ? 'active' : ''}
          onClick={() => {
            setDateFilter('today');
            setStartDate('')
            setEndDate('')
          }}
        >
          Today
        </button>
        <button
          className={dateFilter === 'yesterday' ? 'active' : ''}
          onClick={() => {
            setDateFilter('yesterday')
            setStartDate('')
            setEndDate('')
          }}
        >
          Yesterday
        </button>
        <button
          className={dateFilter === 'lastweek' ? 'active' : ''}
          onClick={() => {
            setDateFilter('lastweek')
            setStartDate('')
            setEndDate('')
          }}
        >
          Last Week
        </button>
        <button
          className={dateFilter === 'lastmonth' ? 'active' : ''}
          onClick={() => {
            setDateFilter('lastmonth')
            setStartDate('')
            setEndDate('')
          }}
        >
          Last Month
        </button>
        <button
          className={dateFilter === 'custom' ? 'active' : ''}
          onClick={() => {
            setDateFilter('custom')
          }}
        >
          Custom
        </button>
      </div>
      <div className="date-range-picker">
        {dateFilter === 'custom' && (
          <div className="date-range-picker-inner">
            <RangePicker
              onChange={(value, dateString) => {
                console.log('dateString: ', dateString)
                setStartDate(dateString[0])
                setEndDate(dateString[1])
              }}
            />
            <button
              className="button-filter"
              disabled={!startDate || !endDate}
              onClick={() => handleSubmit()}
            >
              Filter
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomDateFilter