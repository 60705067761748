import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'
import { message, Spin, DatePicker, Modal } from 'antd'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import axios from '../config/axios'
import {
  getCurrentUser,
  removeObjectPrototype,
  redirectLoginIfLoggedOut,
  getFloatNumber,
  generatePDF,
  getFormattedReadableNumber
} from '../utils/helper'
import {
  fetchAllusers
} from '../utils/apis'
import '../style/common.css'
import '../style/bank-loan.css'

const accountTypeOptions = [
  {
    label: 'Loan(Credit)',
    value: 'Loan(Credit)'
  }, {
    label: 'Deposit(Debit)',
    value: 'Deposit(Debit)'
  }
]

const bankTypeOptions = [
  {
    label: 'Loan',
    value: 'Loan'
  }, 
  {
    label: 'Current',
    value: 'Current'
  }
]

const transactionTypeOptions = [
  {
    label: 'Cash In',
    value: 'Cash In'
  }, 
  {
    label: 'Cash Out',
    value: 'Cash Out'
  }
]

const BankLoan = () => {

  const navigate = useNavigate()
  const _user = getCurrentUser()

  const [viewMode, setViewMode] = useState('add-bank-loan')
  const [deleteAccountConfirmModal, setDeleteAccountConfirmModal] = useState(false)
  const [bankLoading, setBankLoading] = useState(true)
  const [bankLoanLoading, setBankLoanLoading] = useState(true)
  const [bankSubmitLoading, setBankSubmitLoading] = useState(false)
  const [bankLoanSubmitLoading, setBankLoanSubmitLoading] = useState(false)
  const [transactionSubmitLoading, setTransactionSubmitLoading] = useState(false)
  const [banks, setBanks] = useState([])
  const [bankOptions, setBankOptions] = useState([])
  const [selectedBank, setSelectedBank] = useState({})
  const [selectedBankForEdit, setSelectedBankForEdit] = useState({})

  const [bankName, setBankName] = useState('')
  const [branch, setBranch] = useState('')

  const [selectedBankType, setSelectedBankType] = useState(bankTypeOptions[0])
  const [selectedAccountType, setSelectedAccountType] = useState(accountTypeOptions[0])
  const [selectedTransactionType, setSelectedTransactionType] = useState(transactionTypeOptions[0])
  const [totalAmount, setTotalAmount] = useState('')
  const [dueAmount, setDueAmount] = useState('')
  const [payAmount, setPayAmount] = useState('')
  const [interestRate, setInterestRate] = useState('')
  const [prevInterestRate, setPrevInterestRate] = useState('')
  const [note, setNote] = useState('')
  const [bankLoanDate, setBankLoanDate] = useState(moment().format('YYYY-MM-DD'))
  const [bankLoans, setBankLoans] = useState([])
  const [rawBankLoans, setRawBankLoans] = useState([])
  const [bankLoanTransactions, setBankLoanTransactions] = useState([])

  const [searchName, setSearchName] = useState('')
  const [selectedBankLoan, setSelectedBankLoan] = useState({})
  const [transactionNote, setTransactionNote] = useState('')

  const [selectedBankId, setSelectedBankId] = useState('')
  const [deleteBankConfirmModal, setDeleteBankConfirmModal] = useState(false)

  const [users, setUsers] = useState([])
  const trnasactionRef = useRef()
  const _docRef = useRef()
  const _extraDataPdfRef = useRef()

  const [downloadClicked, setDownloadClicked] = useState(false)

  // const [dateFilter, setDateFilter] = useState('today')
  // const [startDate, setStartDate] = useState('')
  // const [endDate, setEndDate] = useState('')

  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/')
    }
    init()
    
  }, [])



  useEffect(() => {
    // handleSearchName(searchName)
  }, [rawBankLoans])

  const init = async () => {
    const _allBanks = await fetchAllBanks()
    setBanks(_allBanks)
    setBankOptions(_allBanks.map((_item) => ({ value: _item._id, label: _item.name })))
    const _bankLoans = await fetchAllBankLoans()
    const _tempBankLoans = _bankLoans.map((_item) => {
      const _found = _allBanks.find((_item2) => _item2._id === _item.bankId)
      return {
        bankName: _found?.name || '-',
        ..._item,
      }
    }).sort((_a, _b) => _a.bankLoanId > _b.bankLoanId ? -1 : _a.bankLoanId < _b.bankLoanId ? 1 : 0)
    setRawBankLoans(_tempBankLoans)
    setBankLoans(_tempBankLoans.filter((_item) => {
      const re = new RegExp(searchName, "i");
      const _matched = _item.bankName.match(re)
      return _matched
    }))
    // console.log('selectedBankLoan: ', selectedBankLoan)
    const _newSelectedBankLoan = _bankLoans.find((_item) => _item._id === selectedBankLoan?._id)
    if (_newSelectedBankLoan) {
      setSelectedBankLoan(_newSelectedBankLoan)
      setTotalAmount(_newSelectedBankLoan.totalAmount) 
      setDueAmount(_newSelectedBankLoan.due + getCurrentInterest(_newSelectedBankLoan))
    }
    
  }
  
  useEffect(() => {
    console.log('payAmount: ', payAmount)
  }, [payAmount])
  


  const handleSearchName = (_key) => {
    setSearchName(_key)
    setBankLoans(rawBankLoans.filter((_item) => {
      const re = new RegExp(_key, "i");
      const _matched = _item.bankName.match(re)
      return _matched
    }))
  }

  const fetchAllBanks = async () => {
    try {
      const { data } = await axios.get(
        '/bank', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('bank data: ', data)
      return data.map((_item) => removeObjectPrototype(_item))
    } catch (_error) {
      console.log('error: ', _error)
    }
  }
  const fetchAllBankLoans = async () => {
    try {
      const { data } = await axios.get(
        '/bankloan', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('bankloan data: ', data)
      return data.map((_item) => removeObjectPrototype(_item))
    } catch (_error) {
      console.log('error: ', _error)
    }
  }

  const fetchAllBankLoanTransactionsByBankLoanId = async (_bankLoanId) => {
    try {
      const { data } = await axios.get(
        `/bankloantransaction/bankloan/${_bankLoanId}`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      const _users = await fetchAllusers()
      setUsers(_users)

      console.log('_transactions: ', data)
      // console.log('_users: ', _users)
      setBankLoanTransactions(
        data
          .map((_item) => removeObjectPrototype(_item))
          .map((_item) => {
            const _found = _users.find((_item2) => _item2._id === _item.userId)
            // console.log('found: ', _found)
            
            return {
              ..._item,
              userName: _found?.name || '-'
            }
          })
          .sort((_a, _b) => _a.bankLoanTransactionId > _b.bankLoanTransactionId ? -1 : _a.bankLoanTransactionId < _b.bankLoanTransactionId ? 1 : 0)
        
      )
    } catch (_error) {
      console.log('error: ', _error)
    }
  }

  const handleBankSubmit = async () => {

    const body = {
      name: bankName,
      branch,
      type: selectedAccountType.value,
      userId: _user?._id
    }
    
    console.log('body: ', body)
    if (!body.name) {
      message.info('Bank Name is required')
      return
    }
    if (!body.branch) {
      message.info('Branch Name is required')
      return
    }
    

    setBankSubmitLoading(true)
    try {
      if (!selectedBankForEdit || !selectedBankForEdit.name) {
        const { data } = await axios.post(
          '/bank',
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setBankSubmitLoading(false)
        setBankName('')
        setBranch('')
        setSelectedBankType({})
        init()
      } else {
        const { data } = await axios.put(
          `/bank/${selectedBankForEdit._id}`,
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setBankSubmitLoading(false)
        setBankName('')
        setBranch('')
        setSelectedBankType({})
        init()
        setViewMode('add-bank-loan')
      }
      
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setBankSubmitLoading(false)
    }
  }

  const handleBankLoanSubmit = async () => {

    const body = {
      type: selectedAccountType?.value,
      bankId: selectedBank?.value,
      totalAmount,
      interestRate,
      date: bankLoanDate,
      note,
      userId: _user?._id
    }
    
    console.log('body: ', body)
    if (!body.bankId) {
      message.info('Bank must be selected')
      return
    }
    if (!body.totalAmount) {
      message.info(selectedAccountType.label + ' amount is required')
      return
    }
    
    setBankLoanSubmitLoading(true)
    if (viewMode === 'add-bank-loan') {
      body.updatedAt = bankLoanDate
      try {
        const { data } = await axios.post(
          '/bankloan',
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setBankLoanSubmitLoading(false)
        setTotalAmount('')
        setInterestRate('')
        setNote('')
        init()
      } catch (_error) {
        // console.log('error: ', _error)
        // console.log('error: ', _error.response.data.message)
        message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
        setBankLoanSubmitLoading(false)
      }
    } else {
      
      // console.log('sm interestRate: ', parseFloat(interestRate)) 
      // console.log('sm prevInterestRate: ', prevInterestRate)
      // setBankLoanSubmitLoading(false)
      if (parseFloat(interestRate) !== prevInterestRate) {
        const _currentInterest = getCurrentInterest(selectedBankLoan)
        body.due = dueAmount
        body.totalPrevInterest = parseFloat(selectedBankLoan.totalPrevInterest + _currentInterest)
      }
      console.log('updated body: ', body)
      // return

      try {
        const { data } = await axios.put(
          `/bankloan/${selectedBankLoan._id}`,
          body, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        
        message.success(data.message)
        setBankLoanSubmitLoading(false)
        setPayAmount('')
        init()
        fetchAllBankLoanTransactionsByBankLoanId(selectedBankLoan._id)
      } catch (_error) {
        // console.log('error: ', _error)
        // console.log('error: ', _error.response.data.message)
        message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
        setBankLoanSubmitLoading(false)
      }
    }
  }

  const handleTransactionSubmit = async () => {
    const _payAmount = parseFloat(payAmount)
    if (!_payAmount || _payAmount < 1) {
      message.info('Pay Amount Required')
      return
    }
    const body = {
      timeOffset: (new Date()).getTimezoneOffset(),
      amount: _payAmount,
      bankLoanId: selectedBankLoan._id,
      userId: _user._id,
      note: transactionNote,
      _bankLoanType: selectedBankLoan.type,
      _transactionType: selectedTransactionType.value,
    }

    console.log('dueAmount: ', dueAmount)   
    console.log('_payAmount: ', _payAmount)   
    if (selectedBankLoan.type === 'Loan(Credit)') {
      const _currentInterest = getCurrentInterest(selectedBankLoan)
        
      if (selectedTransactionType.value === 'Cash In') { // porishodh
        // if (_payAmount > dueAmount) {
        //   message.info('Pay Amount must be less or equal to Due Amount')
        //   return
        // }

        body.due = dueAmount - _payAmount
        body.totalPrevInterest = selectedBankLoan.totalPrevInterest + _currentInterest
      } else { // Uttolon
        body.due = dueAmount + _payAmount
        body.totalAmount = selectedBankLoan.totalAmount + _payAmount
        body.totalPrevInterest = selectedBankLoan.totalPrevInterest + _currentInterest
      }
      

    } else {// Deposit
      const _currentInterest = getCurrentInterest(selectedBankLoan)
        
      if (selectedTransactionType.value === 'Cash In') { // joma

        body.due = dueAmount + _payAmount
        body.totalAmount = selectedBankLoan.totalAmount + _payAmount
        body.totalPrevInterest = selectedBankLoan.totalPrevInterest + _currentInterest
      } else { // uttolon
        // if (_payAmount > dueAmount) {
        //   message.info('Pay Amount must be less or equal to Due Amount')
        //   return
        // }
        body.due = dueAmount - _payAmount
        body.totalPrevInterest = selectedBankLoan.totalPrevInterest + _currentInterest
      }
      

    }
    
    
    
    console.log('update body: ', body)
    // return
    setTransactionSubmitLoading(true)
    try {
      const { data } = await axios.post(
        `/bankloan/createtransaction`,
        body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      setTransactionSubmitLoading(false)
      setPayAmount('')
      setTransactionNote('')
      init()
      fetchAllBankLoanTransactionsByBankLoanId(selectedBankLoan._id)
    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setTransactionSubmitLoading(false)
    }
  }


  const handleBankAccountDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/bankloan/${selectedBankLoan._id}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success('Bank Account Deleted Successfully!')
      init()
      setSelectedBankLoan({})
      setDeleteAccountConfirmModal(false)
    } catch (_error) {
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const handleBankDelete = async () => {
    try {
      const url = `/bank/${selectedBankId}`
      const config = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      const { data } = await axios.delete(url, config)
      
      message.success(data.message)
      
      setDeleteBankConfirmModal(false)
      await init()
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const getBankLoanEditOption = (bankLoanItem) => (
    <button
      className="custom-button custom-button-sm"
      onClick={() => {
        console.log('selectedBankLoan: ', bankLoanItem)
        fetchAllBankLoanTransactionsByBankLoanId(bankLoanItem._id)
        setSelectedBankLoan(bankLoanItem)
        setSelectedBank(bankOptions.find((_item) => _item.value === bankLoanItem.bankId))
        setSelectedAccountType(accountTypeOptions.find((_item) => _item.value === bankLoanItem.type))
        setTotalAmount(bankLoanItem.totalAmount) 
        setDueAmount(bankLoanItem.due + getCurrentInterest(bankLoanItem))
        setInterestRate(bankLoanItem.interestRate)
        setPrevInterestRate(bankLoanItem.interestRate)
        setNote(bankLoanItem.note)
        setBankLoanDate(bankLoanItem.date)
        setViewMode('update-bank-loan')
      }}
    >
      Edit
    </button>
  )

  const getBankLoanDeleteOption = (bankLoanItem) => (
    <button
      className="custom-button custom-button-sm"
      onClick={() => {
        setSelectedBankLoan(bankLoanItem)
        setDeleteAccountConfirmModal(true)
      }}
      style={{
        backgroundColor: 'red',
        marginLeft: '1rem'
      }}
    >
      Delete
    </button>
  )

  const getBankActionOptions = (bankItem) => (
    <>
      <span
        onClick={() => {
          setSelectedBankForEdit(bankItem)
          setBankName(bankItem.name)
          setBranch(bankItem.branch)
          setSelectedBank(bankTypeOptions.find((_item) => _item.value === bankItem._id))
          setViewMode('update-bank')
        }}
      >
        <EditOutlinedIcon />
      </span>
      {_user.role === 'super_admin' && (
        <span
          className="delete-icon"
          onClick={() => {
            setSelectedBankId(bankItem._id)
            setDeleteBankConfirmModal(true)
          }}
        >
          <DeleteOutlinedIcon />
        </span>
      )}
      
      
    </>
    
  )

  const getCurrentInterest = (_loanItem) => {
    
    const _start = moment()
    const _end = moment(_loanItem.updatedAt)
    const dayDiff = parseInt(moment.duration(_start.diff(_end)).asDays())
    // console.log('diff : ', dayDiff)
    
    return (_loanItem.interestRate * _loanItem.due * dayDiff) / 36500
  }

  const getTransactionLists = (__bankLoans) =>
    __bankLoans.length === 0 ? (<p style={{ marginTop: '20px' }}>No Loan is available</p>)
      : __bankLoans.map((loanItem, _index) => {
        const _colorClass = loanItem.type === 'Deposit(Debit)' ? 'green' : 'red'
        return (
          <div key={_index} className='bank-loan-item grid-item'>
            <div className="grid-item-header">
              <div>
                <span>ID: </span><span><strong>{loanItem.bankLoanId}</strong></span>
              </div>
              <div>
                <h4><span>{loanItem.bankName}</span></h4>
              </div>
              <div className="edit-btn-div" style={downloadClicked ? { visibility: 'hidden' } : {}}>
                {getBankLoanEditOption(loanItem)}
                {_user && _user.role === 'super_admin' && getBankLoanDeleteOption(loanItem)}
              </div>
            </div>
            <div className="grid-item-middle">
              <div className="itm-type">
                Type: <strong className={_colorClass}>{loanItem.type}</strong>
              </div>
              <div className="itm-total-amount">
                Total Amount: <strong>{getFormattedReadableNumber(loanItem.totalAmount)}</strong>
              </div>
              <div className="itm-interest-rate">
                Interest Rate: <strong>{!loanItem.interestRate || isNaN(loanItem.interestRate) ? '0.00' : parseFloat(loanItem.interestRate).toFixed(4)}%</strong>
              </div>
              <div className="itm-created-at">
                Created At: <strong>{moment(loanItem.date).format('YYYY-MM-DD')}</strong>
              </div>
              <div className="itm-due-amount">
              {loanItem.type === 'Deposit(Debit)' ? 'Total Deposit Amount' : 'Total Due Amount'}: <strong className={_colorClass}>{getFormattedReadableNumber(loanItem.due + getCurrentInterest(loanItem))}</strong>
              </div>
              <div className="itm-new-interest">
                New Interest: <strong className={_colorClass}>{getFormattedReadableNumber(getCurrentInterest(loanItem))}</strong>
              </div>
              <div className="itm-updated-at">
                Updated At: <strong>{moment(loanItem.updatedAt).format('YYYY-MM-DD')}</strong>
              </div>
              <div className="grid-item-bottom itm-note">
                <p>Notes: </p>
                <p>{loanItem.note}</p>
              </div>
            </div>
          </div>
        )
      })
  
  const getMarkerClassName = (_transactionType) => {
    return _transactionType === 'Cash In'
      ? 'green' : 'red'
  }
    

  return (
    <div className="bank-loan-container common-container">
      <Modal
        visible={deleteAccountConfirmModal}
        title="Bank Account Delete Confirmation"
        onCancel={() => {
          setSelectedBankLoan({})
          setDeleteAccountConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setDeleteAccountConfirmModal(false)
              setSelectedBankLoan({})
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleBankAccountDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Bank Account?</p>
      </Modal>
      <Modal
        visible={deleteBankConfirmModal}
        title="Bank Delete Confirmation"
        onCancel={() => {
          setSelectedBankLoan({})
          setDeleteBankConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setDeleteBankConfirmModal(false)
              setSelectedBankId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleBankDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Bank?</p>
      </Modal>
      {viewMode === 'add-bank-loan' ? (
        <>
          <div className="add-bank-loan-container">
            <div className="add-bank-loan-header">
              <h2>Add Bank Transaction</h2>
              <button
                className='custom-button custom-button-sm'
                onClick={() => {
                  setViewMode('add-bank')
                }}
              >
                Add/Edit Account
              </button>
            </div>
            <div className="add-bank-loan-body common-container-body input-group">
              <div className="input-item">
                <label>Select Bank</label>
                <Select
                  options={bankOptions}
                  value={selectedBank}
                  onChange={(selected) => setSelectedBank(selected)}
                />
              </div>
              <div className="input-item">
                <label>Account Type</label>
                <Select
                  options={accountTypeOptions}
                  value={selectedAccountType}
                  onChange={(selected) => setSelectedAccountType(selected)}
                />
              </div>
              <div className="input-item">
                <label htmlFor="total-amount">Total Amount</label>
                <input
                  type="text"
                  id="total-amount"
                  placeholder="Enter Amount"
                  value={totalAmount}
                  onChange={(e) => {
                    
                    let _temp = getFloatNumber(e.target.value, totalAmount)
                    if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                    setTotalAmount(parseFloat(_temp) >= 0 || _temp === '' ? _temp : totalAmount)

                    
                  }}
                
                />
              </div>
              <div className="input-item">
                <label>Date</label>
                <DatePicker
                  defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                  onChange={(value, dateString) => setBankLoanDate(dateString)}
                />
              </div>
              <div className="input-item note-item">
                <label htmlFor="interest-rate">Interest Rate {selectedAccountType.value === 'Deposit(Debit)' ? '(Optional)' : '' }</label>
                <input
                  type="text"
                  id="interest-rate"
                  placeholder="Enter Interest Rate"
                  value={interestRate}
                  onChange={(e) => {
                    let _temp = getFloatNumber(e.target.value, interestRate)
                    if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                    setInterestRate(parseFloat(_temp) >= 0 || _temp === '' ? _temp : interestRate)

                    // setInterestRate(getFloatNumber(e.target.value, interestRate))
                  }}
                />
              </div>
              <div className="input-item note-item">
                <label>Note</label>
                <textarea
                  name="note"
                  id="note"
                  rows="3"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div> 
            <div className="add-bank-loan-footer common-container-footer">
              {bankLoanSubmitLoading ? <Spin size="large" /> : (
                <button
                  className="custom-button custom-button-sm"
                  onClick={handleBankLoanSubmit}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="bank-loan-transaction-container common-container-body">
            <div className="bank-loan-transaction-he">
              <h2>Recent Transactions</h2>
              <div className="custom-filter input-item bank-account-filter">
              <input
                type="text"
                id="search-name"
                placeholder={`Search Name`}
                value={searchName}
                onChange={(e) => handleSearchName(e.target.value)}
              />
            </div>
            </div>
            <div className="bank-loan-transaction-body">
              {getTransactionLists(bankLoans)}
            </div>
          </div>
        </>
      ) : viewMode === 'add-bank' ? (
          <>
            <div className="add-bank-container">
              <div className="add-bank-header">
                <h2>Add Bank</h2>
                <button
                  className='custom-button custom-button-sm'
                  onClick={() => {
                    setViewMode('add-bank-loan')
                  }}
                >
                  Add/Edit Transaction
                </button>
              </div>
              <div className="add-bank-body common-container-body input-group">
                <div className="input-item">
                  <label htmlFor="bank-name">Bank Name</label>
                  <input
                    type="text"
                    id="bank-name"
                    placeholder="Enter Bank Name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>
                <div className="input-item">
                  <label htmlFor="branch">Branch</label>
                  <input
                    type="text"
                    id="branch"
                    placeholder="Enter Branch Name"
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                  />
                </div>
                <div className="input-item">
                  <label>Account Type</label>
                  <Select
                    options={bankTypeOptions}
                    value={selectedBankType}
                    onChange={(selected) => setSelectedBankType(selected)}
                    // isDisabled={bankLoanTransactions.length > 1}
                  />
                </div>
              </div>
              <div className="add-bank-footer common-container-footer">
                {bankLoanSubmitLoading ? <Spin size="large" /> : (
                  <button
                    className="custom-button custom-button-sm"
                    onClick={handleBankSubmit}
                  >
                    Add Bank
                  </button>
                )}
              </div>
            </div>
            <div className="bank-transaction-container common-container-body">
              <div className="bank-transaction-body">
                <div className="bank-transaction-table-header">
                  <div className="bank-row common-row header">
                    <span>Date</span>
                    <span>Bank Name</span>
                    <span>Type</span>
                    <span>Branch</span>
                    <span>Action</span>
                  </div>
                </div>
                <div className="bank-transaction-table-body">
                  {banks.length === 0 ? (<p style={{ marginTop: '20px' }}>No Loan is available</p>)
                    : banks.map((_bankItem, _index) => (
                      <div key={_index} className='bank-row common-row'>
                        <span>{moment(_bankItem.date).format('YYYY-MM-DD')}</span>
                        <span>{_bankItem.name}</span>
                        <span>{_bankItem.type}</span>
                        <span>{_bankItem.branch}</span>
                        <span>{getBankActionOptions(_bankItem)}</span>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : viewMode === 'update-bank' ? (
            <>
              <div className="add-bank-container">
                <div className="add-bank-header">
                  <h2>Edit Bank</h2>
                  <button
                    className='custom-button custom-button-sm'
                    onClick={() => {
                      setBankName('')
                      setBranch('')
                      setSelectedBankType({})
                      setSelectedBankForEdit({})
                      setViewMode('add-bank')
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className="add-bank-body common-container-body input-group">
                  <div className="input-item">
                    <label htmlFor="bank-name">Bank Name</label>
                    <input
                      type="text"
                      id="bank-name"
                      placeholder="Enter Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                  <div className="input-item">
                    <label htmlFor="branch">Branch</label>
                    <input
                      type="text"
                      id="branch"
                      placeholder="Enter Branch Name"
                      value={branch}
                      onChange={(e) => setBranch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-bank-footer common-container-footer">
                  {bankLoanSubmitLoading ? <Spin size="large" /> : (
                    <button
                      className="custom-button custom-button-sm"
                      onClick={handleBankSubmit}
                    >
                      Update Bank
                    </button>
                  )}
                </div>
              </div>
            </>
        ) : (
              <>
                <div className="update-bank-loan-container">
                  <div className="update-bank-loan-header">
                    <h2>Update Bank Transaction</h2>
                    <button
                      className='custom-button custom-button-sm'
                      onClick={() => {
                        setSelectedBank({})
                        setSelectedAccountType({})
                        setTotalAmount('')
                        setInterestRate('')
                        setNote('')
                        setPayAmount('')
                        setBankLoanDate(moment().format('YYYY-MM-DD'))
                        setViewMode('add-bank-loan')
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="update-bank-loan-body common-container-body input-group">
                    <div className="input-item">
                      <label>Select Bank</label>
                      <Select
                        options={bankOptions}
                        value={selectedBank}
                        onChange={(selected) => setSelectedBank(selected)}
                        isDisabled={viewMode === 'update-bank-loan'}
                      />
                    </div>
                    <div className="input-item">
                      <label>Account Type</label>
                      <Select
                        options={accountTypeOptions}
                        value={selectedAccountType}
                        onChange={(selected) => setSelectedAccountType(selected)}
                        isDisabled={viewMode === 'update-bank-loan'}
                      />
                    </div>
                    <div className="input-item">
                      <label htmlFor="total-amount">Total Amount</label>
                      <input
                        type="text"
                        id="total-amount"
                        placeholder="Enter Amount"
                        value={totalAmount}
                        disabled={viewMode === 'update-bank-loan'}
                        onChange={(e) => setTotalAmount(getFloatNumber(e.target.value, totalAmount))}
                      />
                    </div>
                    <div className="input-item">
                      <label htmlFor="due-amount">Due Amount</label>
                      <input
                        type="text"
                        id="due-amount"
                        placeholder="Enter Amount"
                        value={parseFloat(dueAmount).toFixed(2)}
                        disabled
                        // onChange={(e) => setDueAmount(getFloatNumber(e.target.value, dueAmount))}
                      />
                    </div>
                    <div className="input-item">
                      <label htmlFor="interest-rate">Interest Rate</label>
                      <input
                        type="text"
                        id="interest-rate"
                        placeholder="Enter Interest Rate"
                        value={interestRate}
                        onChange={(e) => {
                          let _temp = getFloatNumber(e.target.value, interestRate)
                          if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                          setInterestRate(parseFloat(_temp) >= 0 || _temp === '' ? _temp : interestRate)

                          // setInterestRate(getFloatNumber(e.target.value, interestRate))
                        }}
                      />
                    </div>
                    <div className="input-item">
                      <label>Date</label>{console.log('bankLoanDate: ', bankLoanDate)}
                      <DatePicker
                        defaultValue={() => {
                          console.log('defaulValue called')
                          return moment(bankLoanDate ? moment(bankLoanDate).format('YYYY-MM-DD') : bankLoanDate, 'YYYY-MM-DD')
                        }}
                        onChange={(value, dateString) => setBankLoanDate(dateString)}
                      />
                    </div>
                    <div className="input-item note-item">
                      <label>Note</label>
                      <textarea
                        name="note"
                        id="note"
                        rows="3"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div> 
                  <div className="Update-bank-loan-footer common-container-footer">
                    {bankLoanSubmitLoading ? <Spin size="large" /> : (
                      <button
                        className="custom-button custom-button-sm"
                        onClick={handleBankLoanSubmit}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
                  
                <div className="add-account-header">
                  <h2>Add Transaction</h2>
                </div>
                <div className="add-account-body common-container-body input-group">
                  <div className="input-item">
                    <label>Transaction Type</label>
                    <Select
                      options={transactionTypeOptions}
                      value={selectedTransactionType}
                      onChange={(selected) =>  setSelectedTransactionType(selected)}
                    />
                  </div>
                  <div className="input-item">
                    <label htmlFor="Pay-amount">Pay Amount</label>
                    <input
                      type="text"
                      id="pay-amount"
                      placeholder="Enter Amount"
                      value={payAmount}
                      onChange={(e) => {
                        
                        let _temp = getFloatNumber(e.target.value, payAmount)
                        if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                        setPayAmount(parseFloat(_temp) >= 0 || _temp === '' ? _temp : payAmount)
                        
                      }}
                    />
                  </div>
                      
                  <div className="input-item note-item">
                    <label>Note</label>
                    <textarea
                      name="-transaction-note"
                      id="-transaction-note"
                      rows="3"
                      value={transactionNote}
                      onChange={(e) => setTransactionNote(e.target.value)}
                    />
                  </div>
                </div>
                
                <div className="add-account-footer common-container-footer">
                  {transactionSubmitLoading ? <Spin size="large" /> : (
                    <button
                      className="custom-button custom-button-sm"
                      onClick={handleTransactionSubmit}
                    >
                      Add Transaction
                    </button>
                  )}
                </div>

                <div className="export-pdf">
                  <button
                    className="custom-button custom-button-sm"
                    onClick={() => {
                      console.log('ref.current: ', trnasactionRef.current)
                      setDownloadClicked(true)
                      // const _editBtn = document.getElementById('.edit-btn-div')
                      //   _editBtn?.style.visibilty = 'hidden'
                      setTimeout(() => { 

                        const fileName = `transaction-${moment().format('YYYY-MM-DD')}.pdf`
                        generatePDF(fileName, trnasactionRef, setDownloadClicked)
                        
                      }, 0)
                      
                    }}
                  >
                    Export PDF
                  </button>
                </div>
                
                <div
                  className="bank-loan-transaction-container common-container-body"
                  ref={trnasactionRef}
                  style={
                    downloadClicked ? {
                    width: '89rem',
                    padding: '4rem'
                  } : {}}
                >
                  {downloadClicked &&
                    <div
                      className="bank-loan-transaction-body extra-content-for-pdf"
                      ref={_extraDataPdfRef}
                    >
                      {getTransactionLists(bankLoans.filter((_itemB) => _itemB._id === selectedBankLoan._id))}
                    </div>
                  }
                  <div className="bank-loan-transaction-header">
                    <h3><strong>{selectedBank.label}</strong> Transactions</h3>
                  </div>
                  <div className="bank-loan-transaction-body bank-loan-transaction-table-container">
                    <div className="bank-loan-transaction-table-header">
                      <div className="bank-loan-transaction-row common-row header">
                        <span>Transaction ID</span>
                        <span>Date</span>
                        <span>Type</span>
                        <span>Transactor</span>
                        <span>Amount (৳)</span>
                        <span>Note</span>
                      </div>
                    </div>
                    <div className="bank-loan-transaction-table-body">
                      {bankLoanTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Loan is available</p>)
                        : bankLoanTransactions.map((transactionItem, _index) => (
                          <div key={_index} className='bank-loan-transaction-row common-row'>
                            <span>{transactionItem.bankLoanTransactionId}</span>
                            <span>{moment(transactionItem.date).format('YYYY-MM-DD')}</span>
                            <span>{transactionItem.type}</span>
                            <span>{transactionItem.userName}</span>
                            <span className={getMarkerClassName(transactionItem.type)}>{getFormattedReadableNumber(transactionItem.amount)}</span>
                            <span>{transactionItem.note || ''}</span>
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
        )
      }
      
    </div>
  )
}

export default BankLoan;