import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { SidebarData } from "./SidebarData";
import "./Navbar.css";
import { getCurrentUser } from '../utils/helper'
function Navbar({
  history,
  title,
  setTitle,
  setIsNavOpen
}) {
  
  const location = useLocation()
  const _user = getCurrentUser()
  
  let filteredData = SidebarData

  if (_user && _user.role && _user.role !== 'super_admin') {
    filteredData = filteredData.filter((_item) =>
      _item.path !== '/user'
    )
  }

  useEffect(() => {
    const currentPage = filteredData.find((_item) => _item.path === location.pathname)
    if (currentPage) {
      setTitle(currentPage.title)
    }
    
    const unlisten = () => window.scrollTo(0, 0)
    return () => {
      unlisten()
    }
    // console.log('location changed: ', location)
  }, [location])

  return (
    <div className="nav-container">
      <nav className={"nav-menu web active"}>
        <ul className="nav-menu-items">
          {filteredData.map((item, index) => {
            return (
              <li
                key={index}
                className={item.cName}
                onClick={() => {
                  console.log(item.title)
                  setTitle(item.title)
                  if (window.innerWidth <= 800) {
                    document.body.classList.remove('nav-open')
                    setIsNavOpen(false)
                  }
                }}
              >
                <Link
                  className={`nav-link ${item.title === title ? 'active' : ''}`}
                  to={item.path}
                >
                  {item.icon}
                  <span>{item.title}</span>
                  <ArrowForwardIosOutlinedIcon />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
  
}

export default Navbar;
