import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import Topbar from "./components/Topbar";
import BankLoan from "./Pages/BankLoan";
import BuySell from "./Pages/BuySell";
import EntityInfo from "./Pages/EntityInfo";
import DailyAccount from "./Pages/DailyAccount";
import Entity from "./Pages/Entity";
import Expense from "./Pages/Expense";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import NotExists from "./Pages/NotExists";
import Stock from "./Pages/Stock";
import User from "./Pages/User";
import "./style/App.css";
import ExpensesByType from "./Pages/ExpensesByType";

const SidebarLayout = ({
  isNavOpen,
  setIsNavOpen,
  title,
  setTitle
}) => (
  <>
    <Topbar
      isNavOpen={isNavOpen}
      setIsNavOpen={setIsNavOpen}
      title={title}
    />
    <Navbar
      isNavOpen={isNavOpen}
      setIsNavOpen={setIsNavOpen}
      title={title}
      setTitle={setTitle}
    />
    <Outlet />
  </>
);


function App() {
  const [title, setTitle] = useState('')
  const [isNavOpen, setIsNavOpen] = useState(true)
  // const [pageName, setPageName] = useState("DASHBOARD");

  
  return (
    <>
      <Router>
        <Routes>
          <Route element={
            <SidebarLayout
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
              title={title}
              setTitle={setTitle}
            />}
          >
            <Route path="/home" exact element={<Home />} />
            <Route path="/transaction" exact element={<BuySell/>} />
            <Route path="/stock" exact element={<Stock />} />
            <Route path="/user" exact element={<User setTitle={setTitle}/>} />
            <Route path="/expense" exact element={<Expense />} />
            <Route path="/transactionbyexpensetype" exact element={<ExpensesByType />} />
            <Route path="/entity" exact element={<Entity />} />
            <Route path="/entityinfo" exact element={<EntityInfo />} />
            {/* <Route path="/transaction/:transactionId" exact element={<CustomerTransaction />} /> */}
            <Route path="/bankaccount" exact element={<BankLoan />} />
            <Route path="/dailyaccount" exact element={<DailyAccount />} />
          </Route>
          <Route path="/" exact element={<Login />} />
          <Route path="*" element={<NotExists />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
