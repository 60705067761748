
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

export const SidebarData = [
  {
    title: 'DASHBOARD',
    path: '/home',
    icon: <DashboardOutlinedIcon />,
    cName: 'nav-item nav-item-dashboard'
  },
  {
    title: 'BUY/SALE',
    path: '/transaction',
    icon: <CompareArrowsOutlinedIcon />,
    cName: 'nav-item nav-item-transaction'
  },
  {
    title: 'EXPENSE',
    path: '/expense',
    icon: <MoneyOffOutlinedIcon />,
    cName: 'nav-item nav-item-expense'
  },
  {
    title: 'STOCK',
    path: '/stock',
    icon: <Inventory2OutlinedIcon />,
    cName: 'nav-item nav-item-stock'
  },
  {
    title: 'Entity',
    path: '/entity',
    icon: <PersonAddAltOutlinedIcon />,
    cName: 'nav-item nav-item-entity'
  },
  {
    title: 'Bank Account',
    path: '/bankaccount',
    icon: <AccountBalanceOutlinedIcon />,
    cName: 'nav-item nav-item-bank-account'
  },
  {
    title: 'Debitors/Creditors',
    path: '/dailyaccount',
    icon: <AccountBalanceWalletOutlinedIcon />,
    cName: 'nav-item nav-item-daily-account'
  },
  {
    title: 'USER',
    path: '/user',
    icon: <GroupOutlinedIcon />,
    cName: 'nav-item nav-item-user'
  }
];
