import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Spin, Modal } from 'antd'
import axios from '../config/axios'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  redirectLoginIfLoggedOut,
  removeObjectPrototype
} from '../utils/helper';
import '../style/Product.css'
import '../style/expense-types.css'

function ExpenseTypes({
  setViewMode
}) {
  const navigate = useNavigate()

  const [mode, setMode] = useState('view')
  const [expenseTypeLoading, setExpenseTypeLoading] = useState(true)
  const [addExpenseTypeLoading, setAddExpenseTypeLoading] = useState(false)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [expenseType, setExpenseType] = useState('')
  const [selectedId, setSelectedId] = useState('')
  const [expenseTypes, setExpenseTypes] = useState([])
  
  const _user = JSON.parse(localStorage.getItem('user'))
  
  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    fetchAllExpenseTypes()
  }, [])

  const fetchAllExpenseTypes = async () => {
    try {
      const { data } = await axios.get(
        '/expense-type', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('expenseTypes: ', data)
      
      setExpenseTypes(data.map((_item) => removeObjectPrototype(_item)))
      
      setExpenseTypeLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      // console.log('_error.response.data.message: ', _error.response.data.message)
      setExpenseTypeLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  const handleAddEditExpenseType = async () => {
    if (expenseType === '') {
      message.info('Please enter Expense Type')
      return
    }
    const _find = expenseTypes.find((item) => item.name === expenseType)
    if (_find) {
      message.info('Expense Type Already Exists')
      return
    }
    setAddExpenseTypeLoading(true)
    // console.log('user: ', _user._id)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/')
      return
    }

    if (mode === 'view') {
      try {
        const { data } = await axios.post(
          '/expense-type',
          {
            name: expenseType,
            adminId: _user._id
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        setExpenseTypes([
          ...expenseTypes,
          removeObjectPrototype(data.expenseType)
        ])
        
        message.success(data.message)
        setAddExpenseTypeLoading(false)
        setExpenseType('')
      } catch (_error) {
        console.log('error: ', _error)
        // console.log('_error.response.data.message: ', _error.response.data.message)
        message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
        setAddExpenseTypeLoading(false)
      }
    } else {
      try {
        const { data } = await axios.put(
          `/expense-type/${selectedId}`,
          {
            name: expenseType,
            adminId: _user._id
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        fetchAllExpenseTypes()
        
        message.success(data.message)
        setAddExpenseTypeLoading(false)
        setExpenseType('')
        setSelectedId('')
        setMode('view')
      } catch (_error) {
        console.log('error: ', _error)
        // console.log('_error.response.data.message: ', _error.response.data.message)
        message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
        setAddExpenseTypeLoading(false)
      }
    }
    
  }

  const handleExpenseTypeDelete = async () => {
    try {
      const { data } = await axios.delete(
        `/expense-type/${selectedId}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success('Type Deleted Successfully!')
      fetchAllExpenseTypes()
      setSelectedId('')
      setDeleteConfirmModal(false)
    } catch (_error) {
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
    }
  }

  return (
    <div>{/*  className='expense-type-container'*/}
      <Modal
        visible={deleteConfirmModal}
        title="Expense Type Delete Confirmation"
        onCancel={() => {
          setSelectedId('')
          setDeleteConfirmModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setDeleteConfirmModal(false)
              setSelectedId('')
            }}
            style={{marginRight: '10px'}}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleExpenseTypeDelete}
          >
            Delete
          </button>
        ]}
      >
        <p>Are you sure to delete this Type?</p>
      </Modal>
      {mode === 'view' && (
        <div className='all-expense-type-container'>
          <div className='all-expense-type-header back-button-container'>
            <h2>Expense Types</h2>
            <button
              className="custom-button custom-button-sm"
              onClick={() => {
                setViewMode('expenses')
              }}
            >
              Back
            </button>
          </div>
          {expenseTypeLoading
            ? (<Spin size="large" />)
            : expenseTypes.length === 0 ? (<p>Expense Type list is Empty.</p>)
            : (
            <div className="all-expense-type-body">
              {expenseTypes.map((_expenseType, _index) => (
                <div
                  className='expense-type-item'
                  key={_index}
                >
                  <p>{_expenseType.name}</p>
                  {_user.role === 'super_admin' && (
                    <div className="actions">
                      <span
                        onClick={() => {
                          setSelectedId(_expenseType._id)
                          setExpenseType(_expenseType.name)
                          setMode('edit')
                        }}
                      >
                        <EditOutlinedIcon />
                      </span>
                      <span
                        onClick={() => {
                          setSelectedId(_expenseType._id)
                          setDeleteConfirmModal(true)
                        }}
                      >
                        <DeleteOutlinedIcon />
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div>
        <div className='add-expense-type-container'>
          <div className='add-expense-type-header'>
            <h2>{mode === 'view' ? 'Add' : 'Edit'} Expense Type</h2>
            {mode === 'edit' && (
              <button
                className='custom-button custom-button-sm'
                onClick={() => {
                  setExpenseType('')
                  setMode('view')
                }}
              >
                Back
              </button>
            )}
          </div>
          
          <div className="add-expense-type-body">
            <div className="input-group">
              <label htmlFor="expense_type">Expense Type</label>
              <input
                type="text"
                className=""
                id='expense_type'
                placeholder='Enter Expense Type'
                value={expenseType}
                onChange={(e) => setExpenseType(e.target.value)}
              />
            </div>
        
            <div className="input-submit">
              {addExpenseTypeLoading
                ? (<Spin size="large" />)
                : (<button onClick={handleAddEditExpenseType}>{ mode === 'view' ? 'Add' : 'Update'}</button>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseTypes;
