import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from 'moment'
import { message, Spin, DatePicker } from 'antd'
import html2canvas from 'html2canvas'
import jsPDF from "jspdf"
import axios from '../config/axios'
import ExpenseTypes from "./ExpenseTypes";
import CustomDateFilter from '../components/CustomDateFilter';
import {
  getCurrentUser,
  removeObjectPrototype,
  redirectLoginIfLoggedOut,
  generatePDF,
  getFloatNumber,
  getFormattedReadableNumber
} from '../utils/helper'
import '../style/common.css'
import '../style/buy.css'
import '../style/expense.css'
import { fetchAllExpenseTypes, fetchAllProducts, fetchAllusers } from "../utils/apis";

const Expense = () => {

  const navigate = useNavigate()
  const _user = getCurrentUser()

  const expensesAllRef = useRef()
  const productExpensesAllRef = useRef()

  const [viewMode, setViewMode] = useState('expenses')

  const [expenseLoading, setExpenseLoading] = useState(true)
  const [productLoading, setProductLoading] = useState(true)
  const [expenseSubmitLoading, setExpenseSubmitLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [expenseTypeOptions, setExpenseTypeOptions] = useState([])
  const [selectedExpenseType, setSelectedExpenseType] = useState(expenseTypeOptions[0])
  const [rawExpenseProductTransactions, setRawExpenseProductTransactions] = useState([])
  const [expenseProductTransactions, setExpenseProductTransactions] = useState([])
  const [rawExpenseTransactions, setRawExpenseTransactions] = useState([])
  const [expenseTransactions, setExpenseTransactions] = useState([])
  const [expenseAmount, setExpenseAmount] = useState('')
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [note, setNote] = useState('')
  const [dateFilter, setDateFilter] = useState('today')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [users, setUsers] = useState([])

  const [totalExpense, setTotalExpense] = useState(0)
  const [totalProductExpense, setTotalProductExpense] = useState(0)

  const [expenseDownloadClicked, setExpenseDownloadClicked] = useState(false)
  const [productExpenseDownloadClicked, setProductExpenseDownloadClicked] = useState(false)

  const [selectedTypesExpenseFilter, setSelectedTypesExpenseFilter] = useState([])
  const [selectedTypesExpenseProductFilter, setSelectedTypesExpenseProductFilter] = useState([])

  useEffect(() => {
    redirectLoginIfLoggedOut(navigate)
    if (!_user) {
      message.error('Login Expired!')
      navigate('/')
    }
    // fetchAllExpenseTypes()
    // fetchAllusers()
    // fetchAllProduct()
    init()
  }, [])

  useEffect(() => {
    if (dateFilter !== 'custom') {
      fetchExpenseProductTransactions()
      fetchExpenseTransactions()
    }
  }, [dateFilter, users, expenseTypeOptions, productOptions])

  useEffect(() => {
    setTotalExpense(expenseTransactions.reduce((_sum, _item) => _sum + _item.amount, 0))
  }, [expenseTransactions])

  useEffect(() => {
    setTotalProductExpense(expenseProductTransactions.reduce((_sum, _item) => _sum + _item.amount, 0))
  }, [expenseProductTransactions])

  useEffect(() => {
    if (selectedTypesExpenseFilter.length) {
      const _typeIds = selectedTypesExpenseFilter.map((_it) => _it.value)
      const _temps = rawExpenseTransactions.filter((_it) => _typeIds.includes(_it.typeId))
      setExpenseTransactions(_temps)
    } else {
      setExpenseTransactions(rawExpenseTransactions)
    }
  }, [selectedTypesExpenseFilter])

  useEffect(() => {
    if (selectedTypesExpenseProductFilter.length) {
      const _typeIds = selectedTypesExpenseProductFilter.map((_it) => _it.value)
      const _temps = rawExpenseProductTransactions.filter((_it) => _typeIds.includes(_it.typeId))
      setExpenseProductTransactions(_temps)
    } else {
      setExpenseProductTransactions(rawExpenseProductTransactions)
    }
  }, [selectedTypesExpenseProductFilter])


  const init = async () => {
    const _expenseTypes = await fetchAllExpenseTypes()
    setSelectedExpenseType({
      value: _expenseTypes[0]._id,
      label: _expenseTypes[0].name
    })
    setExpenseTypeOptions(_expenseTypes.map((_item) => ({ value: _item._id, label: _item.name })))
    
    const _products = await fetchAllProducts()
    // setSelectedProduct({
    //   value: _products[0]._id,
    //   label: _products[0].name
    // })
    setProducts(_products)
    setProductOptions(_products.filter((_item) => !_item.deleted).map((_item) => ({ value: _item._id, label: _item.name })))
    setProductLoading(false)

    const _users = await fetchAllusers()
    setUsers(_users)

  }
  

  const fetchExpenseProductTransactions = async () => {
    setExpenseLoading(true)
    const _path = dateFilter !== 'custom'
      ? `/expenseproduct/${dateFilter}`
      : `/expenseproduct/${startDate}/${endDate}`
    
    console.log('fetchExpenseProductTransactions: ', _path)
    try {
      const { data } = await axios.post(
        _path,{ timeOffset: (new Date()).getTimezoneOffset() }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      console.log('expenseProductTransactions data: ', data)
      const _temps = data.map((_item) => {
        const targetUser = users.find((_item2) => _item2._id === _item.userId)
        const targetProduct = products.find((_item2) => _item2._id === _item.productId)
        return {
          ...removeObjectPrototype(_item),
          userName: targetUser?.name || '-',
          productName: targetProduct?.name || '-',
          type: expenseTypeOptions.find((_item2) => _item2.value === _item.typeId)?.label || '-',
        }
      }).sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0) || []

      setExpenseProductTransactions(_temps)
      setRawExpenseProductTransactions(_temps)
      
      setExpenseLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      setExpenseLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }
  
  
  const fetchExpenseTransactions = async () => {
    setExpenseLoading(true)
    const _path = dateFilter !== 'custom'
      ? `/expense/${dateFilter}`
      : `/expense/${startDate}/${endDate}`
    
    console.log('fetchExpenseTransactions: ', _path)
    try {
      const { data } = await axios.post(
        _path, { timeOffset: (new Date()).getTimezoneOffset() }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      const _temps = data.map((_item) => {
        const targetUser = users.find((_item2) => _item2._id === _item.userId)
        // console.log(targetUser.name)
        return {
          ...removeObjectPrototype(_item),
          userName: targetUser?.name || '-',
          type: expenseTypeOptions.find((_item2) => _item2.value === _item.typeId)?.label || '-',
        }
      }).sort((_a, _b) => _a.expenseId > _b.expenseId ? -1 : _a.expenseId < _b.expenseId ? 1 : 0)
      setExpenseTransactions(_temps)
      setRawExpenseTransactions(_temps)
      
      setExpenseLoading(false)
    } catch (_error) {
      console.log('error: ', _error)
      setExpenseLoading(false)
      // message.error(_error?.response?.data?.message || 'Something wrong. Please Refresh the page.')
    }
  }


  const handleExpenseProductSubmit = async () => {

    const body = {
      amount: expenseAmount,
      productId: selectedProduct.value,
      date: new Date(date),
      timeOffset: (new Date()).getTimezoneOffset(),
      typeId: selectedExpenseType.value,
      note,
      userId: _user?._id
    }
    
    console.log('body: ', body)
    if (!body.amount) {
      message.info('Expense Amount is required')
      return
    }
    setExpenseSubmitLoading(true)
    try {
      const { data } = await axios.post(
        '/expenseproduct',
        body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      setExpenseSubmitLoading(false)
      setExpenseAmount('')
      setNote('')
      if (dateFilter === 'custom') setDateFilter('today')
      fetchExpenseProductTransactions()

    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setExpenseSubmitLoading(false)
    }
  }

  const handleExpenseSubmit = async () => { 
    // console.log('new Date: ', new Date(date))
    // console.log('new Date iso: ', new Date(date).toISOString())

    // return

    const body = {
      amount: expenseAmount,
      date: new Date(date),
      timeOffset: (new Date()).getTimezoneOffset(),
      typeId: selectedExpenseType.value,
      note,
      userId: _user?._id
    }
    
    console.log('body: ', body)
    if (!body.amount) {
      message.info('Expense Amount is required')
      return
    }
    setExpenseSubmitLoading(true)
    try {
      const { data } = await axios.post(
        '/expense',
        body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      
      message.success(data.message)
      setExpenseSubmitLoading(false)
      setExpenseAmount('')
      setNote('')
      if (dateFilter === 'custom') setDateFilter('today')
      fetchExpenseTransactions()

    } catch (_error) {
      // console.log('error: ', _error)
      // console.log('error: ', _error.response.data.message)
      message.error(_error?.response?.data?.message || 'Something wrong. Please try again.')
      setExpenseSubmitLoading(false)
    }
  }

  return (
    <div className="expense-container">
      {viewMode === 'expenses' ? (
        <>
          <div className="add-expense-container">
            <div className="add-expense-header back-buttons-container">
              <h2>Add Expense</h2>
              <div className="header-buttons">
                <button
                  className="custom-button custom-button-sm"
                  onClick={() => {
                    setExpenseAmount('')
                    setNote('')
                    setDateFilter('today')
                    setViewMode('expenses-product')
                  }}
                >
                  Product Expense
                </button>
                <button
                  className="custom-button custom-button-sm"
                  onClick={() => {
                    setExpenseAmount('')
                    setNote('')
                    setDateFilter('today')
                    setViewMode('expense-types')
                  }}
                >
                  Expense Types
                </button>
              </div>
              
            </div>
            <div className="add-expense-body">
              <div className="input-item">
                <label htmlFor="vendorName">Expense Type</label>
                <Select
                  options={expenseTypeOptions}
                  value={selectedExpenseType}
                  onChange={(selected) => {
                    console.log(selected)
                    setSelectedExpenseType(selected)
                  }}
                />
              </div>
              <div className="input-item">
                <label htmlFor="expense-amount">Expense Amount</label>
                <input
                  type="text"
                  id="expense-amount"
                  placeholder="Enter Amount"
                  value={expenseAmount}
                  onChange={(e) => setExpenseAmount(getFloatNumber(e.target.value))}
                  // onChange={(e) => {
                  //   let _temp = getFloatNumber(e.target.value, expenseAmount)
                  //   if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                  //   setExpenseAmount(parseFloat(_temp) >= 0 || _temp === '' ? _temp : expenseAmount)
                    
                  //   // setExpenseAmount(getFloatNumber(e.target.value, expenseAmount))
                  // }}
                />
              </div>
              <div className="input-item">
                <label>Date</label>
                <DatePicker
                  defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                  onChange={(value, dateString) => {
                    console.log(dateString)
                    setDate(dateString)
                  }}
                />
              </div>
              <div className="input-item note-item">
                <label>Note</label>
                <textarea
                  name="note"
                  id="note"
                  rows="3"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div> 
            <div className="add-expense-footer">
              {expenseSubmitLoading ? <Spin size="large" /> : (
                <button
                  className="buy-submit"
                  onClick={handleExpenseSubmit}
                >
                  Add
                </button>
              )}
            </div>
          </div>

          <div className="expense-transaction-container">
            <div className="in-out-stock-filter">
              <CustomDateFilter
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                handleSubmit={fetchExpenseProductTransactions}
              />
            </div>
            <div className="custom-select-filter input-item">
              <label htmlFor="vendorName">Select Type</label>
              <Select
                isMulti
                options={expenseTypeOptions}
                value={selectedTypesExpenseFilter}
                onChange={(selected) => {
                  console.log(selected)
                  setSelectedTypesExpenseFilter(selected)
                }}
              />
            </div>
            <div className="expense-transaction-header">
              <span>
                <h3>Total: <strong>{getFormattedReadableNumber(totalExpense)}</strong></h3>
              </span>
              <button
                className="custom-button custom-button-sm"
                onClick={() => {
                  setExpenseDownloadClicked(true)
                  setTimeout(() => { 
                    const fileName = `${viewMode === 'expenses-product' ? 'product-' : ''}expenses-${moment().format('YYYY-MM-DD')}.pdf`
                    generatePDF(fileName, expensesAllRef, setExpenseDownloadClicked)
                  }, 0)
                  
                }}
              >
                Export PDF
              </button>
            </div>
            <div className="expense-transaction-body-outer">
              <div
                className="expense-transaction-body"
                ref={expensesAllRef}
                style={expenseDownloadClicked ? {
                  width: '79rem',
                  padding: '4rem'
                } : {}}
              >
                <div className="expense-transaction-table-header">
                  <div className="expense-row header">
                    <span>Date</span>
                    <span>ID</span>
                    <span>Entry Man</span>
                    <span>Type</span>
                    <span>Amount (৳)</span>
                    <span>Note</span>
                  </div>
                </div>
                <div className="expense-transaction-table-body">
                  {expenseTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction is available</p>)
                    : expenseTransactions.map((expenseItem, _index) => (
                      <div key={_index} className='expense-row'>
                        <span>{ moment(expenseItem.date).format('YYYY-MM-DD') }</span>
                        <span>{ expenseItem.expenseId }</span>
                        <span>{ expenseItem.userName }</span>
                        <span
                          className="entity-cell"
                          onClick={() => {
                            navigate(`/transactionbyexpensetype?typeid=${expenseItem.typeId}`)
                          }}
                        >
                          {expenseItem.type}
                        </span>
                        <span>{ getFormattedReadableNumber(expenseItem.amount) }</span>
                        <span>{ expenseItem.note }</span>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : viewMode === 'expenses-product' ? (
        <>
          <div className="add-expense-container">
            <div className="add-expense-header back-button-container">
              <h2>Add Product Expense</h2>
              <button
                className="custom-button custom-button-sm"
                onClick={() => {
                  setExpenseAmount('')
                  setNote('')
                  setDateFilter('today')
                  setViewMode('expenses')
                }}
              >
                Back
              </button>
            </div>
            <div className="add-expense-body">
              <div className="input-item">
                <label htmlFor="vendorName">Expense Type</label>
                <Select
                  options={expenseTypeOptions}
                  value={selectedExpenseType}
                  onChange={(selected) => {
                    console.log(selected)
                    setSelectedExpenseType(selected)
                  }}
                />
              </div>
              <div className="input-item">
                <label htmlFor="expense-amount">Expense Amount</label>
                <input
                  type="text"
                  id="expense-amount"
                  placeholder="Enter Amount"
                  value={expenseAmount}
                  onChange={(e) => setExpenseAmount(getFloatNumber(e.target.value))}
                  // onChange={(e) => {
                  //   let _temp = getFloatNumber(e.target.value, expenseAmount)
                  //   if (_temp.length > 1 && _temp[0] === '0' && _temp[1] !== '.') _temp = _temp.substring(1)
                  //   setExpenseAmount(parseFloat(_temp) >= 0 || _temp === '' ? _temp : expenseAmount)

                  //   // setExpenseAmount(getFloatNumber(e.target.value, expenseAmount))
                  // }}
                />
              </div>
              <div className="input-item">
                <label>Rice Name</label>
                <Select
                  isLoading={productLoading}
                  options={productOptions}
                  value={selectedProduct}
                  onChange={(selected) => {
                    console.log(selected)
                    setSelectedProduct(selected)
                  }}
                />
              </div>
              <div className="input-item">
                <label>Date</label>
                <DatePicker
                  defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                  onChange={(value, dateString) => {
                    console.log(dateString)
                    setDate(dateString)
                  }}
                />
              </div>
              <div className="input-item note-item">
                <label>Note</label>
                <textarea
                  name="note"
                  id="note"
                  rows="3"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div> 
            <div className="add-expense-footer">
              {expenseSubmitLoading ? <Spin size="large" /> : (
                <button
                  className="buy-submit"
                  onClick={handleExpenseProductSubmit}
                >
                  Add
                </button>
              )}
            </div>
          </div>

          <div className="expense-transaction-container">
            <div className="in-out-stock-filter">
              <CustomDateFilter
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                handleSubmit={fetchExpenseProductTransactions}
              />
            </div><div className="custom-select-filter input-item">
              <label htmlFor="vendorName">Select Types</label>
              <Select
                isMulti
                options={expenseTypeOptions}
                value={selectedTypesExpenseProductFilter}
                onChange={(selected) => {
                  console.log(selected)
                  setSelectedTypesExpenseProductFilter(selected)
                }}
              />
            </div>
            <div className="expense-transaction-header">
              
              <span>
                <h3>Total: <strong>{getFormattedReadableNumber(totalProductExpense)}</strong></h3>
              </span>
              <button
                className="custom-button custom-button-sm"
                onClick={() => {
                  setProductExpenseDownloadClicked(true)
                  setTimeout(() => { 
                    const fileName = `product-expenses-${moment().format('YYYY-MM-DD')}.pdf`
                    generatePDF(fileName, productExpensesAllRef, setProductExpenseDownloadClicked)
                  }, 0)
                  
                }}
              >
                Export PDF
              </button>
              </div>
            <div className="expense-transaction-body-outer">
              <div
                className="expense-transaction-body expense-product-transaction-body"
                ref={productExpensesAllRef}
                style={productExpenseDownloadClicked ? {
                  width: '89rem',
                  padding: '4rem'
                } : {}}
                >
                <div>
                  <h3>Product Expenses</h3>
                </div>
                <div className="expense-transaction-table-header">
                  <div className="expense-row header">
                    <span>Date</span>
                    <span>ID</span>
                    <span>Entry Man</span>
                    <span>Type</span>
                    <span>Product</span>
                    <span>Amount (৳)</span>
                    <span>Note</span>
                  </div>
                </div>
                <div className="expense-transaction-table-body">
                  {expenseProductTransactions.length === 0 ? (<p style={{ marginTop: '20px' }}>No Transaction is available</p>)
                    : expenseProductTransactions.map((expenseItem, _index) => (
                    <div key={_index} className='expense-row'>
                      <span>{ moment(expenseItem.date).format('YYYY-MM-DD') }</span>
                      <span>{ expenseItem.expenseProductId }</span>
                        <span>{expenseItem.userName}</span>
                        <span
                          className="entity-cell"
                          onClick={() => {
                            navigate(`/transactionbyexpensetype?typeid=${expenseItem.typeId}`)
                          }}
                        >
                          {expenseItem.type}
                        </span>
                      <span>{ expenseItem.productName }</span>
                      <span>{ getFormattedReadableNumber(expenseItem.amount) }</span>
                      <span>{ expenseItem.note }</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ): (
          <ExpenseTypes
            setViewMode={setViewMode}
          />
      )}
      
    </div>
  )
}

export default Expense;